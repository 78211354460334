import { useNavigate } from "react-router";
import { useSessionContext } from "../contexts/session-context";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { useCallback, useEffect, useState } from "react";
import AuthService from "./auth-service"

export default function Login() {
      const [session, setSession] = useSessionContext();
      const [checked1, setChecked1] = useState(true);

      const [loginSuccess, setLogginSuccess] = useState(true);

      const [input, setInput] = useState({
            username: "",
            password: "",
      });

      const navigate = useNavigate();

 
      const handleLogin = useCallback(async () => {

            if (input.username && input.password) {

                  const res = await AuthService.login(input.username, input.password);
                  if (!res) {
                       // setLogginSuccess( false );
                         alert("login failed.")
                        setSession({ ...session, isAuthenticated: false });
                  } else {
                      // setLogginSuccess( true );
                        setSession({ ...session, isAuthenticated: true });
                        navigate(session.redirectPath);
                  }
            }

      }, [input]);
     
     /*
      useEffect(()=>{
            const handleLogin = async (e) =>{
                  e.preventDefault();
                  if ( input.username && input.password) {
                        const res = await AuthService.login(input.username, input.password);
                        if (!res) {
      
                        } else {
                              setSession({ ...session, isAuthenticated: true });
                              navigate(session.redirectPath);
                        }
                  }
          }
          handleLogin()
      },[])
       */
      /*
        useEffect(()=>{
            const fetchuser = async () =>{
              try{
      
                const res = await AuthService.login(username, password);
                if ( !res) {
      
                } else {
                  setSession({...session, isAuthenticated: true});
                  navigate(session.redirectPath);
                }
      
              }catch(err){
      
                  //need login
      
              }finally{
      
      
      
              }
            }
          },[username, password])
      */
      return (
       
                  <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                        <div className="text-center mb-5">
                        { /*   <img src="/demo/images/blocks/logos/hyper.svg" alt="hyper" height={50} className="mb-3" /> */ }
                              <div className="text-900 text-3xl font-medium mb-3">Login required to access this content.</div>
                              { /*  <span className="text-600 font-medium line-height-3">Don't have an account?</span> 
                  <a className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a> */ }
                        </div>

                        <div>
                              <label htmlFor="email" className="block text-900 font-medium mb-2">User Name</label>
                              <InputText id="email" type="text" placeholder="User Name" className="w-full mb-3" onChange={(e) => setInput({ ...input, username: e.target.value })}
                               />

                              <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
                              <InputText type="password" placeholder="Password" className="w-full mb-3" onChange={(e) => setInput({ ...input, password: e.target.value })}
                               />

                              <div className="flex align-items-center justify-content-between mb-6">
                              { /* <div className="flex align-items-center">
                                          <Checkbox id="rememberme" className="mr-2" checked={checked1} onChange={(e) => setChecked1(e.checked as boolean)} />
                                          { /* <label htmlFor="rememberme">Remember me</label>
                                    </div>*/ }
                                    { /* <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot your password?</a> */}
                              </div>
                                    {/* <Button type="submit" onClick={handleLogin} label="Sign In" icon="pi pi-user" className="w-full" />*/}
                              {loginSuccess === false  &&
                                    <p style={{ "color":"red"}}>Login failed</p>
                              }
                              <Button onClick={handleLogin} label="Sign In" icon="pi pi-user" className="w-full" />
                              
                        </div>

                  </div>
     

      )


}