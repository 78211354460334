import Config from "../../config";
import { FrameCompletion, IFrameEntry, IFrameOtherProperties } from "../../models/daily-activity.models";
import { FrameProduct } from "../../models/frame-product";

interface IFrameTileProps extends IFrameOtherProperties, IFrameEntry {

}

const FrameTile2 = (props: IFrameTileProps) => {
      return (

            <div className={`frame`}>
                  <div className={`frame-year-border year${props.frame.modelYear} `}>
                  </div>
                  <div className="frame-image">
                        <div className={ `frame-style-color  size-${props.frame.size}` }>
                              <img src={Config.baseUrl + '/img/moulding-label/img/' + props.frame.image.replace( '_SUSTAINABLE', '') + '.jpg' } alt="frame" />
                        </div>
                  </div>

                  <div className="frame-details">
                        <div className='top-row'>
                              <div className='serial'>
                                    {props.frameSerialId}
                              </div>
                
                        </div>
                        <div className='frame-style'>
                              <div>{props.frame.modelName} </div>
                              <div>{props.frame.size} - {props.frame.modelYear}</div>
                        </div>

                        {props.isReturn && 
                              <div className='other-properties'>

                                     <div>{props.isVoided !== undefined && props.isVoided === true  ? "VOIDED": ""} </div>
                                     <div>{props.isVoided !== undefined && props.isVoided === false  ? "UN-VOIDED": ""} </div>

                                    <div>{props.isReturn && props.isReturn === true ? "RTN" : ""}</div>
                                    <div>{props.returnDate && props.returnDate}</div>
                                    <div>{props.isRecut  && props.isRecut === true ? "RCT" : ""}</div>
                              </div>
                        }
                  </div>
            </div>
      );
}

export default FrameTile2;