import { Outlet } from 'react-router-dom';
import SideMenuBar from './side-bar-menu';
import { Button } from 'primereact/button';
import { useState } from 'react';
import SessionContextProvider from './contexts/session-context';


interface ILayoutProps {
      visible: boolean;
      displaySideBarMenu: (show: boolean) => void
      isSmallScreenWidth: boolean;
}

const Layout  = ( props: ILayoutProps ) => {

      return (

            <div className="grid">
                  { props.visible === true ?                        
                        <div className="col-fixed side-bar" >
                              <SideMenuBar visible={props.visible} displaySideBarMenu={props.displaySideBarMenu} autoCloseOnMenuClick={props.isSmallScreenWidth}/>
                        </div>
                   :
                        <Button className="menu-button" style={{ position: "absolute", top: "5px", left: "5px", backgroundColor: "#3b3b3c1c" }} icon="pi pi-bars" onClick={() => props.displaySideBarMenu(true)} />
                   }
                  <div className="col bg-primary-50 p-4">
                        <Outlet />
                  </div>
            </div>
           
      )
};

export default Layout;