import React, { useCallback, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";


import { ReactEChartsProps } from './echarts/react-echarts';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import BoxingPrepActivityReport from './reports/boxing-prep/boxing-prep-activity-report/boxing-prep-activity-report';
import ErrorPage from './error-page';
import DailyActivityReport from './reports/daily-summary-report/daily-activity.report';
import FIPByStyleSizeYear from './reports/fip-by-style-size-year/fip-by-style-size-year';
import EndOfDaySlackReport from './reports/end-of-day-slack/end-of-day-slack-report';
import FBAPicklist from './reports/fba-picklist/fba-picklist.report';
import Layout from './layout';
import PalletInventorySummaryReport from './reports/pallet-inventory/pallet-inventory-summary-report';
import PalletInventorySelection from './reports/pallet-inventory/pallet-inventory-selection';
import WasteReport from './reports/waste-report/waste-report';
import BoxingPrepLastInventoryReport from './reports/boxing-prep/boxing-prep-last-inventory-report/boxing-prep-last-inventory-report';
import BoxingPrepCurrentInventoryReport from './reports/boxing-prep/boxing-prep-current-inventory-level-report copy/boxing-prep-current-inventory-level-report';
import FramesBoxedReport from './reports/frames-boxed-report/frames-boxes-report';
import CutListHistoryHome from './reports/cut-list-history/cut-list-history-home';
import CutListHistorySyncReport from './reports/cut-list-history/cut-list-history-sync-report';
import ProtectedRoute from './auth/protected-route';
import { useSessionContext } from './contexts/session-context';
import { ProtectedRouteProps } from './auth/protected-route-props';
import Login from './auth/login';
import FrameHistoryRoot from './frame-history/frame-history-root';
import ConsumptionMappingReport from './reports/consumption-mapping/consumption-mapping-report';
import StationProductivityReport from './reports/station-productivity-report/station-productivity-report';
import FrameInventoryReport from './reports/frame-inventory-report/frame-inventory-report';
import FrameInventoryBatchReport from './reports/frame-inventory-report/frame-inventory-batch-report';
import UserProductivityReport from './reports/user-productivity-report/user-productivity.report';
import PalletInventoryListReportSelectBatch from './reports/current-pallets-report/pallet-inventory-list-report-selection';
import PalletInventoryListReport from './reports/current-pallets-report/pallet-inventory-list-report';

const option: ReactEChartsProps["option"] = {
      dataset: {
            source: [
                  ["Commodity", "Owned", "Financed"],
                  ["Commodity 1", 4, 1],
                  ["Commodity 2", 2, 4],
                  ["Commodity 3", 3, 6],
                  ["Commodity 4", 5, 3],
            ],
      },
      tooltip: {
            trigger: "axis",
            axisPointer: {
                  type: "shadow",
            },
      },
      legend: {
            data: ["Owned", "Financed"],
      },
      grid: {
            left: "10%",
            right: "0%",
            top: "20%",
            bottom: "20%",
      },
      xAxis: {
            type: "value",
      },
      yAxis: {
            type: "category",
      },
      series: [
            {
                  type: "bar",
                  stack: "total",
                  label: {
                        show: true,
                  },
            },
            {
                  type: "bar",
                  stack: "total",
                  label: {
                        show: true,
                  },
            },
      ],
}

function App() { 
      const [isSmallScreenWidth, setIsSmallScreenWidth] = useState(false);
      const [showSideBar, setShowSideBar] = useState(true);
      const [sessionContext, updateSessionContext] = useSessionContext();

      /*
      const setRedirectPath = (path: string) => {
        updateSessionContext({...sessionContext, redirectPath: path});
      }
      */
      const displaySideBarMenu = useCallback( (show: boolean) => {
            setShowSideBar( show )
      }, []);

      const setRedirectPath = useCallback( (path: string) => {
            updateSessionContext({...sessionContext, redirectPath: path});
      }, [sessionContext, updateSessionContext]);
    
      useEffect(() => {
            if(!sessionContext.redirectPath) {
                  setRedirectPath('somewhere');
                }
          }, [sessionContext, setRedirectPath]);

    
      const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
        isAuthenticated: !!sessionContext.isAuthenticated,
        authenticationPath: '/login',
        redirectPath: sessionContext.redirectPath,
        setRedirectPath: setRedirectPath
      };

      const [windowSize, setWindowSize] = useState(() => window.innerWidth);

      const resizeHandler = () => setWindowSize(() => window.innerWidth);

      const debounceFunction = (cb: any, delay: any) => {
        let timer: string | number | NodeJS.Timeout | undefined;
        return () => {
          if (timer) clearTimeout(timer);
          timer = setTimeout(() => cb(), delay);
        };
      };

      const debounce = debounceFunction(resizeHandler, 100);

      useEffect(() => {
            window.addEventListener("resize", debounce);
            return () => window.removeEventListener("resize", debounce);
      }, []);

      useEffect(() => {
            if ( windowSize < 1140 ) {
                  displaySideBarMenu ( false );
                  setIsSmallScreenWidth( true );
            } else {
                  displaySideBarMenu ( true );
                  setIsSmallScreenWidth( false );
            }
      }, [windowSize])

      const router = createBrowserRouter(
            [{
                  path: "/",
                  element: <Layout visible={showSideBar} isSmallScreenWidth={isSmallScreenWidth} displaySideBarMenu={displaySideBarMenu}  />,
                  errorElement: <ErrorPage />,

                  children: [
                        { path: "login", element: <Login/> },
                        { path: "cut-list-history", element: <CutListHistoryHome /> },
                        { path: "cut-list-history/batch/:datetime", element: <CutListHistorySyncReport /> },
                        { path: "daily-activity", element: <DailyActivityReport /> },
                        { path: "fip-by-style-size-year", element: <FIPByStyleSizeYear /> },
                        { path: "end-of-day", element: <EndOfDaySlackReport showSideBarMenu={displaySideBarMenu} isMenuVisible={showSideBar} /> },
                        { path: "boxing-prep-last-inventory", element: <BoxingPrepLastInventoryReport /> },
                        { path: "boxing-prep-activity", element: <BoxingPrepActivityReport /> },
                        { path: "boxing-prep-current-inventory", element: <BoxingPrepCurrentInventoryReport /> },
                        { path: "pallet-inventory", element: <PalletInventorySelection /> },
                        { path: "pallet-inventory-batch/:batchid", element: <PalletInventorySummaryReport /> },
                        { path: "pallet-inventory-current/", element: <PalletInventorySummaryReport /> },
                        { path: "waste-report", element: <WasteReport/> },
                        { path: "frame-history", element: <FrameHistoryRoot/> },
//                        { path: "fba-picklist", element: <ProtectedRoute {...defaultProtectedRouteProps} outlet={<ErrorPage />}></ProtectedRoute>  }
                        { path: "fba-picklist", element: <ProtectedRoute {...defaultProtectedRouteProps} outlet={ <FBAPicklist />}></ProtectedRoute>},
                        { path: "deco-net-mapping", element: <ConsumptionMappingReport/>  },
                        { path: "station-productivity-report", element: <StationProductivityReport/>  },
                        { path: "frames-boxed-report", element: <FramesBoxedReport showSideBarMenu={displaySideBarMenu} isMenuVisible={showSideBar}/> },
                        { path: "frames-inventory-report", element: <FrameInventoryReport showSideBarMenu={displaySideBarMenu} isMenuVisible={showSideBar}/> },
                        { path: "frames-inventory-batch/:batchid/:frameType", element: <FrameInventoryBatchReport /> },
                        { path: "user-productivity-report/", element: <UserProductivityReport /> },
                        { path: "pallet-inventory-list-report/", element: <PalletInventoryListReportSelectBatch /> },
                        { path: "pallet-inventory-list-batch/:batchid", element: <PalletInventoryListReport /> },
                  ],

            }],
            {
                  basename: "",
            }
      )

            ;
      return (
            <div className="App">   
                  <RouterProvider router={router} />
            </div>
      );
}

export default App;
