import Config from "../config";

class AuthService {

 static async login(username: string, password: string) {
      AuthService.logout();
      console.log( Config.baseUrl + '/auth/login/reports' );
      const response = await fetch( Config.baseUrl + '/auth/login/reports', {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
           // mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify( { username, pwd: password}), // body data type must match "Content-Type" header
          });

          if ( response.status >= 200 && response.status <= 300) {

            const jsonResponse = await response.json(); // parses JSON response into native JavaScript objects

            if ( jsonResponse.success && jsonResponse.success === true && jsonResponse.apikey ) {
                  localStorage.setItem("user", JSON.stringify( {key: jsonResponse.apikey}));
                  return true;
            } else {
                  return false;
            } 
                 
      }else {
                  alert("An error occured trying to login.");
                  return false;
       }     

       
  }

  static logout() {
    localStorage.removeItem("user");
  }

  /*
  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }
   */

  static getCurrentUser() {
      let user = localStorage.getItem('user');
      if ( user ) {
          return JSON.parse( user );;
      } else {
            return "";
      }
  }
}

export default AuthService;