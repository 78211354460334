import useFetch from "../../hooks/use-fetch";
import { Station, StationEntry, UserActivity } from '../../models/daily-activity.models'
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Card } from 'primereact/card';
import { User } from "../../models/user";
import UserPicker from "../components/user-picker";
import NextPreviousDayButtons from "../components/next-previous-day-buttons";
import UserSummaryPanel from "./daily-activity-by-station/user-summary";
import StationPanel from "./daily-activity-by-station/station-panel";
import { ToggleButton } from 'primereact/togglebutton';
import Config from "../../config";
/*
interface IDailyActivityReportProps {
      userId: string;
      date: string;
}
*/
interface DailyActivityReportResponse {
      date: string;

}

interface IDailyActivityResponse {
      usersActivity: UserActivity[];
      activities: StationEntry[];
      users: User[];
}
//(props: IDailyActivityReportProps) => {
const DailyActivityReport = () => {

      // make this work for a selected date and user if 
      const [date, setDate] = useState<Date | null>(null);
      const [user, setUser] = useState<User | null>(null);
      const [displayByStation, setDisplayByStation] = useState<boolean>(true);
      const url = date && `${Config.baseUrl}/api/admin/reports/days-activity?date=${date.toJSON().slice(0, 10).split('-').reverse().join('-')}&userid=${user == null ? -1 : user.userId}&orderbystation=${displayByStation}`;
      const [query, setQuery] = useState('');
      const [showEventIds, setShowEventIds] = useState<boolean>(false);
      const { data, loading, error } = useFetch<IDailyActivityResponse>(url ?? "", [], {});

      let curUser = ""
      function onUserChange(user: User) {
            setUser(user);
      }

      function didChangeUserDisplay(userName: string) {
            if (curUser !== userName) {
                  curUser = userName;
                  return true;
            } else {
                  return false;
            }
      }

      function onChangeDate(days: number) {
            if (date) {
                  let x = new Date(date.setDate(date.getDate() + days));
                  setDate(x);
            }
      }
      
      useEffect(() => {
            setDate(new Date());

      }, []);

      function summarizeData(userName: string) {
            //build a summary of total frames / tasks and average times at each station (per user)
            const station = {}
            const userEntries = data.activities.filter(a => a.userName === userName)

            const stations = [...new Set(userEntries.map(a => a.stationId))]
            const stationStats = {
                  totalFrames: 0,
                  timeAtStation: 0,
                  timeOnFrames: 0,
                  avgTimePerFrame: 0,
                  durationPerFrame: 0,
                  timedTasks: {
                        taskName: "",
                        totalTime: "",
                  },
                  counterTasks: {
                        taskName: "",
                        averageTimePerTask: ""
                  }
                  // stats for tasks would depnd on the task type.
                  // if its a counter task, then its average
                  // if it a timer only tasks, just accumulated time
            }
      }

      function orderDataByClock() {
            let result = [];
            for (let entry in data) {


            }
      }

      return (
            <div className='report'>
                  <>

                        <div className="calendar-selector">
                              <div className='flex flex-row pl-5'>
                                    <div className="text-2xl text-white pr-5 align-self-center">Select Date: </div>
                                    <Calendar value={date} onChange={(e) => { console.log(e.value); setDate((e.value as Date)) }} dateFormat="mm-dd-yy" showIcon showButtonBar touchUI />
                              </div>
                              {date && <NextPreviousDayButtons onChangeDate={onChangeDate} />}
                              {data && data.users &&
                                    <UserPicker users={data.users} onUserChange={onUserChange} />
                              }
                              <ToggleButton className="ml-2" onLabel="Displaying by Station" offLabel="Displaying by time" onIcon="pi pi-list" offIcon="pi pi-clock"
                                    checked={displayByStation} onChange={(e) => setDisplayByStation(e.value)} />
                              <div className="card flex justify-content-center align-items-center text-white pl-6">
                                    Display Event Ids <Checkbox onChange={e => setShowEventIds(e.checked ?? false)} checked={showEventIds}></Checkbox>
                              </div>
                        </div>


                        <Card>

                              <div className="report-title-wrapper">
                                    <div className="report-title">Daily Activity</div> <div className="report-date-title"> {date?.toDateString()}</div>
                              </div>

                              {error && <p>An error occurred loading the report.</p>}

                              {loading && <p> <ProgressSpinner /> Loading...</p>}
                              {data && data.usersActivity && data.usersActivity.map((userActivity: UserActivity) => {
                                    return (
                                          <>
                                                <UserSummaryPanel userName={userActivity.userName} userActivity={userActivity} />

                                                {userActivity.stations.map((station: Station) => {
                                                      return <StationPanel userName={userActivity.userName} stationId={station.stationId} stationEntries={station.stationEntries} showEventIds={false} />
                                                })
                                                }


                                          </>
                                    )
                              })}

                        </Card>
                  </>

            </div>
      )


}

export default DailyActivityReport;