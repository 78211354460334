import { useEffect, useState } from "react";
import { CuttingBatchSummaryRow, EntryTypeEnum, IFrameEntry, StationEntry } from "../../../models/daily-activity.models";
import { StationsEnum } from "../../../models/stations-enum";
import { DateUtils } from "../../../utils/date-utils";
import { DurationUtils } from "../../../utils/duration-utils";
import StationTimerStartStopPanel from "./station-timer-start-stop-panel";
import CuttingBatchActions from "./cutting-batch-items";
import CuttingBatchHeader from "./cutting-batch-header";
import { Panel } from "primereact/panel";

interface IStationPanelPanel  {
      userName: string;
      stationId: string;
      stationEntries: StationEntry[];
      showEventIds: boolean;
}

const StationPanel= (props: IStationPanelPanel) => {
      const [uniqueFramesCompleted, setUniqueFramesCompleted] = useState(0);
      const [framesCut, setFramesCut] = useState(0);
      const [totalFramesWorkedOn, setTotalFramesWorkedOn] = useState(0);
      const [avgTimePerFrame, setAvgTimePerFrame] = useState<string>( "" );
      const [avgTimePerFrameCutting, setAvgTimePerFrameCutting] = useState<string>( "" );
      const [avgTimePerFrameWhileAtStation, setAvgTimePerFrameWhileAtStation] = useState(0);
      const [totalTimeAtStation, setTotalTImeAtStation] = useState<string>("");

      useEffect(() => {

            function getUniqueFrameCount() {
                  let serials: string[] = [];
                  props.stationEntries.forEach ( e => {
                        // @ts-ignore 
                        let nonTasks: IFrameEntry[]= e.actions.filter( e=> e.entryType !== EntryTypeEnum.STATION_TASK && e.entryType !== EntryTypeEnum.CUTTING_BATCH_SUMMARY);
                        nonTasks.forEach( frame => {
                              serials.push( frame.frameSerialId);
                        })
                  })
      
                  let uniqueSerials = new Set( serials );
                  setUniqueFramesCompleted(uniqueSerials.size );
            }
            getUniqueFrameCount();


            function getFramesCut(){
                  let totalFramesCut = 0;
                  props.stationEntries.forEach ( e => {
                        let frames = e.actions.filter( e=> e.entryType === EntryTypeEnum.CUTTING_BATCH_SUMMARY);
                        totalFramesCut += frames.reduce ( function( result, item ) {
                              return result +  ( isNaN( (item as CuttingBatchSummaryRow).quantity ) ? 0 :  (item as CuttingBatchSummaryRow).quantity );
                        }, 0)
                  })
      
                  setFramesCut(totalFramesCut );
                  
            }
            getFramesCut()

            function framesCompleted(): number {

                  //TODO:  this needs to get unique serials

                  return props.stationEntries.reduce( function ( result, item ) {
                        return result + (item.actions.reduce(function (result, item) {
                              return result +  1;
                        }, 0));
                  }, 0);

            }

            function getAverageTimePerFrame() {
            let durations = props.stationEntries.map( entry => {

                        if (  entry.actions && entry.actions.length > 0 )  {     
                               // @ts-ignore            
                              let totalDuration =  entry.actions.map( e=>  DurationUtils.toSeconds( e.duration ) ).reduce(function (result, item) {
                                    return result + item;
                              }, 0);
                        return totalDuration;
                        } else {
                              return  0;
                        }
                  });
                  const totalDuration = durations.reduce( function( result, item ) {
                        return result + item;
                  }, 0);
                  if ( totalDuration === 0 || uniqueFramesCompleted === 0 ) {
                        setAvgTimePerFrame( "n/a" );
                  } else {
                        const avg = DateUtils.toHHMMSS_string( totalDuration / uniqueFramesCompleted );
                        setAvgTimePerFrame( avg );
                  }

            }
            getAverageTimePerFrame();

            function getAverageTimePerFrameCutting() {
                  let durations = props.stationEntries.map( entry => {              
                        let totalDuration =  DurationUtils.toSeconds( entry.duration );
                        return totalDuration;                        
                  });

                  const totalDuration = durations.reduce( function( result, item ) {
                        return result + item;
                  }, 0);
                  if ( totalDuration === 0 || framesCut === 0 ) {
                        setAvgTimePerFrameCutting( "n/a" );
                  } else {
                        const avg = DateUtils.toHHMMSS_string( totalDuration / framesCut );
                        setAvgTimePerFrameCutting( avg );
                  }
            }
            
            getAverageTimePerFrameCutting();


            function totalTimeAtStation() {
                  let duration = props.stationEntries.reduce( function( result, item ){
                        return result + DurationUtils.toSeconds( item.duration );
                  }, 0);
                  setTotalTImeAtStation( DateUtils.toHHMMSS_string(duration) );

            }
            totalTimeAtStation();
      })

      return (
            <div> 

                  
                  <div className="pl-3 text-3xl text-white text-left bg-teal-500">{StationsEnum.toDisplayName( props.stationId )}</div>

                  { ( props.stationId === StationsEnum.alloyCutting.toString() || props.stationId === StationsEnum.cutting.toString() ) &&
                        <div className="flex flex-column align-items-start">
                              <div className="bg-white text-base w-full p-2">
                                    <div className="entry m-1 flex text-black-alpha-90"> <div className="title-gray">Total time at station: </div> { totalTimeAtStation}</div>
                                    <div className="entry m-1 flex text-black-alpha-90"> <div className="title-gray">Frames Cut: </div> { framesCut }</div>
                                    <div className="entry m-1 flex text-black-alpha-90"> <div className="title-gray">Avg Time per Frame: </div> { avgTimePerFrameCutting}</div>                        
                              </div>
                        </div>
                  }

                  { !( props.stationId === StationsEnum.alloyCutting.toString() || props.stationId === StationsEnum.cutting.toString() ) &&
                 
                        <div className="flex flex-column align-items-start">
                              <div className="bg-white text-base w-full p-2">
                                    <div className="entry m-1 flex text-black-alpha-90"> <div className="title-gray">Frames Completed: </div> { uniqueFramesCompleted}</div>
                                    <div className="entry m-1 flex text-black-alpha-90"> <div className="title-gray">Avg Time per Frame: </div> { avgTimePerFrame}</div>                        
                              </div>
                        </div>
                  }


     
     
                  { props.stationEntries && props.stationEntries.map ( ( stationEntry: StationEntry ) => {        
                        return (stationEntry.stationId === StationsEnum.alloyCutting.toString() || stationEntry.stationId === StationsEnum.cutting.toString()    
                              ? 
                               <Panel className="w-full" toggleable collapsed header={  <CuttingBatchHeader showEventIds={props.showEventIds} stationEntry={stationEntry} userName={""} stationId={""} /> } >                        
                                    <CuttingBatchActions  showEventIds={props.showEventIds} stationEntry={stationEntry}  actions={stationEntry.actions as CuttingBatchSummaryRow[]} />
                                </Panel>  
      
                              :  <StationTimerStartStopPanel key={stationEntry.eventHistoryId } showEventIds={props.showEventIds} stationEntry={stationEntry} actions={stationEntry.actions} />
                        )
                  })}
            </div>
      );
}

export default StationPanel;