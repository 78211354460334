import useFetch from "../../hooks/use-fetch";
import { StationEntry, UserActivity } from '../../models/daily-activity.models'
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { User } from "../../models/user";
import NextPreviousDayButtons from "../components/next-previous-day-buttons";
import Config from "../../config";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DurationUtils } from "../../utils/duration-utils";
import { StationsEnum } from "../../models/stations-enum";
import { DateUtils } from "../../utils/date-utils";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-enterprise";
import { Button } from "primereact/button";

/*
interface IDailyActivityReportProps {
      userId: string;
      date: string;
}
*/
interface IWasteEntry {
      length: number,
      frameStyle: string,
      frameCategory: string,
      frameProfile: string,
      description: string,
      comment: string,
      date: string,
      user: string,
}

interface IWasteResponse {
      premiere: IWasteEntry[],
      alloyPrismatic: IWasteEntry[]
      alloyScoop: IWasteEntry[]
}

//(props: IDailyActivityReportProps) => {
const WasteReport = (  ) => {

      // make this work for a selected date and user if 
      const [ startDate, setStartDate] = useState<Date | null>(null);
      const [ endDate, setEndDate] = useState<Date | null>(null);
     
      const url = startDate && endDate && `${Config.baseUrl}/api/waste/report/?start_date=${DateUtils.toYYYYMMDDString(startDate)}&end_date=${DateUtils.toYYYYMMDDString(endDate)}`;

      const [query, setQuery] = useState('');

      const gridRefPremiere = useRef<AgGridReact<GridRow>>(null);
      const gridRefAlloyScoop = useRef<AgGridReact<GridRow>>(null);
      const gridRefAlloyPrismatic = useRef<AgGridReact<GridRow>>(null);

      const { data, loading, error } = useFetch<IWasteResponse>(url ?? "", [], {});

      const showHours = false;

      function onChangeStartDate(days: number) {
            if (startDate) {
                  let x = new Date(startDate.setDate(startDate.getDate() + days));
                  setStartDate(x);
            }
      }
      function onChangeEndDate(days: number) {
            if (endDate) {
                  let x = new Date(endDate.setDate(endDate.getDate() + days));
                  setEndDate(x);
            }
      }
      function hasData( x: any ) {
            return x && x.length > 0  ? true : false;
      }

      interface IObjectKeys {
            [key: string]: string | number;
      }
      interface GridRow extends IWasteEntry, IObjectKeys {
            id: string;  //frameStyleId
      }

      const [clipboard, setClipBoard] = useState<string>("");

      const [rowData, setRowData] = useState<GridRow[]>([]);
      const [rowDataAlloyScoop, setRowDataAlloyScoop] = useState<GridRow[]>([]);
      const [rowDataAlloyPrismatic, setRowDataAlloyPrismatic] = useState<GridRow[]>([]);

      const [frameType, setFrameType] = useState<'premiere'|'alloy'>('premiere');

      const [columnDefs, setColumnDefs] = useState([
            {
                  field: "length",
                  headerName: "Length",
                  textAlign: 'left',
                  cellClass: 'ag-left-aligned-cell',
                  minWidth: 60,
                  //// pinned: 'left',

            },
            {
                  field: "frameStyle",
                  headerName: "Frame Style",
                  textAlign: 'left',
                  cellClass: 'ag-left-aligned-cell',
                  minWidth: 100,
                  maxWidth: 175,
                  //   pinned: 'left',
            },
            {
                  field: "description",
                  headerName: "Description",
                  textAlign: 'left',
                  cellClass: 'ag-left-aligned-cell',
                  minWidth: 100,
                  maxWidth: 150,
                  //   pinned: 'left',
            },
            {
                  field: "comment",
                  headerName: "Comment",
                  textAlign: 'left',
                  cellClass: 'ag-left-aligned-cell',
                  minWidth: 100,
                  maxWidth: 200,
                  //   pinned: 'left',
            },
            {
                  field: "user",
                  headerName: "Team Member",
                  headerClass: 'ag-right-aligned-header' ,
                  type: 'valueColumn', 
                  cellClass: 'ag-right-aligned-cell',
                  valueParser: (params: any) => Number(params.newValue),
                  cellEditor: 'agNumberCellEditor',
                  cellEditorParams: {
                        min: 1,
                        max: 100,
                        precision: 0,
                  },
                  minWidth: 100,
            },                              
            {
                  field: "date",
                  headerName: "Date",
                  headerClass: 'ag-right-aligned-header' ,
                  type: 'valueColumn', 
                  cellClass: 'ag-right-aligned-cell',
                  valueParser: (params: any) => Number(params.newValue),
                  cellEditor: 'agNumberCellEditor',
                  cellEditorParams: {
                        min: 1,
                        max: 100,
                        precision: 0,
                  },
            },
            
      ]);

      const columnTypes = {
            valueColumn: {
                  editable: true,
                  aggFunc: 'sum', // for column total row
                  valueParser: (params: any) => Number(params.newValue),  // for column total row
                  filter: 'agNumberColumnFilter',  // for column total row
            }
      }

      const defaultColDef = useMemo<ColDef>(() => {
            return {
                  editable: true,
                  sortable: true,
                  resizable: true,
                  filter: true,
                  flex: 1,

            };
      }, []);

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

  useEffect(() => {

      function getData() {

            let d = [];

            let prismaticRows: GridRow[] = [];
            let scoopRows: GridRow[] = [];
            let premiereRows: GridRow[] = [];

            if (data && data.premiere && data.premiere.length > 0) {
                  for (let entry of data.premiere) {

                        let row = {} as GridRow;
                        row.id = entry.date + entry.user; // required by the grid to lookup rows
                        row.length = entry.length;
                        row.frameStyle = entry.frameStyle;
                        row.description = entry.description;
                        row.comment = entry.comment;
                        row.date = entry.date
                        row.user = entry.user;
                        premiereRows.push(row);
                  }
                  setRowData(premiereRows);
            }

            if (data && data.alloyPrismatic && data.alloyPrismatic.length > 0) {
                  for (let entry of data.alloyPrismatic) {

                        let row = {} as GridRow;
                        row.id = entry.date + entry.user; // required by the grid to lookup rows
                        row.length = entry.length;
                        row.frameStyle = entry.frameStyle;
                        row.description = entry.description;
                        row.comment = entry.comment;
                        row.date = entry.date
                        row.user = entry.user;
                        prismaticRows.push(row);
                  }
                  setRowDataAlloyPrismatic(prismaticRows);
            }
            if (data && data.alloyScoop && data.alloyScoop.length > 0) {
                  for (let entry of data.alloyScoop) {

                        let row = {} as GridRow;
                        row.id = entry.date + entry.user; // required by the grid to lookup rows
                        row.length = entry.length;
                        row.frameStyle = entry.frameStyle;
                        row.description = entry.description;
                        row.comment = entry.comment;
                        row.date = entry.date
                        row.user = entry.user;
                        scoopRows.push(row);
                  }
                  setRowDataAlloyScoop(scoopRows);
            }

      }

  

//////////////////////////////////////////////////////////////////////////////////////////////
            // what is left in the consumed arrays is not neither in the main arrrry or the received array
       


      getData()

}, [data]);

const onGridReadyPremiere = useCallback((params: any) => {

      gridRefPremiere.current?.api.sizeColumnsToFit({
                  defaultMinWidth: 100,
                  columnLimits: [{ key: 'frameStyle', minWidth: 400 }],
            });
}, []);

const onGridReadyAlloyScoop = useCallback((params: any) => {

      gridRefAlloyScoop.current?.api.sizeColumnsToFit({
                  defaultMinWidth: 100,
                  columnLimits: [{ key: 'frameStyle', minWidth: 400 }],
            });
}, []);

const onGridReadyAlloyPrismatic = useCallback((params: any) => {

      gridRefAlloyPrismatic.current?.api.sizeColumnsToFit({
                  defaultMinWidth: 100,
                  columnLimits: [{ key: 'frameStyle', minWidth: 400 }],
            });
}, []);

const onBtCopyRows = useCallback((sectionName: string ) => {
      let gridRef = null;
      if ( sectionName ==='premiere'){
            gridRef = gridRefPremiere;
      } else if ( sectionName === "alloy-scoop") {
            gridRef = gridRefAlloyScoop;

      } else if ( sectionName === 'alloy-prismatic') {
            gridRef = gridRefAlloyPrismatic;
      }

      gridRef!.current?.api.forEachNode(node => node.setSelected(true));
      gridRef!.current?.api.copySelectedRowsToClipboard();
      gridRef!.current?.api.forEachNode(node => node.setSelected(false));
    
}, []);

const exportToExcel = ( sectionName: string) => {
      let gridToExport = null;
      if ( sectionName ==='premiere'){
            gridToExport = gridRefPremiere;
      } else if ( sectionName === "alloy-scoop") {
            gridToExport = gridRefAlloyScoop;

      } else if ( sectionName === 'alloy-prismatic') {
            gridToExport = gridRefAlloyPrismatic;
      }
      // @ts-ignore 
      gridToExport.current?.api.exportDataAsExcel({
            processRowGroupCallback: (params: any) => {
                  const { node } = params;
                  return `${node.key} (${node.allChildrenCount})`;
            }
      });
   
};

      return (
            <div className='report'>

    
                        <div className="calendar-selector">
                              <div className='flex flex-row pl-5'>
                                    <div className="text-2xl text-white pr-5 align-self-center">Select Start Date: </div>
                                    <Calendar value={startDate} onChange={(e) => { console.log(e.value); setStartDate((e.value as Date)) }} dateFormat="mm-dd-yy" showIcon showButtonBar touchUI />
                              </div>
                              {startDate && <NextPreviousDayButtons onChangeDate={onChangeStartDate} />}
                    
                        </div>
                        <div className="calendar-selector">
                              <div className='flex flex-row pl-5'>
                                    <div className="text-2xl text-white pr-5 align-self-center">Select End Date: </div>
                                    <Calendar value={endDate} onChange={(e) => { console.log(e.value); setEndDate((e.value as Date)) }} dateFormat="mm-dd-yy" showIcon showButtonBar touchUI />
                              </div>
                              {endDate && <NextPreviousDayButtons onChangeDate={onChangeEndDate} />}
                    
                        </div>

                  <Card>
                        <div className="report-title-wrapper">
                               <div className="report-title"> Waste Report</div> 
                               <div className="report-date-title">{startDate?.toDateString()}
                                    { startDate !== endDate &&
                                    <span>
                                          &nbsp; to {endDate?.toDateString()}
                                           </span> 
                                    }
                               
                               </div>
                               
                        </div>
      
                        {error && <p>An error occurred loading the report.</p>}

                        {loading && <p> Loading...</p>}

              
                        <div className="ag-theme-alpine" > { /*, width: "95%" */}
                        <div className="alloy-header"> Premiere Waste
                              <Button label="Export to Excel" icon="pi pi-file-export" onClick={ () => exportToExcel('premiere')} />
                              <Button label="Copy All to Clipboard" icon="pi pi-copy" onClick={() => onBtCopyRows('premiere')} />
                        </div>
                              <AgGridReact<GridRow>
                                    ref={gridRefPremiere}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    columnTypes={columnTypes}
                                    defaultColDef={defaultColDef}
                                    pagination={false}
                                    rowMultiSelectWithClick={true}
                                    enableRangeSelection={true}
                                    rowSelection={'multiple'}
                                    onGridReady={onGridReadyPremiere}
                                    groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    domLayout={'autoHeight'}
                              ></AgGridReact>
                        </div>
                              
                        <div className="ag-theme-alpine">
                              <div className="alloy-header"> Alloy Scoop Waste
                              <div className="pl-3">
                                    <Button label="Export to Excel" icon="pi pi-file-export" onClick={() => exportToExcel('alloy-scoop')} />
                                    <Button label="Copy All to Clipboard" icon="pi pi-copy" onClick={() => onBtCopyRows('alloy-scoop')} />
                                    </div>
                              </div>
                              <AgGridReact<GridRow>
                                    ref={gridRefAlloyScoop}
                                    rowData={rowDataAlloyScoop}
                                    columnDefs={columnDefs}
                                    columnTypes={columnTypes}
                                    defaultColDef={defaultColDef}
                                    pagination={false}
                                    rowMultiSelectWithClick={true}
                                    enableRangeSelection={true}
                                    rowSelection={'multiple'}
                                    onGridReady={onGridReadyAlloyScoop}
                                    groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    domLayout={'autoHeight'}
                              ></AgGridReact>
                        </div>                         

                        <div className="ag-theme-alpine">
                              <div className="alloy-header"> Alloy Prismatic Waste
                              
                              <Button label="Export to Excel" icon="pi pi-file-export" onClick={() => exportToExcel('alloy-prismatic')} />
                              <Button label="Copy All to Clipboard" icon="pi pi-copy" onClick={() => onBtCopyRows('alloy-prismatic')} />
                              
                              </div>
                              <AgGridReact<GridRow>
                                    ref={gridRefAlloyPrismatic}
                                    rowData={rowDataAlloyPrismatic}
                                    columnDefs={columnDefs}
                                    columnTypes={columnTypes}
                                    defaultColDef={defaultColDef}
                                    pagination={false}
                                    rowMultiSelectWithClick={true}
                                    enableRangeSelection={true}
                                    rowSelection={'multiple'}
                                    onGridReady={onGridReadyAlloyPrismatic}
                                    groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    domLayout={'autoHeight'}
                              ></AgGridReact>
                        </div>   
                    </Card>

                    
            </div>
      )


}

export default WasteReport;