import { useRef, useState } from "react";
import useFetch from "../hooks/use-fetch";
import FrameLookupDTO from "./models/frame-lookup.dto";
import Config from "../config";
import FrameImage from './frame-image';
import FrameHistoryTimeLine from './frame-history-timeline';

import { IconField } from "primereact/iconfield";
import { InputOtp } from 'primereact/inputotp';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';

//(props: IDailyActivityReportProps) => {
const FrameHistoryRoot = ( ) => { 
      const [frameSerialInput, setFrameSerialInput] = useState<string | number>('');
      const [frameSerialToLookup, setFrameSerialToLookup] = useState<string | number>('');
      const [frameType, setFrameType] = useState('');
      const [inputValidationError, setInputValidationError] = useState('');

      const url = `${Config.baseUrl}/api/public/scanner/frame/${frameType}/${frameSerialToLookup}`;

      const [query, setQuery] = useState('');
      const { data, loading, error } = useFetch<FrameLookupDTO>(url ?? "", [], {});
      const inputMaskRef = useRef<HTMLInputElement | null>(null);

      const resetInput = () => {
            let y = inputMaskRef;
            let i = 0;
      }
      return (

            <div style={{width: "80vw", height: "100vh", backgroundColor: "#f7f7f7"}}>
                  <div style={{maxWidth: "1024px", margin: "auto", backgroundColor: "whitesmoke", padding:"10px", border: "1px solid #f2f2f2"}}>

                        <div className="bg-white p-6 relative text-center"  >

                              <img className="absolute p-2" style={{ maxWidth: '300px', left:"15px", top: "28px"}} src={process.env.PUBLIC_URL + `/img/deco-tv-logo.avif`} alt='fmtv-logo' />

                              <div className='flex justify-content-center'> <h1>Frame Finder</h1></div>
                        </div>

                        <div className="flex justify-content-center flex-column ">
                              <div className="card flex justify-content-center flex-column mt-5">
                                    <div className="flex flex-column align-items-center justify-content-center p-5 mb-5 bg-white">
                                          <label className="text-2xl pb-3" htmlFor="frameserial">To lookup the history of a frame enter the frame's serial</label>
                                          <div> AP209220413 rejected (nodestination)</div>
                                          <div>AP151241493 rejected (Destination)</div>
                                          <div className="flex gap-3 flex-direction-row" >
                                                <IconField iconPosition="left" className="flex-column">

                                                      {/*https://github.com/primefaces/primereact/issues/6592 issue clearing field but is still broken */}

                                                      <InputOtp title='serial' ref={el => { inputMaskRef.current = el; console.log("SET REF"); }} value={frameSerialInput} length={11}

                                                            onChange={(e) => {
                                                                  // validate first 

                                                                  if (e.value && e.value.toString().length > 1 && e.value.toString().toUpperCase().charAt(0) !== "A") {
                                                                        setInputValidationError("The first letter of a frame's serial is always 'A'. Please double check the serial number. ");
                                                                  } else if (e.value && e.value.toString().length > 2 && (e.value.toString().toUpperCase().charAt(0) !== "A" && e.value.toString().toUpperCase().charAt(0) !== "P")) {
                                                                        setInputValidationError("The second letter of a frame's serial is always an 'A' or 'P'.  Please recheck the serial number. ");
                                                                  } else if (e.value && e.value.toString().length > 2) {
                                                                        let val = e.value.toString().substring(2)
                                                                        if (isNaN((val as any))) {
                                                                              setInputValidationError("All letters after the first two should be numbers.  Please recheck the serial number.");
                                                                        } else {
                                                                              setInputValidationError("");
                                                                        }
                                                                  } else {
                                                                        setInputValidationError("");
                                                                  }
                                                                  // it may seem odd to have to set the frameSerialInput manually since it is the value tied to this control
                                                                  // but the control itself won't assign the new value to frameSerialInput, rather it keeps its own internal copy
                                                                  // and although the control works fine without manually setting the frameSerialInput,
                                                                  // the 'reset' button won't work
                                                                  if (e.value) {
                                                                        setFrameSerialInput(e.value.toString())
                                                                  }
                                                                  if (e.value) {

                                                                        if (e.value && e.value.toString().toUpperCase().startsWith("AP")) {
                                                                              setFrameType("premiere")
                                                                        }
                                                                        if (e.value && e.value.toString().toUpperCase().startsWith("AA")) {
                                                                              setFrameType("alloy")
                                                                        }
                                                                        if (e.value && e.value.toString().toUpperCase().startsWith("AD")) {
                                                                              setFrameType("premiere")
                                                                        }
                                                                        if (e.value.toString().length === 11) {
                                                                              setFrameSerialToLookup(e.value.toString().toUpperCase())
                                                                        }
                                                                  }
                                                            }}
                                                            onInput={(e) => {


                                                            }}
                                                      />


                                                      { /*
                                                <InputIcon className="pi pi-search"> </InputIcon>

                                                <InputText id="frameserial" placeholder="frame serial" aria-describedby="frameserial-help"
                                                      value={frameSerial.toString()}
                                                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            if ( e.target.value && e.target.value.toUpperCase().startsWith("AP") ){
                                                                  setFrameType("premiere")
                                                            }
                                                            if ( e.target.value && e.target.value.toUpperCase().startsWith("AA")){
                                                                  setFrameType("alloy")
                                                            }
                                                            setFrameSerial(e.target.value)
                                                      }}
                                                />
                                                */}
                                                </IconField>
                                                <Button label="Reset" onClick={() => {
                                                      setFrameSerialInput('');
                                                      setFrameSerialToLookup('');
                                                      setInputValidationError('');
                                                      setFrameType('');
                                                      resetInput()
                                                }} />
                                          </div>

                                          {inputValidationError &&
                                                <div className="my-5 text-2xl pb-3 text-red-400"> {inputValidationError}</div>
                                          }

                                    </div>
                              </div>
                              {data && error && <p>An error occurred searching for the frame.</p>}

                              {loading &&
                                    <div className='flex flex-column'>

                                          <ProgressSpinner style={{ width: '150px', height: '150px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                          <div className="text-2xl pb-3">  Looking for the frame that matches the serial number...    </div>
                                    </div>}

                              {data && data.wasFound === false &&
                                    <div className="text-2xl mt-4 pb-3"> No frame was found with this serial number. Please verify the serial is correct.</div>
                              }

                              {!data &&

                                    <Card className='flex flex-column'>
                                          <div className='flex flex-column' >
                                                <div className="text-2xl pb-3">The frame's serial can be located on the box label where the red arrow points below. </div>
                                                <div style={{ minWidth: "350px" }}>
                                                      <img style={{ minWidth: "350px" }} src={process.env.PUBLIC_URL + `/img/find_serial_box_label.jpg`} alt="find-serial-frame" />
                                                </div>
                                          </div>
                                          <div className="text-2xl mt-5 mb-2"> OR in the box, the frame has a label with a serial where the red arrow indicates below</div>
                                          <div >
                                                <img className="border-1" style={{ minWidth: "450px" }} src={process.env.PUBLIC_URL + `/img/find_serial_frame_qr.jpg`} alt="find" />
                                          </div>
                                    </Card>


                              }
                              {data && data && data.wasFound === true &&
                                    <div className="flex flex-column justify-content-center align-items-center">
                                          <div className='card flex flex-column justify-content-center align-items-center ' style={{ paddingTop:"55px", width: "575px", border: "3px solid #eee6de", backgroundColor:"white",  margin:"25px" }}  >
                                                <div className='relative'>
                                                      <FrameImage imageName={data.frame?.image ?? "NOT_FOUND"} />
                                                      {data.isVoid === true &&
                                                            <div className='absolute text-red-600 text-7xl' style={{ bottom: "50%", left: "80px" }}> Voided </div>
                                                      }
                                                </div>

                                                <div className='text-4xl p-2' >  {data.frame?.name} {data.frame?.size}"</div>
                                          </div>
                                          <div className='flex justify-content-center' style={{maxWidth:"1024px"}} >
                                                <FrameHistoryTimeLine frame={data.frame!} />
                                          </div>
                                    </div>
                              }
                        </div>
                  </div>
            </div>
            
      )



}

export default FrameHistoryRoot;