import React from 'react';
import Uppy from '@uppy/core';
import GoogleDrive from '@uppy/google-drive';
import RemoteSources from '@uppy/remote-sources';
import { Dashboard, DashboardModal, DragDrop, ProgressBar, FileInput } from '@uppy/react';
import XHR from '@uppy/xhr-upload'

import "@uppy/core/dist/style.css"
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import AuthService from '../../auth/auth-service';
import Config from '../../config';

interface UppyProps {

}

interface UppyState {
      showInlineDashboard: boolean;
      open: boolean;
}

export default class UppyDropZone extends React.Component<UppyProps, UppyState> {

      uppy: Uppy<Record<string, unknown>, Record<string, unknown>>

      constructor(props: any) {
            super(props)

            this.state = {
                  showInlineDashboard: false,
                  open: false
            };
            const url = Config.baseUrl + '/api/google-sheet/fba/upload';
          //  console.log( url )
          //  console.log( `${AuthService.getCurrentUser().key}` )
            this.uppy = new Uppy({ id: 'uppy1', autoProceed: true, debug: true })
                  .use(RemoteSources, { companionUrl: 'https://companion.uppy.io', sources: ['GoogleDrive', 'Box', 'Dropbox', 'OneDrive', 'Unsplash', 'Url'] })
                  .use(XHR, {
                        endpoint:  url, 
                        headers: {
                              'X-API-KEY': `${AuthService.getCurrentUser().key}`
//                              authorization: `X-API-KEY: ${AuthService.getCurrentUser().key}`,
                        },
                  }
                  )

            this.handleModalClick = this.handleModalClick.bind(this)
      }

      componentWillUnmount() {
            this.uppy.close({ reason: 'unmount' })
      }

      handleModalClick() {
            this.setState({
                  open: !this.state.open
            })
      }

      render() {
            const { showInlineDashboard } = this.state
            return (
                  <>

                        <Dashboard
                              uppy={this.uppy}
                              plugins={['GoogleDrive']}
                              metaFields={[
                                    { id: 'name', name: 'Name', placeholder: 'File name' }
                              ]}
                        />


                        <ProgressBar
                              uppy={this.uppy}
                              hideAfterFinish={false}
                        />

                  </>
            )
      }
}