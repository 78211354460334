import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Config from "../../config";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { Card } from "primereact/card";
import useFetch from "../../hooks/use-fetch";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import PalletInventoryBatchModel from "./dtos/pallet-inventory-batch-model";
interface IPalletInventorySelection {
      inventoryId: string
      date: string;
}

const PalletInventoryListReportSelectBatch = () => {

      const [rowData, setRowData] = useState<PalletInventoryBatchModel[]>([]);
      const [selectedBatch, setSelectedBatch] = useState<PalletInventoryBatchModel>();
      const [metaKey, setMetaKey] = useState(true);

      const url = `${Config.baseUrl}/api/temp-pallet-count/batch-history`;

      const { data, loading, error } = useFetch<PalletInventoryBatchModel[]>(url ?? "", [], {});
   

      useEffect(() => {

            function getData() {
                  if (data && data && data.length > 0) {
                        setRowData( data );
                  }
            }
            getData()

      }, [data])
      
      const navigate = useNavigate();

      useEffect(() => {
            if ( selectedBatch ) {
                navigate( `/pallet-inventory-list-batch/${selectedBatch.palletInventoryBatchId}`);
            }

      }, [selectedBatch] );

      
      return (
            <div className='report'>
                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title"> Pallet Inventory Batches </div> <div className="report-date-title">{new Date().toDateString()}</div>
                        </div>
                        <div className="text-bluegray-600 text-2xl text-left pb-3">Select a batch to view it or <NavLink to="/pallet-inventory-current" >View Current</NavLink></div>
                        <div className="ag-theme-alpine" > { /*, width: "95%" */}

                              <DataTable stripedRows value={rowData} selectionMode="single" selection={selectedBatch}
                                    onSelectionChange={(e) => setSelectedBatch(e.value as any)} dataKey="palletInventoryBatchId" metaKeySelection={metaKey} tableStyle={{ minWidth: '50rem' }}>
                                          
                                    <Column field="palletInventoryBatchId" header="Id"></Column>
                                    <Column field="inventoryLocationName" header="Location"></Column>
                                    <Column field="startedDateLocal" header="Date"></Column>
                                    <Column field="batchState" header="BatchState"></Column>
                                    <Column field="startByUserName" header="Completed By"></Column>
                              </DataTable>

                              {error && <p>An error occurred loading the report.</p>}

                              {loading && <p> <ProgressSpinner /> Loading...</p>}
                        </div>
                  </Card>
            </div>
      )
}

export default PalletInventoryListReportSelectBatch;

/*
<div className="alloy-header"> Select a batch to view </div>
                              <AgGridReact<GridRow>
                                    ref={gridRefBatches}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    columnTypes={columnTypes}
                                    defaultColDef={defaultColDef}
                                    pagination={false}
                                    rowMultiSelectWithClick={true}
                                    enableRangeSelection={true}
                                    rowSelection={'multiple'}
                                    onGridReady={onGridReadyGridRefBatches}
                                    groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    domLayout={'autoHeight'}
                              ></AgGridReact>
                              </div>*/