import { ProductBase } from "./product-base";

export class FrameProduct extends ProductBase {     

      constructor( product: any ) {
          super(product);
          this.size =  product.modelSize;
          this.image =  product.image ?? product.modelName.toUpperCase().replace(' ', '_').replace(' ', '_');
          this.barCode = product.barCode;
          this.modelName = product.modelName;
      }
      //TODO - image is app specific, this should be a URL that is cached in the app
      // rather than being built into the app
      image: string;
      size: string;
      barCode: string;
  }