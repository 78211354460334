import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import useFetch from "../../hooks/use-fetch";
import Config from "../../config";
//import NextPreviousDayButtons from "../../components/next-previous-day-buttons";
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PalletInventoryBatchModel, PalletInventoryBatchSummaryResult, PalletMouldingEntryInventorySummary } from "./dtos/pallet-inventory-summary.dtos";
import { Button } from "primereact/button";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { useParams } from "react-router-dom";

interface IPalletInventorySummaryReport {
      inventoryId: string
      date: string;
}

interface TableRow {
      mouldingName: string;
      total: string;
}

const PalletInventorySummaryReport = () => {

      /// this should likely just list both alloy and premiere for a complete view
      const [clipboard, setClipBoard] = useState<string>("");

      const [dataFormatted, setDataFormatted] = useState<TableRow[]>([]);

      const [rowData, setRowData] = useState<GridRow[]>([]);
      const [batchSummary, setBatchSummary]  = useState<PalletInventoryBatchModel> ();
      const [rowDataAlloyScoop, setRowDataAlloyScoop] = useState<GridRow[]>([]);

      const [rowDataAlloyPrismatic, setRowDataAlloyPrismatic] = useState<GridRow[]>([]);

      const [rowDataSlim, setRowDataSlim] = useState<GridRow[]>([]);

      const [frameType, setFrameType] = useState<'premiere'|'alloy'>('premiere');

      const params = useParams();

      const [ batchNotEnded, setBatchNotEnded ] = useState<string>("");

      const [columnDefs, setColumnDefs] = useState([
            {
                  field: "sku",
                  headerName: "SKU",
                  textAlign: 'left',
                  cellClass: 'ag-left-aligned-cell',
                  minWidth: 60,
                  //// pinned: 'left',

            },
            {
                  field: "frameCategory",
                  headerName: "Frame Category",
                  textAlign: 'left',
                  cellClass: 'ag-left-aligned-cell',
                  minWidth: 100,
                  maxWidth: 175,
                  //   pinned: 'left',
            },
            {
                  field: "frameProfile",
                  headerName: "Frame Profile",
                  textAlign: 'left',
                  cellClass: 'ag-left-aligned-cell',
                  minWidth: 100,
                  maxWidth: 150,
                  //   pinned: 'left',
            },
            {
                  field: "frameStyle",
                  headerName: "Frame Style",
                  textAlign: 'left',
                  cellClass: 'ag-left-aligned-cell',
                  minWidth: 100,
                  maxWidth: 200,
                  //   pinned: 'left',
            },
            {
                  field: "feetPerBox",
                  headerName: "Feet Per Box",
                  headerClass: 'ag-right-aligned-header' ,
                  type: 'valueColumn', 
                  cellClass: 'ag-right-aligned-cell',
                  valueParser: (params: any) => Number(params.newValue),
                  cellEditor: 'agNumberCellEditor',
                  cellEditorParams: {
                        min: 1,
                        max: 100,
                        precision: 0,
                  },
                  minWidth: 100,
            },
            {
                  field: "totalBoxes",
                  headerName: "Total Boxes (Inventory)",
                  headerClass: 'ag-right-aligned-header' ,
                  type: 'valueColumn', 
                  cellClass: 'ag-right-aligned-cell',
                  valueParser: (params: any) => Number(params.newValue),
                  cellEditor: 'agNumberCellEditor',
                  cellEditorParams: {
                        min: 1,
                        max: 100,
                        precision: 0,
                  },
                  minWidth: 100,
            },                              
            {
                  field: "totalFeet",
                  headerName: "Total Feet (Inventory)",
                  headerClass: 'ag-right-aligned-header' ,
                  type: 'valueColumn', 
                  cellClass: 'ag-right-aligned-cell',
                  valueParser: (params: any) => Number(params.newValue),
                  cellEditor: 'agNumberCellEditor',
                  cellEditorParams: {
                        min: 1,
                        max: 100,
                        precision: 0,
                  },
            },
            {
                  field: "totalBoxesReceivedOnPos",
                  headerName: "Total Boxes (Received)",
                  headerClass: 'ag-right-aligned-header' ,
                  type: 'valueColumn', 
                  cellClass: 'ag-right-aligned-cell received-cell',
                  valueParser: (params: any) => Number(params.newValue),
                  cellEditor: 'agNumberCellEditor',
                  cellEditorParams: {
                        min: 1,
                        max: 100,
                        precision: 0,
                  },
            },    
            {
                  field: "totalFeetReceivedOnPos",
                  headerName: "Total Feet (Received)",
                  headerClass: 'ag-right-aligned-header' ,
                  type: 'valueColumn', 
                  cellClass: 'ag-right-aligned-cell received-cell',
                  valueParser: (params: any) => Number(params.newValue),
                  cellEditor: 'agNumberCellEditor',
                  cellEditorParams: {
                        min: 1,
                        max: 100,
                        precision: 0,
                  },
            },    

            {
                  field: "totalBoxesConsumed",
                  headerName: "Total Boxes (Consumed)",
                  headerClass: 'ag-right-aligned-header' ,
                  type: 'valueColumn', 
                  cellClass: 'ag-right-aligned-cell consumed-cell',
                  valueParser: (params: any) => Number(params.newValue),
                  cellEditor: 'agNumberCellEditor',
                  cellEditorParams: {
                        min: 1,
                        max: 100,
                        precision: 0,
                  },
            },
            {
                  field: "totalFeetConsumed",
                  headerName: "Total Feet (Consumed)",
                  headerClass: 'ag-right-aligned-header' ,
                  type: 'valueColumn', 
                  cellClass: 'ag-right-aligned-cell consumed-cell',
                  valueParser: (params: any) => Number(params.newValue),
                  cellEditor: 'agNumberCellEditor',
                  cellEditorParams: {
                        min: 1,
                        max: 100,
                        precision: 0,
                  },
            },
            {
                  field: "remainingBoxes",
                  headerName: "Total Boxes",
                  headerClass: 'ag-right-aligned-header' ,
                  type: 'valueColumn', 
                  cellClass: 'ag-right-aligned-cell',
                  valueParser: (params: any) => Number(params.newValue),
                  valueGetter: (params: any) =>  {
                        return ( params.data.totalBoxes ?? 0 )  +  (params.data.totalBoxesReceivedOnPos ?? 0) -  ( params.data.totalBoxesConsumed ?? 0 );
                  },                              
                  cellEditor: 'agNumberCellEditor',
                  cellEditorParams: {
                        min: 1,
                        max: 100,
                        precision: 0,
                  },
            },
            {
                  field: "remainingFeet",
                  headerName: "Total Feet",
                  headerClass: 'ag-right-aligned-header' ,
                  type: 'valueColumn', 
                  cellClass: 'ag-right-aligned-cell',
                  valueParser: (params: any) => Number(params.newValue),
                  valueGetter: (params: any) =>  {
                        return ( params.data.totalFeet ?? 0 ) + (params.data.totalFeetReceivedOnPos ?? 0 ) -  ( params.data.totalFeetConsumed ?? 0 )
                  },
                  cellEditor: 'agNumberCellEditor',
            
                  cellEditorParams: {
                        min: 1,
                        max: 100,
                        precision: 0,
                  },
            },
      ]);
  
      const columnTypes = {
            valueColumn: {
                  editable: true,
                  aggFunc: 'sum', // for column total row
                  valueParser: (params: any) => Number(params.newValue),  // for column total row
                  filter: 'agNumberColumnFilter',  // for column total row
            }
      }

      const defaultColDef = useMemo<ColDef>(() => {
            return {
                  editable: true,
                  sortable: true,
                  resizable: true,
                  filter: true,
                  flex: 1,

            };
      }, []);

      const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

      const url =  params && params.batchid && `${Config.baseUrl}/api/temp-pallet-count/inventory/summary/${params.batchid}`;

      const { data, loading, error } = useFetch<PalletInventoryBatchSummaryResult>(url ?? "", [], {});

      const gridRefPremiere = useRef<AgGridReact<GridRow>>(null);
      const gridRefSlim= useRef<AgGridReact<GridRow>>(null);
      const gridRefAlloyScoop = useRef<AgGridReact<GridRowAlloy>>(null);
      const gridRefAlloyPrismatic = useRef<AgGridReact<GridRowAlloy>>(null);

      const gridRefConsumptionPremiere = useRef<AgGridReact<GridRow>>(null);
      const gridRefConsumptionSlim = useRef<AgGridReact<GridRow>>(null);
      const gridRefConsumptionAlloyScoop = useRef<AgGridReact<GridRowAlloy>>(null);
      const gridRefConsumptionAlloyPrismatic = useRef<AgGridReact<GridRowAlloy>>(null);

      interface IObjectKeys {
            [key: string]: string | number;
      }

      interface GridRow extends IObjectKeys {
            id: string;  //      mouldingId: string;
            sku: string;
            frameCategory: string;
            frameProfile: string;
            frameStyle: string;
            feetPerBox: number;
            totalBoxes: number;
            totalFeet: number;
            totalBoxesReceivedOnPos: number;
            totalFeetReceivedOnPos: number;
            totalBoxesConsumed: number;
            totalFeetConsumed: number;
            remainingBoxes: number;
            remainingFeet: number;
      }

      interface GridRowAlloy extends IObjectKeys {
            id: string;  //      mouldingId: string;
            sku: string;
            frameCategory: string;
            frameProfile: string;
            frameStyle: string;
            feetPerBox: number;
            totalBoxes: number;
            totalFeet: number;
            totalBoxesReceivedOnPos: number;
            totalFeetReceivedOnPos: number;
            totalBoxesConsumed: number;
            totalFeetConsumed: number;
            remainingBoxes: number;
            remainingFeet: number;
      }

      function sortFrames ( a:any, b:any) {
            if ( !a || !b) {
                  return 0;
            }
            if ( a.frameProfile === b.frameProfile ) {
               if ( a.frameStyle === b.frameStyle ) {
                   return 0
               } else {
                   if ( a.frameStyle > b.frameStyle ) {
                         return 1;
                   } else  {
                         return -1;
                   }                        
               }
            } else {
                   if (a.frameProfile > b.frameProfile ) {
                         return 1;
                   } else  {
                         return -1;
                   }              
            }
          
      };

      useEffect(() => {

            function getData() {

                  let curStyle = ""
                  let d = [];

                  curStyle = ""

                  let prismaticRows: GridRow[] = [];
                  let scoopRows: GridRow[] = [];
                  let premiereRows: GridRow[] = [];
                  let slimRows: GridRow[] = [];

                  if ( data && data.batchSummary &&  data.batchSummary.batchState === "ENDED" ) {
                        setBatchSummary( data.batchSummary );
                  } else {

                        if ( data && data.batchSummary ) {
                              setBatchNotEnded( data.batchSummary.batchState );
                        } else {
                        //      setLoading
                        //      return;
                        }
                  }

                  if (data && data.mouldingSummary && data.mouldingSummary.length > 0) {
                        for (let entry of data.mouldingSummary) {
                            //  if (curStyle !== entry.sku) {
                              curStyle = entry.sku;

                              let row = {} as GridRow;
                              row.id = entry.mouldingId; // required by the grid to lookup rows
                              row.frameStyle = entry.frameStyle;
                              row.sku = entry.sku;
                              row.feetPerBox = entry.feetPerBox;
                              row.totalBoxes = entry.totalBoxes;
                              row.totalFeet = entry.totalFeet
                              row.frameCategory = entry.frameCategory;
                              row.frameProfile = entry.frameProfile;

                              //TODO:  with POs, they could contain rows that the last inventory does not contain so need to account for this
                              if ( data && data.posPalletsReceivedSummary  ) {
                                    row.totalBoxesReceivedOnPos = data.posPalletsReceivedSummary.mouldingBoxes.find( box => box.sku === row.sku )?.totalBoxes ?? 0;
                                    row.totalFeetReceivedOnPos = data.posPalletsReceivedSummary.mouldingBoxes.find( box => box.sku === row.sku )?.totalFeet ?? 0;
                              } else {
                                    row.totalBoxesConsumed = 0;
                                    row.totalFeetConsumed = 0;
                              }

                              //TODO: with moulding transfered/consumed, it should match a row in the last inventory count _BUT_ it could also match a row in POs received since the last count
                              if ( data && data.mouldingConsumption && data.mouldingConsumption.mouldingBoxes && data.mouldingConsumption.mouldingBoxes.length > 0 ) {
                                    row.totalBoxesConsumed = data.mouldingConsumption.mouldingBoxes.find( box => box.sku === row.sku )?.totalBoxes ?? 0;
                                    row.totalFeetConsumed = data.mouldingConsumption.mouldingBoxes.find( box => box.sku === row.sku )?.totalFeet ?? 0;
                              } else {
                                    row.totalBoxesConsumed = 0;
                                    row.totalFeetConsumed = 0;
                              }

                              //let key: string = row.mouldingId.toString()
                              // row[key]  = Number(entry.totalFeet ?? 0);
                              if ( entry.frameCategory.toLowerCase() === 'premiere' && entry.sku !== 'not created') {
                                    premiereRows.push(row);
                              } else if ( entry.frameCategory.toLocaleLowerCase() === "decoslim") {
                                    slimRows.push(row);
                              } else if ( entry.frameCategory.toLowerCase() === 'alloy') {
                                    if ( entry.frameProfile.toLowerCase() === 'prismatic' ) {
                                          prismaticRows.push(row);
                                    } else if ( entry.frameProfile.toLowerCase() === 'scoop') {
                                          scoopRows.push(row);
                                    }
                              }

                         //     } else {

                          //          let key: string = entry.mouldingId.toString()
                          //          e[e.length - 1][key] =Number(entry.totalFeet ?? 0);
                          //    }
                        }

                        // Since received items may be frame styles NOT in inventory yet, need to account for new styles and add them into the data
                        premiereRows.sort( (a,b) => sortFrames(a,b) )

                        setRowData(premiereRows);
                        setRowDataAlloyPrismatic(prismaticRows);
                        setRowDataAlloyScoop(scoopRows);
                        setRowDataSlim(slimRows);
                  }

                  // find skus in received and consumed not already on the grid -- this would be easier if just using a 'full grid off all skus'.. and maybe it should
                  let newPremiereSkusReceived:PalletMouldingEntryInventorySummary[] = [];
                  let newPrismaticSkusReceived: PalletMouldingEntryInventorySummary[] = [];
                  let newScoopSkusReceived: PalletMouldingEntryInventorySummary[] = [];
                  let newSlimSkusReceived: PalletMouldingEntryInventorySummary[] = [];

                  let newPremiereSkusConsumption:PalletMouldingEntryInventorySummary[] = [];
                  let newPrismaticConsumption: PalletMouldingEntryInventorySummary[] = [];
                  let newScoopSkusConsumption: PalletMouldingEntryInventorySummary[] = [];
                  let newSlimSkusConsumption: PalletMouldingEntryInventorySummary[] = [];
                  
                  if ( data && data.posPalletsReceivedSummary && data.posPalletsReceivedSummary.mouldingBoxes ) {
                        const premiereSkusReceived = data.posPalletsReceivedSummary.mouldingBoxes?.filter( row=>row.frameCategory === 'premiere' ) ?? [];
                        const prismaticSkusReceived = data.posPalletsReceivedSummary.mouldingBoxes?.filter( row=>row.frameProfile === 'prismatic' ) ?? [];
                        const scoopSkusReceived = data.posPalletsReceivedSummary.mouldingBoxes?.filter( row=>row.frameProfile === 'scoop' ) ?? [];
                        const slimSkusReceived = data.posPalletsReceivedSummary.mouldingBoxes?.filter( row=>row.frameProfile === 'decoSlim' ) ?? [];
                        // premiere rows received but not in existing grid
                        newPremiereSkusReceived = premiereSkusReceived?.filter( row => premiereRows.map( prow=>prow.sku ).includes( row.sku ) === false ) ?? [] ;
                        newPrismaticSkusReceived = prismaticSkusReceived?.filter( row => prismaticRows.map( prow=>prow.sku ).includes( row.sku ) === false ) ?? [];
                        newScoopSkusReceived = scoopSkusReceived?.filter( row => scoopRows.map( prow=>prow.sku ).includes( row.sku ) === false ) ?? [];
                        newSlimSkusReceived = slimSkusReceived?.filter( row => scoopRows.map( prow=>prow.sku ).includes( row.sku ) === false ) ?? [];
                  }

                  if ( data && data.mouldingConsumption ) {
                        const premiereSkusReceived = data.mouldingConsumption.mouldingBoxes?.filter( row=>row.frameCategory === 'premiere' ) ?? [];
                        const prismaticSkusReceived = data.mouldingConsumption.mouldingBoxes?.filter( row=>row.frameProfile === 'prismatic' ) ?? [];
                        const scoopSkusReceived = data.mouldingConsumption.mouldingBoxes?.filter( row=>row.frameProfile === 'scoop' ) ?? [];
                        const slimSkusReceived = data.mouldingConsumption.mouldingBoxes?.filter( row=>row.frameProfile === 'scoop' ) ?? [];

                        // consumed rows received but not in existing gird
                        newPremiereSkusConsumption = premiereSkusReceived?.filter( row => premiereRows.map( prow=>prow.sku ).includes( row.sku ) === false ) ?? [];
                        newPrismaticConsumption = prismaticSkusReceived?.filter( row => prismaticRows.map( prow=>prow.sku ).includes( row.sku ) === false ) ?? [];
                        newScoopSkusConsumption = scoopSkusReceived?.filter( row => scoopRows.map( prow=>prow.sku ).includes( row.sku ) === false ) ?? [];
                        newSlimSkusConsumption = scoopSkusReceived?.filter( row => scoopRows.map( prow=>prow.sku ).includes( row.sku ) === false ) ?? [];
                  }
                  
                  for ( let entry of newPremiereSkusReceived ) {
                        //combine if exists
                        let consumedIdx = newPremiereSkusConsumption.findIndex( row => row.mouldingId === entry.mouldingId );
                        let consumed = null;
                        if ( consumedIdx > -1 ) {
                              consumed =   newPremiereSkusConsumption.splice( consumedIdx, 1)[0];
                        }

                        let row = {} as GridRow;
                        row.id = entry.mouldingId; // required by the grid to lookup rows
                        row.frameStyle = entry.frameStyle;
                        row.sku = entry.sku;
                        row.feetPerBox = entry.feetPerBox;
                        row.totalBoxes = entry.totalBoxes;
                        row.totalFeet = entry.totalFeet
                        row.frameCategory = entry.frameCategory;
                        row.frameProfile = entry.frameProfile;
                        row.totalBoxesReceivedOnPos = entry.totalBoxes;
                        row.totalFeetReceivedOnPos = entry.totalFeet;
                        row.totalBoxesConsumed = consumed ? consumed.totalBoxes : 0;
                        row.totalFeetConsumed = consumed ? consumed.totalFeet : 0 ;

                        premiereRows.push( row );
                  
                  }
                 

                  for ( let entry of newPrismaticSkusReceived ) {

                        let consumedIdx = newPrismaticConsumption.findIndex( row => row.mouldingId === entry.mouldingId );
                        let consumed = null;
                        if ( consumedIdx > -1 ) {
                              consumed =   newPrismaticConsumption.splice( consumedIdx, 1)[0];
                        }

                        let row = {} as GridRow;
                        row.id = entry.mouldingId; // required by the grid to lookup rows
                        row.frameStyle = entry.frameStyle;
                        row.sku = entry.sku;
                        row.feetPerBox = entry.feetPerBox;
                        row.totalBoxes = entry.totalBoxes;
                        row.totalFeet = entry.totalFeet
                        row.frameCategory = entry.frameCategory;
                        row.frameProfile = entry.frameProfile;
                        row.totalBoxesReceivedOnPos = entry.totalBoxes;
                        row.totalFeetReceivedOnPos = entry.totalFeet;
                        row.totalBoxesConsumed = consumed ? consumed.totalBoxes : 0;
                        row.totalFeetConsumed = consumed ? consumed.totalFeet : 0 ;

                        prismaticRows.push( row );
           
                  }


                  for ( let entry of newScoopSkusReceived ) {

                        let consumedIdx = newScoopSkusConsumption.findIndex( row => row.mouldingId === entry.mouldingId );
                        let consumed = null;
                        if ( consumedIdx > -1 ) {
                              consumed =   newScoopSkusConsumption.splice( consumedIdx, 1)[0];
                        }

                        let row = {} as GridRow;
                        row.id = entry.mouldingId; // required by the grid to lookup rows
                        row.frameStyle = entry.frameStyle;
                        row.sku = entry.sku;
                        row.feetPerBox = entry.feetPerBox;
                        row.totalBoxes = entry.totalBoxes;
                        row.totalFeet = entry.totalFeet
                        row.frameCategory = entry.frameCategory;
                        row.frameProfile = entry.frameProfile;
                        row.totalBoxesReceivedOnPos = entry.totalBoxes;
                        row.totalFeetReceivedOnPos = entry.totalFeet;
                        row.totalBoxesConsumed = consumed ? consumed.totalBoxes : 0;
                        row.totalFeetConsumed = consumed ? consumed.totalFeet : 0 ;

                        scoopRows.push( row );

                      
                  }

                  for ( let entry of newSlimSkusReceived ) {

                        let consumedIdx = newSlimSkusConsumption.findIndex( row => row.mouldingId === entry.mouldingId );
                        let consumed = null;
                        if ( consumedIdx > -1 ) {
                              consumed =   newSlimSkusConsumption.splice( consumedIdx, 1)[0];
                        }

                        let row = {} as GridRow;
                        row.id = entry.mouldingId; // required by the grid to lookup rows
                        row.frameStyle = entry.frameStyle;
                        row.sku = entry.sku;
                        row.feetPerBox = entry.feetPerBox;
                        row.totalBoxes = entry.totalBoxes;
                        row.totalFeet = entry.totalFeet
                        row.frameCategory = entry.frameCategory;
                        row.frameProfile = entry.frameProfile;
                        row.totalBoxesReceivedOnPos = entry.totalBoxes;
                        row.totalFeetReceivedOnPos = entry.totalFeet;
                        row.totalBoxesConsumed = consumed ? consumed.totalBoxes : 0;
                        row.totalFeetConsumed = consumed ? consumed.totalFeet : 0 ;

                        slimRows.push( row );

                      
                  }
//////////////////////////////////////////////////////////////////////////////////////////////
                  // what is left in the consumed arrays is not neither in the main arrrry or the received array
                  for ( let entry of newPremiereSkusConsumption ) {


                              let row = {} as GridRow;
                              row.id = entry.mouldingId; // required by the grid to lookup rows
                              row.frameStyle = entry.frameStyle;
                              row.sku = entry.sku;
                              row.feetPerBox = entry.feetPerBox;
                              row.totalBoxes = entry.totalBoxes;
                              row.totalFeet = entry.totalFeet
                              row.frameCategory = entry.frameCategory;
                              row.frameProfile = entry.frameProfile;
                              row.totalBoxesReceivedOnPos = 0;
                              row.totalFeetReceivedOnPos =  0;
                              row.totalBoxesConsumed =  entry.totalBoxes;
                              row.totalFeetConsumed = entry.totalFeet;

                              premiereRows.push( row );
                             

                  }

                  for ( let entry of newPrismaticConsumption ) {

                              let row = {} as GridRow;
                              row.id = entry.mouldingId; // required by the grid to lookup rows
                              row.frameStyle = entry.frameStyle;
                              row.sku = entry.sku;
                              row.feetPerBox = entry.feetPerBox;
                              row.totalBoxes = entry.totalBoxes;
                              row.totalFeet = entry.totalFeet
                              row.frameCategory = entry.frameCategory;
                              row.frameProfile = entry.frameProfile;
                              row.totalBoxesReceivedOnPos =  0;
                              row.totalFeetReceivedOnPos = 0;
                              row.totalBoxesConsumed =  entry.totalBoxes;
                              row.totalFeetConsumed = entry.totalFeet;

                              prismaticRows.push( row );
                  }

                  for ( let entry of newScoopSkusConsumption ) {

                              let row = {} as GridRow;
                              row.id = entry.mouldingId; // required by the grid to lookup rows
                              row.frameStyle = entry.frameStyle;
                              row.sku = entry.sku;
                              row.feetPerBox = entry.feetPerBox;
                              row.totalBoxes = entry.totalBoxes;
                              row.totalFeet = entry.totalFeet
                              row.frameCategory = entry.frameCategory;
                              row.frameProfile = entry.frameProfile;
                              row.totalBoxesReceivedOnPos =  0;
                              row.totalFeetReceivedOnPos =  0;
                              row.totalBoxesConsumed =  entry.totalBoxes;
                              row.totalFeetConsumed = entry.totalFeet;

                              scoopRows.push( row );

                  }
                  for ( let entry of newSlimSkusConsumption ) {

                        let row = {} as GridRow;
                        row.id = entry.mouldingId; // required by the grid to lookup rows
                        row.frameStyle = entry.frameStyle;
                        row.sku = entry.sku;
                        row.feetPerBox = entry.feetPerBox;
                        row.totalBoxes = entry.totalBoxes;
                        row.totalFeet = entry.totalFeet
                        row.frameCategory = entry.frameCategory;
                        row.frameProfile = entry.frameProfile;
                        row.totalBoxesReceivedOnPos =  0;
                        row.totalFeetReceivedOnPos =  0;
                        row.totalBoxesConsumed =  entry.totalBoxes;
                        row.totalFeetConsumed = entry.totalFeet;

                        slimRows.push( row );
                  }

                  setRowData(premiereRows);
                  setRowDataAlloyPrismatic(prismaticRows);
                  setRowDataAlloyScoop(scoopRows);
                  setRowDataSlim( slimRows);
            }

            getData()

      }, [params, data]);
      
      const onGridReady = useCallback( (params: any, gridRef: RefObject<AgGridReact<GridRow>> ) => {
            gridRef.current?.api.sizeColumnsToFit({
                  defaultMinWidth: 100,
                  columnLimits: [{ key: 'frameStyle', minWidth: 400 }],
            });

      }, []);
      const onGridReadyPremiere = useCallback((params: any,) => {

            gridRefPremiere.current?.api.sizeColumnsToFit({
                        defaultMinWidth: 100,
                        columnLimits: [{ key: 'frameStyle', minWidth: 400 }],
                  });
      }, []);

      const onGridReadyAlloyScoop = useCallback((params: any) => {

            gridRefAlloyScoop.current?.api.sizeColumnsToFit({
                        defaultMinWidth: 100,
                        columnLimits: [{ key: 'frameStyle', minWidth: 400 }],
                  });
      }, []);

      const onGridReadyAlloyPrismatic = useCallback((params: any) => {

            gridRefAlloyPrismatic.current?.api.sizeColumnsToFit({
                        defaultMinWidth: 100,
                        columnLimits: [{ key: 'frameStyle', minWidth: 400 }],
                  });
      }, []);

      const onBtCopyRows2 = useCallback((gridRef: RefObject<AgGridReact<any>> ) => {

            gridRef!.current?.api.forEachNode(node => node.setSelected(true));
            gridRef!.current?.api.copySelectedRowsToClipboard();
            gridRef!.current?.api.forEachNode(node => node.setSelected(false));
          
      }, []);

      const onBtCopyRows = useCallback((sectionName: string ) => {
            let gridRef = null;
            if ( sectionName ==='premiere'){
                  gridRef = gridRefPremiere;
            } else if ( sectionName === "alloy-scoop") {
                  gridRef = gridRefAlloyScoop;

            } else if ( sectionName === 'alloy-prismatic') {
                  gridRef = gridRefAlloyPrismatic;
            }

            gridRef!.current?.api.forEachNode(node => node.setSelected(true));
            gridRef!.current?.api.copySelectedRowsToClipboard();
            gridRef!.current?.api.forEachNode(node => node.setSelected(false));
          
      }, []);

      const exportToExcel = ( sectionName: string) => {
            let gridToExport = null;
            if ( sectionName ==='premiere'){
                  gridToExport = gridRefPremiere;
            } else if ( sectionName === "alloy-scoop") {
                  gridToExport = gridRefAlloyScoop;

            } else if ( sectionName === 'alloy-prismatic') {
                  gridToExport = gridRefAlloyPrismatic;
            }
            // @ts-ignore 
            gridToExport.current?.api.exportDataAsExcel({
                  processRowGroupCallback: (params: any) => {
                        const { node } = params;
                        return `${node.key} (${node.allChildrenCount})`;
                  }
            });
         
      };
      const exportToExcel2 = ( gridRef:  RefObject<AgGridReact<any>>) => {

            // @ts-ignore 
            gridRef.current?.api.exportDataAsExcel({
                  processRowGroupCallback: (params: any) => {
                        const { node } = params;
                        return `${node.key} (${node.allChildrenCount})`;
                  }
            });
         
      };
      return (
            <div className='report'>
                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title"> Pallet Inventory Batch Summary</div> <div className="report-date-title"> 
                              { data && data.batchSummary  &&
                                     <>
                                     {data.batchSummary.inventoryLocationName} &#x2022; { data &&  data.batchSummary.startedDateLocal} &#x2022; Completed By: {data && data.batchSummary.startByUserName}  &#x2022; State: {data && data.batchSummary.batchState.toLowerCase()} 
                                    </>
                              }
                              </div>                              
                        </div>

                        <div className="ag-theme-alpine" > { /*, width: "95%" */}
                              <div className="alloy-header"> Premiere Moulding
                                    <Button label="Export to Excel" icon="pi pi-file-export" onClick={ () => exportToExcel2(gridRefPremiere)} />
                                    <Button label="Copy All to Clipboard" icon="pi pi-copy" onClick={() => onBtCopyRows2(gridRefPremiere)} />
                              </div>
                              <AgGridReact<GridRow>
                                    ref={gridRefPremiere}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    columnTypes={columnTypes}
                                    defaultColDef={defaultColDef}
                                    pagination={false}
                                    rowMultiSelectWithClick={true}
                                    enableRangeSelection={true}
                                    rowSelection={'multiple'}
                                    onGridReady={ (params) => onGridReady( params, gridRefPremiere)}
                                    groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    domLayout={'autoHeight'}
                              ></AgGridReact>
                        </div>
                              
                        <div className="ag-theme-alpine" > { /*, width: "95%" */}
                              <div className="alloy-header"> Slim Moulding
                                    <Button label="Export to Excel" icon="pi pi-file-export" onClick={ () => exportToExcel2(gridRefSlim )} /> 
                                    <Button label="Copy All to Clipboard" icon="pi pi-copy" onClick={() => onBtCopyRows2(gridRefSlim)} />
                              </div>
                              <AgGridReact<GridRow>
                                    ref={gridRefSlim}
                                    rowData={rowDataSlim}
                                    columnDefs={columnDefs}
                                    columnTypes={columnTypes}
                                    defaultColDef={defaultColDef}
                                    pagination={false}
                                    rowMultiSelectWithClick={true}
                                    enableRangeSelection={true}
                                    rowSelection={'multiple'}
                                    onGridReady={ (params) => onGridReady( params, gridRefSlim)}
                                    groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    domLayout={'autoHeight'}
                              ></AgGridReact>
                        </div>


                        <div className="ag-theme-alpine">
                              <div className="alloy-header"> Alloy Scoop Moulding
                              <div className="pl-3">
                                    <Button label="Export to Excel" icon="pi pi-file-export" onClick={() => exportToExcel2(gridRefAlloyScoop)} />
                                    <Button label="Copy All to Clipboard" icon="pi pi-copy" onClick={() => onBtCopyRows2(gridRefAlloyScoop)} />
                                    </div>
                              </div>
                              <AgGridReact<GridRowAlloy>
                                    ref={gridRefAlloyScoop}
                                    rowData={rowDataAlloyScoop}
                                    columnDefs={columnDefs}
                                    columnTypes={columnTypes}
                                    defaultColDef={defaultColDef}
                                    pagination={false}
                                    rowMultiSelectWithClick={true}
                                    enableRangeSelection={true}
                                    rowSelection={'multiple'}
                                    onGridReady={ (params) => onGridReady( params, gridRefAlloyScoop)}
                                    groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    domLayout={'autoHeight'}
                              ></AgGridReact>
                        </div>                         

                        <div className="ag-theme-alpine">
                              <div className="alloy-header"> Alloy Prismatic Moulding
                              
                              <Button label="Export to Excel" icon="pi pi-file-export" onClick={() => exportToExcel2(gridRefAlloyPrismatic)} />
                              <Button label="Copy All to Clipboard" icon="pi pi-copy" onClick={() => onBtCopyRows2(gridRefAlloyPrismatic)} />
                              
                              </div>
                              <AgGridReact<GridRowAlloy>
                                    ref={gridRefAlloyPrismatic}
                                    rowData={rowDataAlloyPrismatic}
                                    columnDefs={columnDefs}
                                    columnTypes={columnTypes}
                                    defaultColDef={defaultColDef}
                                    pagination={false}
                                    rowMultiSelectWithClick={true}
                                    enableRangeSelection={true}
                                    rowSelection={'multiple'}
                                    onGridReady={ (params) => onGridReady( params, gridRefAlloyPrismatic)}
                                    groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    domLayout={'autoHeight'}
                              ></AgGridReact>
                        </div>   

                        { error && <p>An error occurred loading the report <span>{error}</span></p> }
                        { batchNotEnded && <p style={{ color: 'navy' }} > There is no data to display because this batch was {batchNotEnded}</p> }
                        { loading && <p> <ProgressSpinner /> Loading... </p> }

                  </Card>
            </div>
      )
}

export default PalletInventorySummaryReport;