import { useEffect, useState } from "react";
import { CuttingBatchSummaryRow, EntryTypeEnum, IFrameEntry, StationEntry } from "../../../models/daily-activity.models";
import { StationsEnum } from "../../../models/stations-enum";
import { DateUtils } from "../../../utils/date-utils";
import { DurationUtils } from "../../../utils/duration-utils";
import StationTimerStartStopPanel from "./station-timer-start-stop-panel";
import CuttingBatchActions from "./cutting-batch-items";
import { Panel } from "primereact/panel";
import Config from "../../../config";
import FrameTile from "../frame-event-tiles/frame-tile";
import { FrameProduct } from "../../../models/frame-product";

interface IStationPanelPanel  {
      userName: string;
      stationId: string;
      stationEntry: StationEntry;
      showEventIds: boolean;
}

const CuttingBatchHeader= (props: IStationPanelPanel) => {

      function convertoFrameProduct( props: any){
     
            let product = {
                  size: (props.stationEntry.actions[0] as CuttingBatchSummaryRow).modelSize,
                  image:(props.stationEntry.actions[0] as CuttingBatchSummaryRow).image ?? "NOT_FOUND",
                  barCode: "",
                  modelName: (props.stationEntry.actions[0] as CuttingBatchSummaryRow).modelName,
                  modelYear: (props.stationEntry.actions[0] as CuttingBatchSummaryRow).modelYear,
            }
            return new FrameProduct( product);
      }

      return (
       
            <div className="flex flex-column  align-items-start   w-full">
                  <div> { props.stationEntry.stationId === StationsEnum.alloyCutting.toString() ? "Alloy Cutting Batch - " : "Premiere Cutting Batch" }       </div>
                  { props.stationEntry.actions.length > 0 && 
                  <div className="flex flex-row align-items-center w-full">
                        <FrameTile eventHistoryId={""} frameSerialId={""} frame={convertoFrameProduct(props)} />
                        <div> Total Cut:  {props.stationEntry.actions && props.stationEntry.actions.length > 0 ? 
                                    props.stationEntry.actions.reduce ( function( result, item ) {
                                    return result +  ( isNaN( (item as CuttingBatchSummaryRow).quantity ) ? 0 :  (item as CuttingBatchSummaryRow).quantity );
                              }, 0) : "" }  </div>      
                  </div>
                  }
            </div>
         
      );
}

export default CuttingBatchHeader;