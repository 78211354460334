import { useEffect, useState } from "react";
import Config from "../../config";
import { FrameCompletion, IFrameEntry, IFrameOtherProperties } from "../../models/daily-activity.models";
import { FrameProduct } from "../../models/frame-product";
import IProduct from "../../models/i-product";

interface IFrameData {

      frames: IProduct[];
}
interface CellMap {
      size: string;
      year: string;
      quantity?: string;
      productId?: string;
}

const GridTotalsRow = (props: IFrameData) => {
      //32 is all years but mapped to one year, same as 85"
      
      const [frames, setFrames] = useState<CellMap[]>([]); 
      const frameSizes: CellMap[] = [    { size: "32", year:"2021"},

                                          { size: "43", year:"2020"},
                                          { size: "50", year:"2020"},
                                          { size: "55", year:"2020"},
                                          { size: "65", year:"2020"},
                                          { size: "75", year:"2020"},

                                          { size: "43", year:"2021"},
                                          { size: "50", year:"2021"},
                                          { size: "55", year:"2021"},
                                          { size: "65", year:"2021"},
                                          { size: "75", year:"2021"},

                                          { size: "85", year:"2021"},
                                          { size: "Total", year:"2021"} ];


      return (

            <>
                   { frameSizes.map((cell: CellMap) => {
                       
                        return      (
                              <>
                             { cell.size === "Total" ? 
                              <div key={cell.size} className={`frame-grid-cell ${cell.size === "Total" ? 'total' : cell.quantity === "" ?  'empty' : ''}`}>
                                    {props.frames.map( frame=> parseInt( (frame as any).framesCompleted ) ).reduce( (p: number, c:number) =>{ return p + c}, 0)}
                              </div>
                              :
                              <div key={cell.size} className={`frame-grid-cell ${cell.size === "Total" ? 'total' : cell.quantity === "" ?  'empty' : ''}`}>
                                    {props.frames.filter( f => f.modelSize === cell.size && f.modelYear === cell.year).map( frame=> parseInt( (frame as any).framesCompleted ) ).reduce( (p: number, c:number) =>{ return p + c}, 0)}

                              </div>
                             }
                             </>
                        )        
                     
                  })}
                   </>          

      );
}

export default GridTotalsRow;