import { EntryType } from "perf_hooks";
import { FrameProduct } from "./frame-product";
import { ITimedEntry } from "./time-entry";
import { Duration } from "./duration";

export enum EntryTypeEnum {
      STATION = "station",
      FRAME_COMPLETED = "frameCompleted",
      FRAME_REJECTED = 'frameRejected',
      FRAME_VOIDED = 'frameVoided',
      FRAME_DISCARDED = 'frameDiscarded',
      STATION_TASK = "stationTask",
      CUTTING_BATCH_SUMMARY = "cuttingBatchSummary",
      CUTTING_RECUT = "cuttingRecut"
}

export interface UserActivity {
      userId: string;
      userName: string;
      stations: Station[];
}

export interface Station {
      stationId: string;
      stationEntries: StationEntry[];
}

interface IStationLabourTotals {
      stationId: number;
      stationName: string;
      timeAtStation: any;
      timeOnFrames: any;
}

export interface StationEntry extends ITimedEntry {
      entryType:  EntryTypeEnum.STATION
      taskName?: string;
      stationId: string;
      hours: IStationLabourTotals;
      actions: ( StationTask |  FrameCompletion | FrameRejected | FrameVoided |  FrameDiscarded | CuttingBatchSummaryRow | CuttingRecut) [] 
}

export interface IFrameOtherProperties {
      isReturn?: boolean;
      returnDate?: string;
      isRecut?: boolean;
      isVoided?: boolean;  // this event should be true IFF the frame is voided in its current state and DOES NOT represent the event that voids the frame
}

export interface IFrameEntry {
      eventHistoryId: string;
      frameSerialId: string;
      frame: FrameProduct
}

export interface IStationAction {
      entryType: string;
      userName: string;  // who did the action to the frame
}

export interface FrameCompletion extends IFrameEntry, ITimedEntry, IStationAction, IFrameOtherProperties {
      entryType:  EntryTypeEnum.FRAME_COMPLETED
      frameSerialId: string;
      frame: FrameProduct
}

export interface FrameRejected extends IFrameRejected, IFrameEntry, ITimedEntry, IStationAction, IFrameOtherProperties {
      entryType: EntryTypeEnum.FRAME_REJECTED;

}

export interface IFrameRejected {
      rejectedToStationId: string;
      rejectionReason: string;
      comment: string;     
}

export interface FrameVoided extends ITimedEntry, IFrameEntry, IFrameOtherProperties {
      entryType: EntryTypeEnum.FRAME_VOIDED;
}

export interface FrameDiscarded extends ITimedEntry, IFrameEntry, IFrameOtherProperties {
      entryType: EntryTypeEnum.FRAME_DISCARDED;

}

export interface StationTask extends IStationTask {
      entryType: EntryTypeEnum.STATION_TASK;
      userName: string;
      taskName: string;
      isDeleted?: boolean;
      deletedTime?: string;
 }

 export interface IStationTask extends IStationTaskTimedEntry {
      eventHistoryId: string;
      userName: string;
      taskName: string;
      isDeleted?: boolean;
      deletedTime?: string;
 }

 export interface IStationTaskTimedEntry {
      time: string;
      duration: Duration; 
}

export interface IUserStatsPerStation {
      totalFrames: number;
      totalRejections: number;
      totalVoided: number;
      totalDiscarded: number;
      totalTimeOnFrame: number;
      totalTimeAtStation: number;
      avgTimePerFrame: number;
      avgTimePerFrameWithStation:number;

}

export interface CuttingBatchSummaryRow  {
      entryType: EntryTypeEnum.CUTTING_BATCH_SUMMARY;
      batchId: string, 
      image?: string, 
      modelYear: string;
      modelSize: string;
      modelName: string;
      quantity: number;
 }

 export interface CuttingRecut {
      entryType: EntryTypeEnum.CUTTING_RECUT;
      batchId: string;
      discarded: boolean
      recutOriginalFrameSerialid: string
      recutOriginalFrame?: FrameProduct;
      recutNewSize?: string;
      recutNewFrameSerialId?: string;  
      recutNewFrame?: FrameProduct;    
      recutCuttingSawId?: string;
}