import Config from "../../config";
import { FrameCompletion, IFrameEntry, IFrameOtherProperties } from "../../models/daily-activity.models";
import { FrameProduct } from "../../models/frame-product";
import IProduct from "../../models/i-product";


interface CellMap {
      size: string;
      year: string;
      color?: string;
}
const GridHeaderRow = () => {
      //32 is all years but mapped to one year, same as 85"

      const frameSizes: CellMap[] = [    { size: "32", year:"2021", color: "#f58232"},

                                          { size: "43", year:"2020", color: "#f659aa"},
                                          { size: "50", year:"2020", color: "#f5e54f"},
                                          { size: "55", year:"2020", color: "#7f50a8"},
                                          { size: "65", year:"2020", color: "#318fcf"},
                                          { size: "75", year:"2020", color: "#606a6d"},

                                          { size: "43", year:"2021", color: "#f659aa"},
                                          { size: "50", year:"2021", color: "#f5e54f"},
                                          { size: "55", year:"2021", color: "#7f50a8"},
                                          { size: "65", year:"2021", color: "#318fcf"},
                                          { size: "75", year:"2021", color: "#606a6d"},

                                          { size: "85", year:"2021", color: "#1b613a"},
                                    
                                          { size: "Total", year:"2021", color: "gray"}];

      function getYearColor( cell: CellMap ) {
            if ( cell.size === "32" || cell.size === "85"  || cell.size === "Total"  ){
                  return "#FFFFF"
            } else if ( cell.year === "2020" ) {
                  return "red";
            } else {
                  return "green";
            }
      }
     
      return (
            <>
            <div className={`flex flex-row`} style={{borderTop: "1px solid gray"}}>
                  <div className="frame-cell-framestyle year"  style={{borderTop: "none", borderBottom: "none"}} >

                  </div>

                  { frameSizes.map((cell: CellMap) => {
                        
                        return ( 
                              <div className="frame-grid-cell year" style={{borderTop: "none", borderBottom: "none"}}>

                                    { cell.year === "2020" && cell.size==="65" && 
                                          <>2020</>
                                    }
                                    { cell.year === "2021" && cell.size==="65" && 
                                          <>2021</>
                                    }
                                    
                              </div>
                        )        
                        
                  })}
               
            </div>

             <div className={`flex flex-row`}>
                  <div className="frame-cell-framestyle year" style={{borderTop: "none", borderBottom: "none"}}   >

                  </div>
                  
                   { frameSizes.map((cell: CellMap) => {
                       
                        return ( 
                              <div className="frame-grid-cell year" style={{backgroundColor: `${ getYearColor( cell) }` }}>
                             
                              </div>
                        )        
                     
                  })}
               
            </div>

            <div className={`flex flex-row`}>
                  <div className="frame-cell-framestyle" style={{borderTop:"1px solid gray", backgroundColor: "lightgray"}} >
                        Frame Style
                  </div>

                   { frameSizes.map((cell: CellMap) => {
                       
                        return ( 
                              <div className="frame-grid-cell size" style={{backgroundColor: `${cell.color!}` }}>
                                    {cell.size}
                              </div>
                        )        
                     
                  })}
               
            </div>
            </>
      );
}

export default GridHeaderRow;