import { ProgressSpinner } from 'primereact/progressspinner';
import { Panel } from 'primereact/panel';
import { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';

import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { User } from "../../models/user";
import { Button } from 'primereact/button';

/*
interface IDailyActivityReportProps {
      userId: string;
      date: string;
}
*/

interface IUserPicker {
      onChangeDate: ( change: number ) => void;
}

//(props: IDailyActivityReportProps) => {
const NextPreviousDayButtons = ( props: IUserPicker) => { 

      return (
            <div className='next-previous-day-buttons flex mr-2 ml-2'>
                  <Button className="ml-2 mr-2" label="" icon="pi pi-angle-double-left" size="small" onClick={(e) => props.onChangeDate(-1)} />
                  <Button className="mr-2" label="" icon="pi pi-angle-double-right" size="small" onClick={(e) => props.onChangeDate(1)} />   
            </div>
      )

}

export default NextPreviousDayButtons;