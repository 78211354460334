import Config from "../../../config";
import { FrameRejected, IFrameEntry, IFrameRejected } from "../../../models/daily-activity.models";
import { FrameProduct } from "../../../models/frame-product";

interface IFrameRejectedTile extends IFrameRejected, IFrameEntry {
      isReturned?: boolean;
      returnDate?: string;
      isRecut?: boolean;
}

const FrameRejectedTile = (props: IFrameRejectedTile) => {
      return (

            <div className={`frame`}>
                  <div className={`frame-year-border year${props.frame.modelYear} `}>
                  </div>
                  <div className="frame-image">
                        <div className={ `frame-style-color  size-${props.frame.size}` }>
                              <img src={Config.baseUrl + '/img/moulding-label/img/' + props.frame.image.replace( '_SUSTAINABLE', '') + '.jpg' } alt="frame" />
                        </div>
                  </div>

                  <div className="frame-details">
                        <div className='top-row'>
                              <div className='serial'>
                                    {props.frameSerialId}
                              </div>
                
                        </div>
                        <div className='frame-style'>
                              {props.frame.modelName} {props.frame.size} - {props.frame.modelYear}
                        </div>

                        {props.rejectionReason && 
                              <div className='other-properties'>
                                    <div>{props.rejectionReason}</div>
                                    <div>{props.comment}</div>
                                    <div>{props.returnDate && props.returnDate}</div>
                                    <div>{ props.isRecut  && props.isRecut === true ? "RCT" : ""}</div>
                              </div>
                        }
                  </div>
            </div>
      );


}

export default FrameRejectedTile;