import { EntryTypeEnum } from "../../../models/daily-activity.models";
import { ITimedEntry } from "../../../models/time-entry"
import { DateUtils } from "../../../utils/date-utils";
import { DurationUtils } from "../../../utils/duration-utils"


interface TimeStartStopDurationProps extends ITimedEntry {
      showEventIds: boolean
      entryType: string;
      eventHistoryId: string;
}

const TimeStartStopDuration = (props: TimeStartStopDurationProps) => {

      return (
       
            <div className="time-entries">

                  { props.entryType === EntryTypeEnum.STATION_TASK  &&
                        <>
                        { props.showEventIds &&  <div className="entry"> <div className="title-gray"> Task:</div> {props.startEventId} - {props.endEventId}</div> }
                        <div className="entry"> <div className="title-gray"> Completed Time:</div> {  props.startTime ? props.startTime :  "" } </div>
                        <div className="entry"> <div className="title-gray">Duration:</div> { DurationUtils.formatDuration( props.duration )}</div>
                        </>

                  }
                  { props.entryType !== EntryTypeEnum.STATION_TASK && <>
                        { props.showEventIds &&  <div className="entry"> <div className="title-gray"> Events:</div> {props.startEventId} - {props.endEventId}</div> }
                        <div className="entry"> <div className="title-gray"> Time:</div> {  props.startTime ? DateUtils.format24To12( props.startTime) : "" } - {props.endTime ? DateUtils.format24To12(props.endTime) : ""}</div>
                        <div className="entry"> <div className="title-gray">Duration:</div> { DurationUtils.formatDuration( props.duration )}</div>
                        </>
                        
                  } 
            </div>
         
      )

}

export default TimeStartStopDuration;

