import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import useFetch from "../../../hooks/use-fetch";
import Config from "../../../config";
import { Calendar } from "primereact/calendar";
import NextPreviousDayButtons from "../../components/next-previous-day-buttons";
import { useEffect, useState } from "react";

interface BoxInventoryEntries {
      boxSize: string;
      boxType: string;
      total: string;
}
interface IBoxingPrepActivityReport {
      boxes: BoxInventoryEntries[]
}
const BoxingPrepActivityReport = () => {

      const [date, setDate] = useState<Date | null>(null);
      const url = date && `${Config.baseUrl}/api/admin/reports/boxing-prep-activity/?date=${date.toJSON().slice(0,10).split('-').join('-')}`;
      const { data, loading, error } = useFetch<IBoxingPrepActivityReport>(url ?? "", [], {});

      function onChangeDate( days: number) {
            if ( date ) {
                  let x = new Date( date.setDate(date.getDate() + days) );
                  setDate( x );
            }
      }

      useEffect(() => {
            setDate(new Date());

            // if a ULRL params are included, assume the report is for posting to slack so hide the menu and date selector to keep the posting minimal
            if ( window.location.search ){
                  const urlParams = new URLSearchParams( window.location.search);
                  let date = urlParams.get('date')
                  if ( date ) {
                        setDate( new Date( date ))
                  }
            }

      }, []);

      return (
            <div className='report'>
                   
                  <div className="calendar-selector">
                        <div className='flex flex-row pl-5'>
                              <div className="text-2xl text-white pr-5 align-self-center">Select Date: </div>
                        
                              <Calendar value={date} onChange={(e) => {  e.value && setDate( (e.value as Date) ) } } dateFormat="mm-dd-yy" showIcon showButtonBar touchUI />
                        </div>

                        { date && <NextPreviousDayButtons onChangeDate={onChangeDate} />}
                  </div>                              

                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title"> Boxing Prep - Boxes Made </div> <div className="report-date-title">{new Date().toDateString()}</div>
                        </div>
      
                        <div className="m-0 flex flex-direction-column align-items-start">

                              {error && <div>An error occurred loading the report.</div>}

                              {loading && <div> <ProgressSpinner /> Loading...</div>}
                              <div style={{display:"inline-block"}}> 
                                    {data && data.boxes && data.boxes.length > 0 ?

                                          <DataTable value={data.boxes} stripedRows tableStyle={{ maxWidth: '500px' }}>
                                                <Column field="boxType" header="Box Type" style={{ width: '10rem' }} ></Column>
                                                <Column field="boxSize" header="Size" style={{ width: '10rem' }} ></Column>
                                                <Column field="total" header={ <div style={{ textAlign: "end" }}>Quantity</div>} style={{ width: '10rem', textAlign: "end" }}></Column>
                                          </DataTable>
                                          
                                          :

                                          "No boxes made on the selected date."
                                    }
                                    { data && data.boxes && data.boxes.length > 0 && 
                                          <div className="flex flex-row p5 justify-content-end">
                                                <div className="font-bold" > Total Boxes </div> 
                                                <div className="flex justify-content-end pt-15 pl-3" style={{paddingRight:"15px"}}  >
                                                { data.boxes.map( e=> parseInt( e.total ) ).reduce((a: number, b: number) => { return a + b; }, 0) }
                                                </div>
                                          </div>
                                    }

                              </div>
                        </div>

                  </Card>
            </div>
      )
}

export default BoxingPrepActivityReport;