import { Duration } from "../models/duration";

export class DurationUtils {
      
      static formatDuration( duration: Duration) {
            return`${ duration && duration.hours ? duration.hours + 'h ' : ''} ${ duration && duration.minutes ? duration.minutes + 'm ' : '0m ' } ${ duration &&duration.seconds ? duration.seconds + 's ' : '0s '}`;
      }

      static toSeconds( duration: Duration ) {
            let total = 0;
      
            if (  duration && duration.hours !== undefined ) {
                  total = duration.hours * 3600;
            }
            if (  duration && duration.minutes ) {
                  total += duration.minutes * 60;
            }
            if (  duration && duration.seconds ) {
                  total += duration.seconds;
            }
            return total;
      }

}