import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from './error-page';

import './App.css';

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

//import { ReactECharts, ReactEChartsProps } from './echarts/react-echarts';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import App from './App';
import SessionContextProvider from './contexts/session-context';

    
   /* ReactDOM.createRoot(document.getElementById("root")  as HTMLElement ).render(
      <React.StrictMode>
             <RouterProvider  router={router} />
      </React.StrictMode>
    );
    */


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>

<SessionContextProvider>
                  
            <App />
            </SessionContextProvider>

  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
