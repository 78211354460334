import { createContext, useContext, useState } from "react";
import { initialSession, Session } from "../models/session"

export const SessionContext = createContext<[Session, (session: Session) => void]>([initialSession, () => {}]);
export const useSessionContext = () => useContext(SessionContext);

interface ISessionContextProviderProps {
      children: React.ReactNode;
}

const SessionContextProvider = (props: ISessionContextProviderProps) => {
  const [sessionState, setSessionState] = useState(initialSession);
  const defaultSessionContext: [Session, typeof setSessionState]  = [sessionState, setSessionState];

  return (
    <SessionContext.Provider value={defaultSessionContext}>
      {props.children}
    </SessionContext.Provider>
  );
}

export default SessionContextProvider;