import { ProgressSpinner } from 'primereact/progressspinner';
import { Panel } from 'primereact/panel';
import { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';

import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { User } from "../../models/user";

/*
interface IDailyActivityReportProps {
      userId: string;
      date: string;
}
*/

interface IUserPicker {
      users: User[];   
      onUserChange: ( user: User ) => void;
}

//(props: IDailyActivityReportProps) => {
const UserPicker = ( props: IUserPicker) => { 
    
      const [user, setUser] = useState<User | null>(null);

      
      return (
            <div className='user-picker card flex justify-content-center mr-2 ml-2'>

                  <Dropdown editable showClear value={user} onChange={(e: DropdownChangeEvent)  => { props.onUserChange( e.value ); setUser(e.value); }} options={props.users} optionLabel="userName" placeholder="Users" className="w-full md:w-14rem" />
   
            </div>
      )

}

export default UserPicker;