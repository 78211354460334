import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';
import  { ProtectedRouteProps } from "./protected-route-props"

const ProtectedRoute = ({isAuthenticated, authenticationPath, redirectPath, setRedirectPath, outlet}: ProtectedRouteProps) => {
      const currentLocation = useLocation();

      useEffect(() => {
          
        if (!isAuthenticated) {
          setRedirectPath(currentLocation.pathname);
        }
    
      }, [isAuthenticated, setRedirectPath, currentLocation]);
    
    
      if(isAuthenticated && redirectPath === currentLocation.pathname) {
        return outlet;
      } else {
        return <Navigate to={{ pathname: isAuthenticated ? redirectPath : authenticationPath }} />;
      }
}

export default ProtectedRoute;

/*
const ProtectedRoute = (props: ProtectedRouteProps) => {
      const currentLocation = useLocation();

      useEffect(() => {
          
        if (!props.isAuthenticated) {
            props.setRedirectPath(currentLocation.pathname);
        }
    
      }, [props.isAuthenticated, props.setRedirectPath, currentLocation]);
    
    
      if ( props.isAuthenticated && props.redirectPath === currentLocation.pathname) {
        return props.outlet;
      } else {
        return <Navigate to={{ pathname: props.isAuthenticated ? props.redirectPath : props.authenticationPath }} />;
      }
}

export default ProtectedRoute;
*/