

import { NavLink, redirect, useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';
import { Menu } from "primereact/menu";
import { MenuItem, MenuItemCommandEvent } from "primereact/menuitem";


interface ISideBarMenuProps {
      visible: boolean;
      displaySideBarMenu: (show: boolean) => void
      autoCloseOnMenuClick: boolean;
}

const SideMenuBar = (props: ISideBarMenuProps) => {
      const navigate = useNavigate();
      const hideSideBarOnSmallScreen = () =>  {
            if (props.autoCloseOnMenuClick) {
                  props.displaySideBarMenu( false);
            }
      }
      function handleClick( url: string) {
            navigate( url);
      }
      /*
      Production
      Inventory
      Other
      ???
      */
      const menuItems: MenuItem[] =   [
            {
              label: 'Production',
              icon: 'pi pi-fw pi-user',
              items: [
                        {
                              label: 'Cut List History',
                              icon: 'pi pi-user-plus',
                              command: (event: MenuItemCommandEvent) => {
                                    navigate("/cut-list-history");
                              },
                        },
                        {
                              label: 'FIPs by Style, Size & Year',
                              icon: 'pi pi-fw pi-user-minus',
                              command: (event: MenuItemCommandEvent) => {
                                    navigate("/fip-by-style-size-year");
                              },
                        },             
                        {
                              label: 'FBA Picklist',
                              icon: 'pi pi-fw pi-list',
                              command: (event: MenuItemCommandEvent) => {
                                    navigate("/fba-picklist");
                              },
                        },
       
                        {
                              label: 'Frame Parts',
                              icon: 'pi pi-fw pi-user-minus',
                              command: (event: MenuItemCommandEvent) => {
                                    navigate("/deco-net-mapping");
                              },
                        },
                        {
                              label: 'Frames Boxed',
                              icon: 'pi pi-fw pi-box',
                              command: (event: MenuItemCommandEvent) => {
                                    navigate("/frames-boxed-report");
                              },
                        },
                        {
                              label: 'Waste Report',
                              icon: 'pi pi-fw pi-user-minus',
                              command: (event: MenuItemCommandEvent) => {
                                    navigate("/waste-report");
                              },
                        },
                        {
                              label: 'End Of Day',
                              icon: 'pi pi-fw pi-user-minus',
                              command: (event: MenuItemCommandEvent) => {
                                    navigate("/end-of-day");
                              },
                        },                 
                        {
                              label: 'Frame Lookup',
                              icon: 'pi pi-fw pi-user-minus',
                              command: (event: MenuItemCommandEvent) => {
                                    navigate("/frame-history");
                              },
                        },

                  ]
            },
            {
                  label: 'Inventory',
                  icon: 'pi pi-fw pi-user',
                  items: [
      
       
                            {
                                  label: 'Pallet Inventory',
                                  icon: 'pi pi-fw pi-palette',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/pallet-inventory");
                                  },
                            },
                            {
                                  label: 'Frame Inventory',
                                  icon: 'pi pi-fw pi-images',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/frames-inventory-report");
                                  },
                            },
                            {
                                    label: 'Boxing Prep Inventory',
                                    icon: 'pi pi-fw pi-inbox',
                                    command: (event: MenuItemCommandEvent) => {
                                          navigate("/boxing-prep-current-inventory");
                                    },
                              },
                             {
                                    label: 'Pallet Inventory List',
                                    icon: 'pi pi-fw pi-inbox',
                                    command: (event: MenuItemCommandEvent) => {
                                          navigate("/pallet-inventory-list-report");
                                    },
                             },
                        
                      ]
                },
                {
                  label: 'Productivity',
                  icon: 'pi pi-fw pi-user',
                  items: [
                            {
                                  label: 'Daily Activity',
                                  icon: 'pi pi-fw pi-calendar',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/daily-activity");
                                  },
                            },
                            {
                                  label: 'End Of Day',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/end-of-day");
                                  },
                            },

                            {
                                  label: 'Frame Lookup',
                                  icon: 'pi pi-fw pi-barcode',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/frame-history");
                                  },
                            },
       
                            {
                                  label: 'Station Productivty Report',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/station-productivity-report");
                                  },
                            },
                            {
                                  label: 'Frames Boxed',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/frames-boxed-report");
                                  },
                            },
                            {
                                  label: 'User Performance',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/user-productivity-report");
                                  },
                            },
                        
                      ]
                },
                /*
                {
                  label: 'Production',
                  icon: 'pi pi-fw pi-user',
                  items: [
                            {
                                  label: 'Cut List History',
                                  icon: 'pi pi-fw pi-cog',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/cut-list-history");
                                  },
                            },
                            {
                                  label: 'Daily Activity',
                                  icon: 'pi pi-fw pi-user-plus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/daily-activity");
                                  },
                            },
                            {
                                  label: 'FIPs by Style, Size & Year',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/fip-by-style-size-year");
                                  },
                            },
                            {
                                  label: 'End Of Day',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/end-of-day");
                                  },
                            },
                            {
                                  label: 'Boxing Prep Current Inventory',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/boxing-prep-current-inventory");
                                  },
                            },
                            {
                                  label: 'Pallet Inventory',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/pallet-inventory");
                                  },
                            },
                            {
                                  label: 'Waste Report',
                                  icon: 'pi pi-fw pi-trash',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/waste-report");
                                  },
                            },
                            {
                                  label: 'Pallet Inventory',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/pallet-inventory");
                                  },
                            },
                            {
                                  label: 'FBA Picklist',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/fba-picklist");
                                  },
                            },
                            {
                                  label: 'Frame Lookup',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/frame-history");
                                  },
                            },
                            {
                                  label: 'Frame Parts',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/deco-net-mapping");
                                  },
                            },
                            {
                                  label: 'Station Productivty Report',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/station-productivity-report");
                                  },
                            },
                            {
                                  label: 'Frames Boxed',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/frames-boxed-report");
                                  },
                            },
                            {
                                  label: 'Frame Inventory',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/frames-inventory-report");
                                  },
                            },
                            {
                                  label: 'Frames Boxed',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/frames-boxed-report");
                                  },
                            },
                            {
                                  label: 'User Performance',
                                  icon: 'pi pi-fw pi-user-minus',
                                  command: (event: MenuItemCommandEvent) => {
                                        navigate("/user-productivity-report");
                                  },
                            },
                        
                      ]
                },
                */

      ];


      return (
            <Menu  className="mr-2" model={menuItems} />
      )
        /*
      return (
            <>
                  {props.visible === true &&
                        <div className="card flex justify-content-center w-full h-screen">
                              <nav className=" w-full h-screen" onClick={ hideSideBarOnSmallScreen }>

                                    <ul className=" w-full">
                                    <li className=" w-full flex justify-content-end pr-3 ">
                                          <Button icon="pi pi-bars" onClick={() => props.displaySideBarMenu( false )} />
                                    </li>
                                          <li>
                                          <NavLink 

                                                      to="/cut-list-history"
                                                      className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                      }
                                                >
                                                      Cut List History
                                                </NavLink>;
                                           </li>
                                           <li>
                                                <NavLink
                                                      to="/daily-activity"
                                                      className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                      }
                                                >
                                                      Daily Activity
                                                </NavLink>;
                                          </li>
                                          <li>
                                                <NavLink
                                                      to="/fip-by-style-size-year"
                                                      className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                      }
                                                >
                                                      FIPs by Style, Size & Year
                                                </NavLink>;
                                          </li>
                                          <li>
                                                <NavLink
                                                      to="/end-of-day"
                                                      className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                      }
                                                >
                                                      End Of Day
                                                </NavLink>;
                                          </li>                                          
                                          <li>
                                                      <NavLink
                                                            to="/boxing-prep-current-inventory"
                                                            className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                            }
                                                            >
                                                            Boxing Prep Current Inventory
                                                      </NavLink>;
                                          </li>
                                          <li>
                                                <NavLink
                                                      to="/pallet-inventory"
                                                      className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                      }
                                                >
                                                      Pallet Inventory
                                                </NavLink>;
                                          </li>
                                          <li>
                                                <NavLink
                                                      to="/waste-report"
                                                      className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                      }
                                                >
                                                      Waste Report
                                                </NavLink>;
                                          </li>
                                          <li>
                                                <NavLink
                                                      to="/fba-picklist"
                                                      className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                      }
                                                >
                                                      FBA Picklist
                                                </NavLink>;
                                          </li>
                                          <li>
                                                <NavLink
                                                      to="/frame-history"
                                                      className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                      }
                                                >
                                                      Frame Lookup
                                                </NavLink>;
                                          </li>
                                          <li>
                                                <NavLink
                                                      to="/deco-net-mapping"
                                                      className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                      }
                                                >
                                                      Premiere Frame Parts
                                                </NavLink>;
                                          </li>
                                          <li>
                                                <NavLink
                                                      to="/station-productivity-report"
                                                      className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                      }
                                                >
                                                      Station Productivty Report
                                                </NavLink>;
                                          </li>
                                          <li>
                                                <NavLink
                                                      to="/frames-boxed-report"
                                                      className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                      }
                                                >
                                                      Frames Boxed
                                                </NavLink>;
                                          </li>
                                          <li>
                                                <NavLink
                                                      to="/frames-inventory-report"
                                                      className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                      }
                                                >
                                                      Frame Inventory
                                                </NavLink>;
                                          </li>
                                          <li>
                                                <NavLink
                                                      to="/user-productivity-report"
                                                      className={({ isActive, isPending }) =>
                                                            isPending ? "pending" : isActive ? "active" : ""
                                                      }
                                                >
                                                      User Performance
                                                </NavLink>;
                                          </li>
                                    </ul>
                              </nav>

                        </div>

                  }
            </>
      )
            */
};

export default SideMenuBar;