import { StationsEnum } from "../../../models/stations-enum";
import { ITimedEntry } from "../../../models/time-entry"
import { DateUtils } from "../../../utils/date-utils";
import { DurationUtils } from "../../../utils/duration-utils"


interface IStationTimeLineHeaderProps extends ITimedEntry {
   //    entryType: string; this is always station
      taskName?: string;
      showEventIds: boolean;
      stationId: string;
      userName: string;
      actions: any [] ;
}

const StationTimeLineHeader = (props: IStationTimeLineHeaderProps) => {

      function getUniqueFrameCount(): number {
            return new Set( props.actions.map( e=> e.frameSerialId ) ).size;
      }

      function tasksCompleted(): number {

            return props.actions.reduce(function (result, item) {
                  return result +  (item.isDeleted === true ? 0: 1 );
                }, 0);

      }

      function getAverage() {
            if ( props.actions && props.actions.length > 0 )  {
                  let totalDuration =  props.actions.map( e=> DurationUtils.toSeconds( e.duration ) ).reduce(function (result, item) {
                        return result + item;
                      }, 0);
                 return DateUtils.toHHMMSS_string( totalDuration / getUniqueFrameCount() );
            }
      }
      function frameOrTask(){
            if ( props.taskName && props.taskName !== "" ) {
                  return "task";
            } else {
                  return "frame";
            }
      }
      return (
            <div className="station-header" >
                  <div className="flex align-content-end">
                        { props.showEventIds &&  <div className="entry"> {props.startEventId} {props.endEventId}</div> }
                        <div className="flex align-content-start flex-column">
                              <div className="entry text-xl p-1 text-primary"> { StationsEnum.toDisplayName(props.stationId) } {props.taskName && props.taskName !== "" && <div> &nbsp; - Task</div>} </div>
                              {props.taskName && props.taskName !== "" && <div className="entry text-x1 p-1 text-seondary">{props.taskName} </div>} 
                        </div>

                        <div className="user-name  text-xl p-1">{props.userName} </div>                      
                        <div className=" text-xl p-1">{  DateUtils.format24To12( props.startTime )} - {DateUtils.format24To12( props.endTime ) } </div>
                  </div>

                  <div className="entry m-1"> <div className="title-gray">Duration:</div> { DurationUtils.formatDuration( props.duration )}</div>

                  { props.actions && props.actions.length > 0 && 
                        <div className="summary">
                             { (!props.taskName || props.taskName === "" ) &&
                                    <div className="entry m-1"> <div className="title-gray">Frames: </div> { getUniqueFrameCount() }</div>
                              }
                              {  props.taskName && props.taskName !== "" &&
                                    <div className="entry m-1"> <div className="title-gray">Completed Tasks: </div> {tasksCompleted()} of { props.actions ? props.actions.length : 0 }</div>                                    
                              }
                              <div className="entry m-1"> <div className="title-gray">Avg time per {frameOrTask()}: </div> { getAverage()}</div>
                              <div className="entry m-1"> <div className="title-gray"> Avg time per {frameOrTask()} at station: </div> { props.duration &&  DateUtils.toHHMMSS_string( DurationUtils.toSeconds(props.duration) / getUniqueFrameCount() ) } </div>
                        </div>
                  }
            </div>

      )
}

export default StationTimeLineHeader;

