import useFetch from "../../hooks/use-fetch";
import { Station, StationEntry, UserActivity } from '../../models/daily-activity.models'
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Card } from 'primereact/card';
import { User } from "../../models/user";
import Dropzone from "./drop-zone"

import Config from "../../config";
import UppyDropZone from "./uppy-drop-zone";
/*
interface IDailyActivityReportProps {
      userId: string;
      date: string;
}
*/
interface FBAPicklistReportResponse {
      date: string;
}

interface IFBAPicklistReport {
      usersActivity: UserActivity[];
      activities: StationEntry[];
      users: User[];
}

const FBAPicklistReport = () => {
      return (
            <div className='report' style={{maxWidth: "800px"}}>
            <>
                  <Card>

                        <div className="report-title-wrapper">
                              <div className="report-title">FBA Picklist</div> 
                        </div>
                        <p> Upload FBA CSV(s) to add them to the google sheet and generate a picklist</p>
                        <UppyDropZone/>
                  </Card>

                  <Card>
                  
                  </Card>
            </>
      </div>
      )
}

export default FBAPicklistReport;
/*
         <div className='report' style={{maxWidth: "800px"}}>
                  <>
                        <Card>

                              <div className="report-title-wrapper">
                                    <div className="report-title">FBA Picklist</div> 
                              </div>
                              <p> Upload FBA pdf(s) to add them to the google sheet and generate a picklist</p>
                              <UppyDropZone/>
                        </Card>

                        <Card>
                        
                        </Card>
                  </>
            </div>
            */