import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import useFetch from "../../../hooks/use-fetch";
import Config from "../../../config";


interface BoxInventoryCurrentEntries {
      boxSize: string;
      boxType: string;
      inventoryBoxes: number;
      newBoxes:  number;
      boxed:  number;
      deleted:  number;
      currentTotal: number;
}

interface IBoxInventorySerializedReportResponse {
      boxes: BoxInventoryCurrentEntries[];
      date: string;
}

const BoxingPrepCurrentInventoryReport = () => {
      
      const url = `${Config.baseUrl}/api/admin/reports/box-inventory/current`;
      const { data, loading, error } = useFetch<IBoxInventorySerializedReportResponse>(url ?? "", [], {});

      return (

            <div className='report'>
                
                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title">Current Box Inventory</div> 
                              <div className="report-date-title">{new Date().toDateString()}</div>
                        </div>

                        <div className="m-0 flex flex-direction-column align-items-start">

                              {error && <div>An error occurred loading the report.</div>}

                              {loading && <div> <ProgressSpinner /> Loading...</div>}
                              <div style={{display:"inline-block"}}> 

                              {data && data.boxes && data.boxes.length && data.boxes.length > 0 ?

                                    <DataTable value={data.boxes} stripedRows tableStyle={{ maxWidth: '500px' }}>
                                          <Column field="boxType" header="Box Type" style={{ width: '10rem' }} ></Column>
                                          <Column field="boxSize" header="Size" style={{ width: '10rem' }} ></Column>
                                          <Column field="inventoryBoxes"  header={ <div style={{ textAlign: "end" }}>Last Inventory</div>} style={{ width: '10rem', textAlign: "end" }}></Column>
                                          <Column field="newBoxes"  header={ <div style={{ textAlign: "end" }}>New</div>} style={{ width: '10rem', textAlign: "end" }}></Column>
                                          <Column field="boxed"  header={ <div style={{ textAlign: "end" }}>Consumed</div>} style={{ width: '10rem', textAlign: "end" }}></Column>
                                          <Column field="deleted"  header={ <div style={{ textAlign: "end" }}>Deleted</div>} style={{ width: '10rem', textAlign: "end" }}></Column>
                                          <Column  header={ <div style={{ textAlign: "end" }}>Quantity</div>} style={{ width: '10rem', textAlign: "end" }}></Column>

                                    </DataTable>
                                    
                                    :
                                    "There are no boxes in inventory."
                              }

                              {data && data.boxes && data.boxes.length && data.boxes.length > 0 &&
                                    <div> Total Boxes in Inventory: {data.boxes.map( b=>b.inventoryBoxes).reduce((sum,b)=>{ return sum + b} )}</div>
                              }

                               </div>

                        </div>
                  </Card>
                
            </div>
      )
}

export default BoxingPrepCurrentInventoryReport;

/*
{  data && data.boxes && data.boxes.length > 0 && 
      <div className="flex flex-row p5 justify-content-end">
      <div className="font-bold" > Total Boxes </div> 
      <div className="flex justify-content-end pt-15 pl-3" style={{paddingRight:"15px"}}  >
                  { data.boxes.map( e=> parseInt( e.total ) ).reduce((a: number, b: number) => { return a + b; }, 0) }
      </div>
</div>
}
*/