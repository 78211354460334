import { UserActivity } from "../../../models/daily-activity.models";

interface IUserSummary  {
      userName: string;
      userActivity: UserActivity
}

const UserSummaryPanel= (props: IUserSummary) => {
      return (
            
            <div className="user-section flex align-content-start flex-column">
                  {props.userName} 
            </div>
      );
}

export default UserSummaryPanel;