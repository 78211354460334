import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import useFetch from "../../hooks/use-fetch";
import Config from "../../config";
import { useParams } from "react-router-dom";
import { FrameProduct } from "../../models/frame-product";
import FrameTile2 from "./frame-tile2";
import PLACEHOLDER_IMAGE from './PLACEHOLDER_IMAGE.jpg';
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { useEffect, useState } from "react";

interface IShopifyConsumptionMappingResult {
     // mappings: IProduct[];
     stations: IStation[];
}

interface IStation {
      stationId: string;
      stationName: string;
      mappings: IProduct[];
}

interface IShopifyConsumptionMapping {
      shopifyConsumptionMappingId: string;
      frameProductId: string;
      frameProduct: FrameProduct
      stationId: number;
      shopifyProductId: string;
      shopifyVariantId: string | null;
      quantity: number;
      shopifyCollectionId: string;
      shopifyProductTitle: string;
      shopifyCollectionTitle: string;
      shopifyImageUrl: string;   
}

interface IProduct {
   
      productId: string;
      createdTimestamp: Date;
      createdBy: string;
      lastUpdated: Date;
      lastUpdatedBy: number | null;
      deletedBy: string | null;
      sku: string;
      skuInternal: string;
      modelName: string;
      modelYear: string;
      modelSize: string;
      manufacturer: string;
      description: string | null;
      infoJsonB: object;
      frameProfileId: string | null;
      barCode: string;
      frameStyle: IFrameStyles;
      frameStyleId: string;
    //  productCategory: ProductCategories;
   //   frameProfile: FrameProfiles;
      openBoxBarCode: string | null;
      openBoxSku: string | null;
      shopifyConsumptionMappings: IShopifyConsumptionMapping[];

}

interface IFrameStyles {
      frameStyleId: string;
      frameStyle: string;
     // frameProfile: FrameProfiles;
      frameProfileId: string;
   //   products: Products[];
     // productMouldings: ProductMouldings[];
     // limitedFrameStyles: LimitedFrameStyles;
     // frameCategory: FrameCategory;      
      frameCategoryId: string;  
      sideChipImage: string | null;
      frontChipImage: string | null;
      sideCornerImage: string | null;
      frontCornerImage: string | null;
      perspectiveCornerImage: string | null;            
}
        


const ConsumptionMappingReport = () => {
      const [ frameFilter, setFrameFilter] = useState<string>("");
      const [ partFilter, setPartFilter] = useState<string>("");

      //result of the search/filter
      const [ filteredData, setFilteredData] = useState<IStation[]>();

      const params = useParams();
      const url = params && `${Config.baseUrl}/api/deconet/consumption/mapping/`;
      const { data, loading, error } = useFetch<IShopifyConsumptionMappingResult>(url ?? "", [], {});
      let curFrameStyle = ""

      /*
      // frame filter
      useEffect(() => {
          //if there is a part filter in affect, use that as the datasource to continue filtering
         // const sourceData = (partFilter === "" && frameFilter === "" ? data.stations : filteredData!);
          const sourceData = data.stations;
          if ( frameFilter !== "" && data && data.stations && data.stations.length && data.stations.length > 0) {

            const result = []
            for ( let station of sourceData) {
                  const matches = station.mappings.filter(m=> (`${m.frameStyle.frameStyle} ${m.modelSize} ${m.modelYear}`).toLowerCase().includes( frameFilter.toLowerCase() ))
                  if ( matches )  {
                        let stationCopy: IStation = JSON.parse( JSON.stringify( station ));
                        stationCopy.mappings = matches;
                        result.push( stationCopy );
                  }
            }

            setFilteredData( result );
          }  else {
            // if the frame filter was cleared
            if ( frameFilter === "" && partFilter == "" ) {
                  setFilteredData( data.stations );
            } else {
                  setPartFilter( partFilter )
            }
          }
            
      }, [frameFilter]);

      useEffect(() => {
            //if there is a part filter in affect, use that as the datasource to continue filtering
          //  const sourceData = (partFilter === "" && frameFilter === "" ? data.stations : filteredData!);
            const sourceData = data.stations;
            if ( partFilter !== "" && data && data.stations && data.stations.length && data.stations.length > 0) {
              const result = [];

              for ( let station of sourceData) {

                  const stationCopy: IStation = JSON.parse( JSON.stringify( station ));

                  const filteredFrames: IProduct[] = [];
                  for ( let frame of station.mappings ) {

                        const framePartMatches = frame.shopifyConsumptionMappings.filter(  part => part.shopifyProductTitle.toLowerCase().includes( partFilter.toLowerCase() ) ) ;
                        if ( framePartMatches.length > 0  ) {
                              const frameCopy = JSON.parse( JSON.stringify( frame ));
                              frameCopy.shopifyConsumptionMappings = framePartMatches;
                              let idx = stationCopy.mappings.findIndex( f=> f.productId === frame.productId );
                              stationCopy.mappings[idx] = frameCopy;
                        } else {
                              stationCopy.mappings = stationCopy.mappings.filter( p => p.productId != frame.productId);
                        }

                       
                  }
                  result.push(stationCopy);


              }
              setFilteredData( result );
              
            } else {
                  setFilteredData( sourceData );
            }
              
        }, [partFilter]);

*/

        useEffect(() => {
            //if there is a part filter in affect, use that as the datasource to continue filtering
          //  const sourceData = (partFilter === "" && frameFilter === "" ? data.stations : filteredData!);

          // if still loading data, do nothing
            if ( !data || !data.stations  || !data.stations.length || data.stations.length === 0 ){
                  return;
            }


            const sourceData = data.stations;


            let resultFrame = []
            // filter on frames
            if ( frameFilter !== "" ) {
                
                  //here want to search the result of the previous filter and continue filtering it if needed... but don't want to add 'new results' to the 'old result;
                  for ( let station of data.stations) {
                        const matches = station.mappings.filter(m=> (`${m.frameStyle.frameStyle} ${m.modelSize} ${m.modelYear}`).toLowerCase().includes( frameFilter.toLowerCase() ))
                        if ( matches )  {
                              let stationCopy: IStation = JSON.parse( JSON.stringify( station ));
                              stationCopy.mappings = matches;
                              resultFrame.push( stationCopy );
                        }
                  }
            }

            let result = [];

            // filter on parts
            if ( partFilter !== "") {
              const source = frameFilter === "" ? data.stations : resultFrame; //resultFrame may be [] because it filtered out everything but that is okay
              for ( let station of source) {

                  const stationCopy: IStation = JSON.parse( JSON.stringify( station ));

                  const filteredFrames: IProduct[] = [];
                  for ( let frame of station.mappings ) {

                        const framePartMatches = frame.shopifyConsumptionMappings.filter(  part => part.shopifyProductTitle.toLowerCase().includes( partFilter.toLowerCase() ) ) ;
                        if ( framePartMatches.length > 0  ) {
                              const frameCopy = JSON.parse( JSON.stringify( frame ));
                              frameCopy.shopifyConsumptionMappings = framePartMatches;
                              let idx = stationCopy.mappings.findIndex( f=> f.productId === frame.productId );
                              stationCopy.mappings[idx] = frameCopy;
                        } else {
                              stationCopy.mappings = stationCopy.mappings.filter( p => p.productId !== frame.productId);
                        }

                       
                  }
                  result.push(stationCopy);
              }
              
            } else {

                  result = resultFrame;
            }


            if ( frameFilter === "" && partFilter === "" ) {
                  setFilteredData( data.stations );
            } else {
                  setFilteredData( result );
            }

              
        }, [frameFilter, partFilter]);


      useEffect(() => {
           setFilteredData( data.stations )
              
        }, [data]);


      function convertoFrameProduct( frame: any){
     
            let product = {
                  modelSize: frame.modelSize,
                  image: frame.frameStyle.perspectiveCornerImage ? frame.frameStyle.perspectiveCornerImage :  frame.frameStyle.sideChipImage ?  frame.frameStyle.sideChipImage : "NOT_FOUND",
                  barCode: "",
                  modelName: frame.modelName,
                  modelYear: frame.modelYear,
            }
            return new FrameProduct( product);
      }

      function camelCaseToWords(s: string) {
            const result = s.replace(/([A-Z])/g, ' $1');
            return result.charAt(0).toUpperCase() + result.slice(1);
      }

      return (

            <div className='report'>
                
                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title">Deconet Premiere Frame Parts </div> 
                        </div>
                        <div className="flex">
                              <div className="card flex justify-content-center">
                                    <FloatLabel>
                                          <InputText id="frame-filter" value={frameFilter} onChange={(e) => setFrameFilter(e.target.value)}  style={{width: "300px"}}/>
                                          <label htmlFor="frame-filter">Filter by frame style, size and year</label>
                                    </FloatLabel>
                              </div>

                              <div className="card flex justify-content-center">
                                    <FloatLabel>
                                          <InputText id="filter-part" value={partFilter} onChange={(e) => setPartFilter(e.target.value)}  style={{width: "300px"}}/>
                                          <label htmlFor="filter-part">Filter by part name</label>
                                    </FloatLabel>
                              </div>

                        </div>
                        <div className="m-0 flex flex-direction-column align-items-start">

                              {error && <div>An error occurred loading the report.</div>}

                              {loading && <div> <ProgressSpinner /> Loading...</div>}
                              <div style={{display:"inline-block"}}> 
                              {data && data.stations && data.stations.length === 0 &&
                                       <div>No frame consumption mapped to shopify was found</div>
                              }

                        {filteredData && filteredData.length > 0  && filteredData.map((station: IStation) => {
                              return (
                                    <div key={station.stationId}  className="station-productivity-report flex flex-column flex-wrap justify-content-start  align-items-start align-content-start  frame-consumption-entry" >

                                          <div className="frame-grid-station-title"> { camelCaseToWords(station.stationName)}</div>

                                          {station.mappings && station.mappings.length > 0  && station.mappings.map((frame: IProduct) => {
                                                return (

                                                      <div key={frame.productId}  className="flex flex-row flex-wrap frame-consumption-entry" >

                                                            <div className="flex flex-row align-items-center frame-consumption-entry-image" style={{}}>
                                                                  <FrameTile2  eventHistoryId={""} frameSerialId={""} frame={convertoFrameProduct(frame)} />
                                                            </div>
                                                            <div className='frame-consumption-part-mapping-list'>
                                                                  {frame && frame.shopifyConsumptionMappings && frame.shopifyConsumptionMappings.length > 0  && frame.shopifyConsumptionMappings.map((mapping: IShopifyConsumptionMapping) => {
                                                                        return (
                                                                              <div key={mapping.shopifyConsumptionMappingId} className="frame-consumption-mapping" >
                                                                                    <div className="flex-column">
                                                                                          <img src={mapping.shopifyImageUrl === "" ? PLACEHOLDER_IMAGE : mapping.shopifyImageUrl} alt="frame" />
                                                                                          <div> QTY: {mapping.quantity}  </div>
                                                                                    </div>
                                                                                    <div >{mapping.shopifyProductTitle} </div>
                                                                              </div>
                                                                        )
                                                                  })}
                                                            </div>
                                                      </div>


                                                )
                                          })}

                                    </div> 
                        )
                  })}
                              </div>

                        </div>
                  </Card>
                
            </div>
      )
}

export default ConsumptionMappingReport;