import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import useFetch from "../../hooks/use-fetch";
import Config from "../../config";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";


interface CustListHistoryEntry {
      displayDateTime: string;
      batchDateTime: number;
      totalFrames: number;
      totalFramesCut: number;
}

interface ICustListHistoryResponse {
      cutListHistory: CustListHistoryEntry[]
}

const CutListHistoryHome = () => {

      const [rowData, setRowData] = useState<CustListHistoryEntry[]>([]);
      const [selectedDateTime, setSelectedDateTime] = useState<CustListHistoryEntry>();
      const [metaKey, setMetaKey] = useState(true);

      const url = `${Config.baseUrl}/api/admin/cut-list/history/`;
      const { data, loading, error } = useFetch<ICustListHistoryResponse>(url ?? "", [], {});

      const navigate = useNavigate();

      useEffect(() => {

            function getData() {
                  if (data && data.cutListHistory && data.cutListHistory.length > 0) {
                        setRowData( data.cutListHistory );
                  }
            }
            getData()

      }, [data])

      useEffect(() => {
            if ( selectedDateTime ) {
                   navigate( `/cut-list-history/batch/${selectedDateTime.batchDateTime}`);
            }

      }, [selectedDateTime] );
  

      return (

            <div className='report'>
                
                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title">Cut List Sync History </div> <div className="report-date-title">{new Date().toDateString()}</div>
                        </div>

                        <div className="m-0 flex flex-direction-column align-items-start">

                              {error && <div>An error occurred loading the report.</div>}

                              {loading && <div> <ProgressSpinner /> Loading...</div>}
                              <div style={{display:"inline-block"}}> 
                              {data && data.cutListHistory && data.cutListHistory.length > 0 ?

                                    <DataTable  
                                          value={rowData} 
                                          selectionMode="single" 
                                          selection={selectedDateTime}
                                          onSelectionChange={(e) => setSelectedDateTime(e.value as any)} 
                                          dataKey="batchDateTime" metaKeySelection={metaKey}
                                          stripedRows tableStyle={{ maxWidth: '500px' }}>
                                                
                                          <Column field="displayDateTime" header="Date/Time" style={{ width: '20rem' }} ></Column>
                                          <Column field="totalFrames" header="Number to Cut" style={{ width: '10rem' }} ></Column>
                                          <Column field="totalFramesCut" header="Frames Cut" style={{ width: '10rem' }} ></Column>
                                    </DataTable>
                                    
                                    :

                                    "No cut lists have been synced."


                              }
                              
                              </div>

                        </div>
                  </Card>
                
            </div>
      )
}

export default CutListHistoryHome;