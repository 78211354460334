import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import useFetch from "../../hooks/use-fetch";
import Config from "../../config";
import { useParams } from "react-router-dom";


interface CutListBatchEntry {
      displayDateTime: string,
      frameProfile: string,
      frameStyle: string;
      frameSize: string;
      frameYear: string;
      originalQuantity: number;
      quantityCut: number;
}

interface ICutListBatchResponse {
      cutListBatch: CutListBatchEntry[]
}


        


const CutListHistorySyncReport = () => {

      const params = useParams();
      const url = params && params.datetime && `${Config.baseUrl}/api/admin/cut-list/history/batch/${params.datetime}`;
      const { data, loading, error } = useFetch<ICutListBatchResponse>(url ?? "", [], {});
      const footer =  <tr role="row" className="p-row-odd" style={{maxWidth: '850px'}} draggable="false">
                        <td className="" role="cell" style={{ width: "10rem"}}>Totals:</td>
                        <td className="" role="cell" style={{ width: "13rem"}}></td>
                        <td className="" role="cell" style={{ width: "10rem"}}></td>
                        <td className="" role="cell" style={{ width: "10rem"}}></td>
                        <td className="p-align-right" role="cell" style={{width: "10rem", textAlign: "right"}}> { data && data.cutListBatch && data.cutListBatch.length>  0 ? data.cutListBatch.map( e=>  e.originalQuantity ).reduce((a: number, b: number) => { return a + b; }, 0) : "0" }</td>
                        <td className="p-align-right" role="cell" style={{width: "10rem", textAlign: "right"}}>{  data && data.cutListBatch && data.cutListBatch.length>  0 ? data.cutListBatch.map( e=>  e.quantityCut ).reduce((a: number, b: number) => { return a + b; }, 0) : "0" }</td>
                      </tr>

      return (

            <div className='report'>
                
                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title">Cut List </div> 
                              <div className="report-date-title">{ data.cutListBatch && data.cutListBatch.length > 0 ? data.cutListBatch[0]?.displayDateTime.replace(".000Z", "") : "no data"}</div>
                        </div>

                        <div className="m-0 flex flex-direction-column align-items-start">

                              {error && <div>An error occurred loading the report.</div>}

                              {loading && <div> <ProgressSpinner /> Loading...</div>}
                              <div style={{display:"inline-block"}}> 
                              {data && data.cutListBatch && data.cutListBatch.length > 0 ?
                                    <div style={{ maxWidth: '850px' }} >
                                    <DataTable value={data.cutListBatch} stripedRows scrollHeight="81vh" footer={footer}>
                                          <Column field="frameProfile" header="Profile" style={{ width: '10rem' }} ></Column>
                                          <Column field="frameStyle" header="Frame Style" style={{ width: '13rem' }} ></Column>
                                          <Column field="frameYear" header="Year" style={{ width: '10rem' }} ></Column>
                                          <Column field="frameSize" header="Size" style={{ width: '10rem' }} ></Column>
                                          <Column field="originalQuantity" align="right" header={ <div style={{ textAlign: "right" }}>Qty to Cut</div>} style={{ width: '10rem', textAlign: "right" }} ></Column>
                                          <Column field="quantityCut"  align="right" header={ <div style={{ textAlign: "right" }}>Cut</div>} style={{ width: '10rem', textAlign: "right" }}></Column>
                                    </DataTable>
                                    </div>
                                    
                                    :

                                    "Cut List Sync Report not found"


                              }
      
                              </div>

                        </div>
                  </Card>
                
            </div>
      )
}

export default CutListHistorySyncReport;