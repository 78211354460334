import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import useFetch from "../../../hooks/use-fetch";
import Config from "../../../config";


interface BoxInventoryEntries {
      boxSize: string;
      boxType: string;
      total: string;
}

interface IBoxInventorySerializedReportResponse {
      boxes: BoxInventoryEntries[];
      phantomBoxes:  BoxInventoryEntries[];
      date: string;
}

const BoxingPrepLastInventoryReport = () => {
      
      const url = `${Config.baseUrl}/api/admin/reports/box-inventory/serialized`;
      const { data, loading, error } = useFetch<IBoxInventorySerializedReportResponse>(url ?? "", [], {});

      return (

            <div className='report'>
                
                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title">Last Box Inventory</div> 
                                    <div className="report-date-title">
                                    {data && data.date ? 
                                          <>
                                                {data.date}
                                          </>
                                          : 
                                          <>
                                                An inventory has not yet been performed.
                                          </>
                                    }
                                    </div>
                        </div>

                        <div className="m-0 flex flex-direction-column align-items-start">

                              {error && <div>An error occurred loading the report.</div>}

                              {loading && <div> <ProgressSpinner /> Loading...</div>}
                              <div style={{display:"inline-block"}}> 

                              {data && data.boxes && data.boxes.length > 0 ?

                                    <DataTable value={data.boxes} stripedRows tableStyle={{ maxWidth: '500px' }}>
                                          <Column field="boxType" header="Box Type" style={{ width: '10rem' }} ></Column>
                                          <Column field="boxSize" header="Size" style={{ width: '10rem' }} ></Column>
                                          <Column field="total"  header={ <div style={{ textAlign: "end" }}>Quantity</div>} style={{ width: '10rem', textAlign: "end" }}></Column>
                                    </DataTable>
                                    
                                    :

                                    "An inventory has not yet been performed."


                              }
                              { data && data.boxes && data.boxes.length > 0 && 
                                     <div className="flex flex-row p5 justify-content-end">
                                     <div className="font-bold" > Total Boxes </div> 
                                          <div className="flex justify-content-end pt-15 pl-3" style={{paddingRight:"15px"}}  >
                                          { data.boxes.map( e=> parseInt( e.total ) ).reduce((a: number, b: number) => { return a + b; }, 0) }
                                          </div>
                                    </div>
                              }
                              </div>

                        </div>
                  </Card>


                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title">Phantom Boxes Detected</div> 
                                    <div className="report-date-title">
                                    {data && data.date ? 
                                          <>
                                                {data.date}
                                          </>
                                          : 
                                          <>
                                                No phantom labels detected.
                                          </>
                                    }
                                    </div>
                        </div>

                        <div className="m-0 flex flex-direction-column align-items-start">

                              {error && <div>An error occurred loading the report.</div>}

                              {loading && <div> <ProgressSpinner /> Loading...</div>}
                              <div style={{display:"inline-block"}}> 

                              {data && data.phantomBoxes && data.phantomBoxes.length > 0 ?

                                    <DataTable value={data.phantomBoxes} stripedRows tableStyle={{ maxWidth: '500px' }}>
                                          <Column field="boxType" header="Box Type" style={{ width: '10rem' }} ></Column>
                                          <Column field="boxSize" header="Size" style={{ width: '10rem' }} ></Column>
                                          <Column field="lastname" header="User" style={{ width: '10rem' }} ></Column>
                                          <Column field="total"  header={ <div style={{ textAlign: "end" }}>Quantity</div>} style={{ width: '10rem', textAlign: "end" }}></Column>
                                    </DataTable>
                                    
                                    :

                                    "This inventory has no phantom boxes. All boxes were identified in the inventory or consumed."


                              }
                              { data && data.phantomBoxes && data.phantomBoxes.length > 0 && 
                                     <div className="flex flex-row p5 justify-content-end">
                                     <div className="font-bold" > Total Phantom Boxes </div> 
                                          <div className="flex justify-content-end pt-15 pl-3" style={{paddingRight:"15px"}}  >
                                          { data.boxes.map( e=> parseInt( e.total ) ).reduce((a: number, b: number) => { return a + b; }, 0) }
                                          </div>
                                    </div>
                              }
                              </div>

                        </div>
                  </Card>
                
            </div>
      )
}

export default BoxingPrepLastInventoryReport;