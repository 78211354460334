/* eslint-disable array-callback-return */
import { Panel } from "primereact/panel";
import { CuttingBatchSummaryRow, CuttingRecut, EntryTypeEnum, StationEntry } from "../../../models/daily-activity.models";
import FrameTile from "../frame-event-tiles/frame-tile";

interface ICuttingBatchActionProps {
      stationEntry: StationEntry;
      showEventIds: boolean;
      actions: ( CuttingBatchSummaryRow|  CuttingRecut) [] ;
}


// items will be either the quantity of each frame size cut _OR_ a recut
const CuttingBatchActions = (props: ICuttingBatchActionProps) => {

      return (

            <>
               
            { props.actions && props.actions.map ( ( action: CuttingBatchSummaryRow | CuttingRecut ) => {  
                  
                  switch( action.entryType) {
                        case EntryTypeEnum.CUTTING_RECUT:
                              return  <div  className="flex  ">
                                          <Panel className="w-12" header={<div className="flex">Recut Batch - Saw {action.recutCuttingSawId}<div className="text-gray flex w-max">&nbsp;({(action as CuttingRecut).batchId}) </div></div>} toggleable>
                                             
                                                <div className="flex align-content-start flex-column">
                                                      <div className="flex">
                                                            <div className="text-xs">Original Frame</div>
                                                            <FrameTile eventHistoryId={""} frameSerialId={(action as CuttingRecut).recutOriginalFrameSerialid} frame={(action as CuttingRecut).recutOriginalFrame!} />
                                                           <div>{action.discarded}</div>
                                                           <div>{action.recutNewSize}</div>
                                                      </div>

                                                      {action.recutNewFrame && 
                                                      <>
                                                            <h2>New Frame</h2>
                                                                  <FrameTile eventHistoryId={""} frameSerialId={(action as CuttingRecut).recutNewFrameSerialId!} frame={(action as CuttingRecut).recutNewFrame!} />
                                                                  </>
                                                      }                                                                                  
                                                </div>
                                           
                                          </Panel>
                                    </div> 
                                    

                        case EntryTypeEnum.CUTTING_BATCH_SUMMARY:                              
                              return <div  className="flex  ">

                                    <div className="flex align-content-start flex-column">
                                          <div className="flex">
                                               <div>{action.modelSize}" </div>
                                               <div>- Quantity: {action.quantity}</div>
                                          </div>                                                                              
                                    </div>
             
                        </div> 
                  }              
            })}
            </>
      )
}

export default CuttingBatchActions;