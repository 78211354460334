
import useFetch from "../../hooks/use-fetch";
import { ProgressSpinner } from 'primereact/progressspinner';
import {  useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Card } from 'primereact/card';

import Config from "../../config";
import { Button } from "primereact/button";

import 'ag-grid-enterprise';

import { AgGridReact } from 'ag-grid-react/lib/agGridReact';

import {  ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Panel } from "primereact/panel";


interface FrameStyleSizeYearRow {
      frameStyleId: string; // used as the id in the grid
      frameProfileId: string;
      total: string;
      modelSize: string;
      modelYear: string;
      modelName: string;
}

interface IFipByStyleSizeYearResponse {
      frames: FrameStyleSizeYearRow[];
}

interface FrameStyleQuantity {
      size: string;
      total: string;
}
interface TableRow {
      modelYear: string;
      modelName: string;
      sizes: FrameStyleQuantity[];
}


const FIPByStyleSizeYear = () => {

      // make this work for a selected date and user if 
      const [date, setDate] = useState<Date | null>(null);

      const [displayByStation, setDisplayByStation] = useState<boolean>(true);
      /// this should likely just list both alloy and premiere for a complete view
      const [clipboard, setClipBoard] = useState<string>("");

      const [dataFormatted, setDataFormatted] = useState<TableRow[]>([]);

      const [rowData, setRowData] = useState<GridRow[]>([]);

      const [rowDataAlloy, setRowDataAlloy] = useState<GridRow[]>([]);

      const [frameType, setFrameType] = useState<'premiere'|'alloy'>('premiere');

      const [columnDefs, setColumnDefs] = useState([
            {

                  headerName: '',
                  headerClass: 'grid-header-style' , 
                  textAlign: 'right',
                  children: [
                        {
                              field: "frameStyle",
                              headerName: "Style",
                              textAlign: 'left',
                              cellClass: 'ag-left-aligned-cell',
                              minWidth: 400,
                              pinned: 'left',
                        },
                  ],
            },
            {
                  headerName: '',
//                  headerClass: 'grid-header-2020',
                  children: [
                        {
                              field: "s32_2021",  // 32' 2020 and 32'2021 are the same.  Mapping all 32's to the 2021 sku - this is not a mistake
                              headerName: "32",
                              type: 'valueColumn',
                              headerClass: 'ag-right-aligned-header' , 
                              cellClass: 'ag-right-aligned-cell',
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                  ],
            },
            {

                  headerName: '2017-2020 MY',
                  headerClass: 'grid-header-2020',
                  children: [

                        {
                              field: "s43_2020",
                              headerName: "43",
                              type: 'valueColumn', 
                              headerClass: 'ag-right-aligned-header' ,
                              cellClass: 'ag-right-aligned-cell',
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s50_2020",
                              headerName: "50",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s55_2020",
                              headerName: "55",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s65_2020",
                              headerName: "65",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s75_2020",
                              headerName: "75",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                       
                  ],
            },
            {
                  headerName: '2021-2023 MY',
                  headerClass: 'grid-header-2021',
                  children: [
                        {
                              field: "s43_2021",
                              headerName: "43",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              },
                        },
                        {
                              field: "s50_2021",
                              headerName: "50",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              },
                        },
                        {
                              field: "s55_2021",
                              headerName: "55",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              },
                        },
                        {
                              field: "s65_2021",
                              headerName: "65",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              },
                        },                              
                        {
                              field: "s75_2021",
                              headerName: "75",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              },
                        },
                        
                  ]
            },{
                  headerName: '', // there is no '85 2020, so only one 2021
                  children: [
                        {
                              field: "s85_2021",
                              headerName: "85",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                  ],
            },            
            {
                  headerName: 'Total',
                  headerClass: 'ag-right-aligned-header' ,
                  valueGetter: (params: any) => {
                        let d: GridRow = params.data;
                        if (params && params.data) {
                              const rowTotal =   (params.data.s43_2020 ?? 0 ) + (params.data.s50_2020 ?? 0 ) + (params.data.s55_2020 ?? 0 ) + (params.data.s65_2020 ?? 0 ) + (params.data.s75_2020 ?? 0 ) 
                                    + (params.data.s32_2021 ?? 0 ) + (params.data.s43_2021 ?? 0 ) + (params.data.s50_2021 ?? 0 ) + (params.data.s55_2021 ?? 0 ) + (params.data.s65_2021 ?? 0 ) + (params.data.s75_2021 ?? 0 ) + (params.data.s85_2021 ?? 0 );
                                    console.log( rowTotal )
                                    if (isNaN(rowTotal)) {
                                          debugger;
                                    }
                                    return rowTotal;
                        } else {
                              // this is the last row of the column where the grand total is shown
                              let rowTotal = 0;
                              Object.keys(params.node.aggData).forEach(function (key) {
                                    rowTotal +=isNaN( params.node.aggData[key] ) ? 0 :  Number( params.node.aggData[key] )
                              })
                              console.log( rowTotal )
                              return rowTotal; //params.node.aggData 99999; // params.row.node;
                        }
                  },

                  //(data: any) => data.32_2020 + data.43_2020 + data.50_2020 + data.55_2020 + data.65_2020 + data.75_2020 + data.85_2020 + data.32_2021 + data.43_2021 + data.50_2021 + data.55_2021 + data.65_2021 + data.75_2021 + data.85_2021,
                  valueParser: (params: any) => Number(params.newValue),
                  editable: false,
                  cellClass: 'total-col',
            },
      ]);
 
      const [columnAlloyDefs, setColumnAlloyDefs] = useState([
            {

                  headerName: '',
                  headerClass: 'grid-header-style' , 
                  children: [
                        {
                              field: "frameStyle",
                              headerName: "Style",
                              textAlign: 'left',
                              cellClass: 'ag-left-aligned-cell',
                              minWidth: 400,
                              pinned: 'left',
                        },
                  ],
            },
            {
                  headerName: '2021-2023 MY',
                  headerClass: 'grid-header-2021',
                  children: [
                        {
                              field: "s32_2021",
                              headerName: "32",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s43_2021",
                              headerName: "43",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s50_2021",
                              headerName: "50",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s55_2021",
                              headerName: "55",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s65_2021",
                              headerName: "65",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s75_2021",
                              headerName: "75",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s85_2021",
                              headerName: "85",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                  ]
            },
            {
                  headerName: 'Total',
                  valueGetter: (params: any) => {
                        let d: GridRow = params.data;
                        if (params && params.data) {
                              return  (params.data.s32_2021 ?? 0 ) + (params.data.s43_2021 ?? 0 ) + (params.data.s50_2021 ?? 0 ) + (params.data.s55_2021 ?? 0 ) + (params.data.s65_2021 ?? 0 ) + (params.data.s75_2021 ?? 0 ) + (params.data.s85_2021 ?? 0 );
                        } else {
                              // this is the last row of the column where the grand total is shown
                              let rowTotal = 0;
                              Object.keys(params.node.aggData).forEach(function (key) {
                                    rowTotal += Number(params.node.aggData[key])
                              })
                              return rowTotal; //params.node.aggData 99999; // params.row.node;
                        }
                  },

                  //(data: any) => data.32_2020 + data.43_2020 + data.50_2020 + data.55_2020 + data.65_2020 + data.75_2020 + data.85_2020 + data.32_2021 + data.43_2021 + data.50_2021 + data.55_2021 + data.65_2021 + data.75_2021 + data.85_2021,
                  valueParser: (params: any) => Number(params.newValue),
                  editable: false,
                  cellClass: 'total-col',
            },
      ]);

      const columnTypes = {
            valueColumn: {
                  editable: true,
                  aggFunc: 'sum', // for column total row
                  valueParser: (params: any) => Number(params.newValue),  // for column total row
                  filter: 'agNumberColumnFilter',  // for column total row
            }
      }
      const defaultColDef = useMemo<ColDef>(() => {
            return {
                  editable: true,
                  sortable: true,
                  resizable: true,
                  filter: true,
                  flex: 1,

            };
      }, []);

      const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

      const url = frameType && `${Config.baseUrl}/api/admin/reports/fips-by-style-size-year/${frameType}`;

      const { data, loading, error } = useFetch<FrameStyleSizeYearRow[]>(url ?? "", [], {});

      const gridRef = useRef<AgGridReact<GridRow>>(null);
      const gridRefAlloy = useRef<AgGridReact<GridRowAlloy>>(null);
      interface IObjectKeys {
            [key: string]: string | number;
      }

      interface GridRow extends IObjectKeys {
            id: string;  //frameStyleId
            frameStyle: string;
            // these are prefixed with 's' so .s32_2020 is a valid variable name otherwise .32_2020 causes errors at times
         //   s32_2020: number;
            s43_2020: number;
            s50_2020: number;
            s55_2020: number;
            s65_2020: number;
            s75_2020: number;
          //   s85_2020: number; -- never existed

            s32_2021: number;
            s43_2021: number;
            s50_2021: number;
            s55_2021: number;
            s65_2021: number;
            s75_2021: number;
            s85_2021: number;
      }

      interface GridRowAlloy extends IObjectKeys {
            id: string;  //frameStyleId
            frameStyle: string;
            // these are prefixed with 's' so .s32_2020 is a valid variable name otherwise .32_2020 causes errors at times


            s32_2021: number;
            s43_2021: number;
            s50_2021: number;
            s55_2021: number;
            s65_2021: number;
            s75_2021: number;
            s85_2021: number;
      }

      useEffect(() => {

            function getData() {

                  let curStyle = ""
                  let d = [];

                  curStyle = ""


                  if ( frameType === "alloy") {
                        let prismaticRow: GridRow[] = [];
                        let scoopRow: GridRow[] = [];
                        if (data && data.length > 0) {
                              for (let entry of data) {
                                    if (curStyle !== entry.modelName) {

                                          curStyle = entry.modelName;
                                          let row = {} as GridRow;
                                          row.id = entry.frameStyleId; // required by the grid to lookup rows
                                          row.frameStyle = entry.frameProfileId === '3' ? `Prismatic ${entry.modelName}` : `Scoop ${entry.modelName}`;
                                          let key: string = `s${entry.modelSize}_${entry.modelYear}`;
                                          row[key] = Number(entry.total ?? 0);                                    
                                          prismaticRow.push( row )
                                    
                                    } else {
                                          let key: string = `s${entry.modelSize}_${entry.modelYear}`;                                    
                                          prismaticRow[prismaticRow.length - 1][key] = Number(entry.total ?? 0);
                                    }
                              }
                              setRowDataAlloy( prismaticRow );
                        }

                  } else {
                        let e: GridRow[] = [];
                        if (data && data.length > 0) {
                              for (let entry of data) {
                                    if (curStyle !== entry.modelName) {

                                          curStyle = entry.modelName;
                                          let row = {} as GridRow;
                                          row.id = entry.frameStyleId; // required by the grid to lookup rows
                                          row.frameStyle = entry.frameProfileId === '8'? `DecoSlim ${entry.modelName}` : entry.frameProfileId === '7' ? `Target ${entry.modelName}` : `Premiere ${entry.modelName}`;
                                          let key: string = `s${entry.modelSize}_${entry.modelYear}`;
                                          row[key] = Number(entry.total ?? 0);
                                          e.push(row);

                                    } else {

                                          let key: string = `s${entry.modelSize}_${entry.modelYear}`;
                                          e[e.length - 1][key] = Number(entry.total ?? 0);
                                    }
                              }
                              setRowData(e)
                        }
                  }

            }
            getData()

      }, [frameType, data])

      const onGridReady = useCallback((params: any) => {
            if ( frameType==='premiere'){
                  gridRef.current?.api.sizeColumnsToFit({
                        defaultMinWidth: 100,
                        columnLimits: [{ key: 'frameStyle', minWidth: 400 }],
                  });
            } else {
                  gridRefAlloy.current?.api.sizeColumnsToFit({
                        defaultMinWidth: 100,
                        columnLimits: [{ key: 'frameStyle', minWidth: 400 }],
                  });
            }

      }, []);

      const onBtCopyRows = useCallback(() => {
            if ( frameType==='premiere'){
                  gridRef.current?.api.forEachNode(node => node.setSelected(true));
                  gridRef.current?.api.copySelectedRowsToClipboard();
                  gridRef.current?.api.forEachNode(node => node.setSelected(false));
            } else {
                  gridRefAlloy.current?.api.forEachNode(node => node.setSelected(true));
                  gridRefAlloy.current?.api.copySelectedRowsToClipboard();
                  gridRefAlloy.current?.api.forEachNode(node => node.setSelected(false));
            }
      }, []);

      // not used but an example of copying by range
      const onBtCopyRange = useCallback(() => {
            if ( frameType==='premiere'){
                  gridRef.current?.api.copySelectedRangeToClipboard();
            } else {
                  gridRefAlloy.current?.api.copySelectedRangeToClipboard();
            }
      }, []);


      const exportToExcel = () => {
            if ( frameType==='premiere'){
                  // @ts-ignore 
                  gridRef.current?.api.exportDataAsExcel({
                        processRowGroupCallback: (params: any) => {
                              const { node } = params;
                              return `${node.key} (${node.allChildrenCount})`;
                        }
                  });
            } else {
                  // @ts-ignore 
                  gridRefAlloy.current?.api.exportDataAsExcel({
                        processRowGroupCallback: (params: any) => {
                              const { node } = params;
                              return `${node.key} (${node.allChildrenCount})`;
                        }
                  });
            }
      };

      return (
        
                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title"> {frameType.charAt(0).toUpperCase() + frameType.slice(1)} FIPs by Style Size Year</div> <div className="report-date-title">{date?.toDateString()}</div>
                        </div>

                        { frameType === 'premiere' ?
                        <div className="ag-theme-alpine" > { /*, width: "95%" */}
                              <AgGridReact<GridRow>
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    columnTypes={columnTypes}
                                    defaultColDef={defaultColDef}
                                    pagination={false}
                                    rowMultiSelectWithClick={true}
                                    enableRangeSelection={true}
                                    rowSelection={'multiple'}
                                    onGridReady={onGridReady}
                                    groupIncludeFooter={true}
                                    groupIncludeTotalFooter={true}
                                    domLayout={'autoHeight'}
                              ></AgGridReact>
                              </div>
                              : 
                              <div className="ag-theme-alpine">
                                    <div className="alloy-header"> Alloy</div>
                                          <AgGridReact<GridRowAlloy>
                                                ref={gridRefAlloy}
                                                rowData={rowDataAlloy}
                                                columnDefs={columnAlloyDefs}
                                                columnTypes={columnTypes}
                                                defaultColDef={defaultColDef}
                                                pagination={false}
                                                rowMultiSelectWithClick={true}
                                                enableRangeSelection={true}
                                                rowSelection={'multiple'}
                                                onGridReady={onGridReady}
                                                groupIncludeFooter={true}
                                                groupIncludeTotalFooter={true}
                                                domLayout={'autoHeight'}
                                          ></AgGridReact>
                             </div>                         

                        } 


                        {error && <p>An error occurred loading the report.</p>}

                        {loading && <p> <ProgressSpinner /> Loading...</p>}

                        <Card>

                              <div className="card flex flex-wrap justify-content-start gap-3">
                                    <Button label="Copy All to Clipboard" icon="pi pi-copy" onClick={onBtCopyRows} />
                                    <Button label="Export to Excel" icon="pi pi-file-export" onClick={exportToExcel} />
                                    <Button label={ frameType==='premiere' ? 'Change to Alloy' : 'Change To Premiere' } onClick={()=>setFrameType( frameType==='premiere' ? 'alloy' : 'premiere')} />
                              </div>
                        </Card>

                
                  </Card>

      )


}

export default FIPByStyleSizeYear;