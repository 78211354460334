
import useFetch from "../../hooks/use-fetch";
import { ProgressSpinner } from 'primereact/progressspinner';
import {  RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Card } from 'primereact/card';
import { useNavigate, useParams } from "react-router-dom";
import Config from "../../config";
import { Button } from "primereact/button";

import 'ag-grid-enterprise';

import { AgGridReact } from 'ag-grid-react/lib/agGridReact';

import {  ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";



interface FrameStyleSizeYearRow {
      frameStyleId: string; // used as the id in the grid
      frameProfileId: string;
      total: string;
      modelSize: string;
      modelYear: string;
      modelName: string;
}

interface IFipByStyleSizeYearResponse {
      frames: FrameStyleSizeYearRow[];
}

interface FrameStyleQuantity {
      size: string;
      total: string;
}
interface TableRow {
      modelYear: string;
      modelName: string;
      sizes: FrameStyleQuantity[];
}

interface IBatchDetails{
      productInventoryBatch: IFrameBatch
}
interface IFrameBatch {

      productInventoryBatchId: string;
      productInventoryLocationId: string;
      locationName: string;
      toLocationName: string;
      createdDateLocal: string;
      username: string;
      deletedBy: string;
      deletedDate: string;
      batchState:  string;
      batchType: string;
      batchEntries: {
                  shelfInventory: FrameInventoryEntry[], 
                  palletInventory: FrameInventoryEntry[]
      };
      notes: string;

}

interface FrameInventoryEntry {
      inventoryId: string;
      productId: string;
      name: string; 
      size: string; 
      year: number;
      sku: string; 
      image: string; 
      style: string;
      frameStyleId: string;
      profileId: number;
      profile: string;
      quantity: number;
      hasEntry: boolean;
}
interface IFrameInventoryProps{
      inventoryBatchId: string;
}
const FrameInventoryBatchReport  = () => {//= (props: IFrameInventoryProps) => {

      const params = useParams();
      const [inventoryBatchId, setInventoryBatchId] = useState<string>("");

      const [displayByStation, setDisplayByStation] = useState<boolean>(true);
      /// this should likely just list both alloy and premiere for a complete view
      const [clipboard, setClipBoard] = useState<string>("");

      const [dataFormatted, setDataFormatted] = useState<TableRow[]>([]);

      const [rowDataPremiere, setRowDataPremiere] = useState<GridRow[]>([]);  // for premiere 

      const [rowDataSlim, setRowDataSlim] = useState<GridRow[]>([]);

      const [rowDataAlloy, setRowDataAlloy] = useState<GridRow[]>([]);

      const [frameType, setFrameType] = useState<'premiere'|'alloy'>('premiere');

      const url =  params && params.batchid && `${Config.baseUrl}/api/inventory/frames/premiere/batch/details/${params.batchid}?sortby=randy`;
      const { data, loading, error } = useFetch<IBatchDetails>(url ?? "", [], {});

      const [columnDefs, setColumnDefs] = useState([
            {

                  headerName: '',
                  headerClass: 'grid-header-style' , 
                  textAlign: 'right',
                  children: [
                        {
                              field: "frameStyle",
                              headerName: "Style",
                              textAlign: 'left',
                              cellClass: 'ag-left-aligned-cell',
                              minWidth: 400,
                              pinned: 'left',
                        },
                  ],
            },
            {
                  headerName: '',
//                  headerClass: 'grid-header-2020',
                  children: [
                        {
                              field: "s32_2021",  // 32' 2020 and 32'2021 are the same.  Mapping all 32's to the 2021 sku - this is not a mistake
                              headerName: "32",
                              type: 'valueColumn',
                              headerClass: 'ag-right-aligned-header' , 
                              cellClass: 'ag-right-aligned-cell',
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                  ],
            },
            {

                  headerName: '2017-2020 MY',
                  headerClass: 'grid-header-2020',
                  children: [

                        {
                              field: "s43_2020",
                              headerName: "43",
                              type: 'valueColumn', 
                              headerClass: 'ag-right-aligned-header' ,
                              cellClass: 'ag-right-aligned-cell',
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s50_2020",
                              headerName: "50",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s55_2020",
                              headerName: "55",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s65_2020",
                              headerName: "65",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s75_2020",
                              headerName: "75",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                       
                  ],
            },
            {
                  headerName: '2021-2023 MY',
                  headerClass: 'grid-header-2021',
                  children: [
                        {
                              field: "s43_2021",
                              headerName: "43",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              },
                        },
                        {
                              field: "s50_2021",
                              headerName: "50",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              },
                        },
                        {
                              field: "s55_2021",
                              headerName: "55",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              },
                        },
                        {
                              field: "s65_2021",
                              headerName: "65",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              },
                        },                              
                        {
                              field: "s75_2021",
                              headerName: "75",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              },
                        },
                        
                  ]
            },{
                  headerName: '', // there is no '85 2020, so only one 2021
                  children: [
                        {
                              field: "s85_2021",
                              headerName: "85",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                  ],
            },            
            {
                  headerName: 'Total',
                  headerClass: 'ag-right-aligned-header' ,
                  valueGetter: (params: any) => {
                        let d: GridRow = params.data;
                        if (params && params.data) {
                              const rowTotal =   (params.data.s43_2020 ?? 0 ) + (params.data.s50_2020 ?? 0 ) + (params.data.s55_2020 ?? 0 ) + (params.data.s65_2020 ?? 0 ) + (params.data.s75_2020 ?? 0 ) 
                                    + (params.data.s32_2021 ?? 0 ) + (params.data.s43_2021 ?? 0 ) + (params.data.s50_2021 ?? 0 ) + (params.data.s55_2021 ?? 0 ) + (params.data.s65_2021 ?? 0 ) + (params.data.s75_2021 ?? 0 ) + (params.data.s85_2021 ?? 0 );
                                    console.log( rowTotal )
                                    if (isNaN(rowTotal)) {
                                          debugger;
                                    }
                                    return rowTotal;
                        } else {
                              // this is the last row of the column where the grand total is shown
                              let rowTotal = 0;
                              Object.keys(params.node.aggData).forEach(function (key) {
                                    rowTotal +=isNaN( params.node.aggData[key] ) ? 0 :  Number( params.node.aggData[key] )
                              })
                              console.log( rowTotal )
                              return rowTotal; //params.node.aggData 99999; // params.row.node;
                        }
                  },

                  //(data: any) => data.32_2020 + data.43_2020 + data.50_2020 + data.55_2020 + data.65_2020 + data.75_2020 + data.85_2020 + data.32_2021 + data.43_2021 + data.50_2021 + data.55_2021 + data.65_2021 + data.75_2021 + data.85_2021,
                  valueParser: (params: any) => Number(params.newValue),
                  editable: false,
                  cellClass: 'total-col',
            },
      ]);
 
      const [columnAlloyDefs, setColumnAlloyDefs] = useState([
            {

                  headerName: '',
                  headerClass: 'grid-header-style' , 
                  children: [
                        {
                              field: "frameStyle",
                              headerName: "Style",
                              textAlign: 'left',
                              cellClass: 'ag-left-aligned-cell',
                              minWidth: 400,
                              pinned: 'left',
                        },
                  ],
            },
            {
                  headerName: '2021-2023 MY',
                  headerClass: 'grid-header-2021',
                  children: [
                        {
                              field: "s32_2021",
                              headerName: "32",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s43_2021",
                              headerName: "43",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s50_2021",
                              headerName: "50",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s55_2021",
                              headerName: "55",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s65_2021",
                              headerName: "65",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s75_2021",
                              headerName: "75",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                        {
                              field: "s85_2021",
                              headerName: "85",
                              headerClass: 'ag-right-aligned-header' ,
                              type: 'valueColumn', 
                              cellClass: 'ag-right-aligned-cell',
                              valueParser: (params: any) => Number(params.newValue),
                              cellEditor: 'agNumberCellEditor',
                              cellEditorParams: {
                                    min: 1,
                                    max: 100,
                                    precision: 0,
                              }
                        },
                  ]
            },
            {
                  headerName: 'Total',
                  valueGetter: (params: any) => {
                        let d: GridRow = params.data;
                        if (params && params.data) {
                              return  (params.data.s32_2021 ?? 0 ) + (params.data.s43_2021 ?? 0 ) + (params.data.s50_2021 ?? 0 ) + (params.data.s55_2021 ?? 0 ) + (params.data.s65_2021 ?? 0 ) + (params.data.s75_2021 ?? 0 ) + (params.data.s85_2021 ?? 0 );
                        } else {
                              // this is the last row of the column where the grand total is shown
                              let rowTotal = 0;
                              Object.keys(params.node.aggData).forEach(function (key) {
                                    rowTotal += Number(params.node.aggData[key])
                              })
                              return rowTotal; //params.node.aggData 99999; // params.row.node;
                        }
                  },

                  //(data: any) => data.32_2020 + data.43_2020 + data.50_2020 + data.55_2020 + data.65_2020 + data.75_2020 + data.85_2020 + data.32_2021 + data.43_2021 + data.50_2021 + data.55_2021 + data.65_2021 + data.75_2021 + data.85_2021,
                  valueParser: (params: any) => Number(params.newValue),
                  editable: false,
                  cellClass: 'total-col',
            },
      ]);

      const columnTypes = {
            valueColumn: {
                  editable: true,
                  aggFunc: 'sum', // for column total row
                  valueParser: (params: any) => Number(params.newValue),  // for column total row
                  filter: 'agNumberColumnFilter',  // for column total row
            }
      }
      const defaultColDef = useMemo<ColDef>(() => {
            return {
                  editable: true,
                  sortable: true,
                  resizable: true,
                  filter: true,
                  flex: 1,

            };
      }, []);


      const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);

      const gridRefPremiere = useRef<AgGridReact<GridRow>>(null);
      const gridRefSlim = useRef<AgGridReact<GridRow>>(null);
      const gridRefAlloy = useRef<AgGridReact<GridRowAlloy>>(null);

      interface IObjectKeys {
            [key: string]: string | number;
      }

      interface GridRow extends IObjectKeys {
            id: string;  //frameStyleId
            frameStyle: string;
            // these are prefixed with 's' so .s32_2020 is a valid variable name otherwise .32_2020 causes errors at times
         //   s32_2020: number;
            s43_2020: number;
            s50_2020: number;
            s55_2020: number;
            s65_2020: number;
            s75_2020: number;
          //   s85_2020: number; -- never existed

            s32_2021: number;
            s43_2021: number;
            s50_2021: number;
            s55_2021: number;
            s65_2021: number;
            s75_2021: number;
            s85_2021: number;
      }

      interface GridRowAlloy extends IObjectKeys {
            id: string;  //frameStyleId
            frameStyle: string;
            // these are prefixed with 's' so .s32_2020 is a valid variable name otherwise .32_2020 causes errors at times


            s32_2021: number;
            s43_2021: number;
            s50_2021: number;
            s55_2021: number;
            s65_2021: number;
            s75_2021: number;
            s85_2021: number;
      }
      /*
      useEffect(() => {

            setInventoryBatchId(props.inventoryBatchId )

      }, [props.inventoryBatchId])
      */

      useEffect(() => {

            function getData() {

                  let curStyle = ""
                  let d = [];

                  curStyle = ""
               //   data.productInventoryBatch.batchEntries.shelfInventory.sort( (a: FrameInventoryEntry, b: FrameInventoryEntry) => {
                        
                //  })
               //   frameType = data.productInventoryBatch.batchEntries.shelfInventory[0].profile == "Target" 
                  if ( params.frameType?.toLowerCase() === "alloy") {
                        let prismaticRow: GridRow[] = [];
                        let scoopRow: GridRow[] = [];
                        if (data && data.productInventoryBatch ) {
                              for (let entry of data.productInventoryBatch.batchEntries.shelfInventory) {
                                    if (curStyle !== entry.frameStyleId) {

                                          curStyle = entry.frameStyleId;
                                          let row = {} as GridRow;
                                          row.id = entry.frameStyleId; // required by the grid to lookup rows
                                          row.frameStyle = `${entry.profile} ${entry.style}`;
                                          let key: string = `s${entry.size}_${entry.year}`;
                                          row[key] = Number(entry.quantity ?? 0);                                    
                                          prismaticRow.push( row )
                                    
                                    } else {
                                          let key: string = `s${entry.size}_${entry.year}`;                                    
                                          prismaticRow[prismaticRow.length - 1][key] = Number(entry.quantity ?? 0);
                                    }
                              }
                              setRowDataAlloy( prismaticRow );
                        }

                  } else {
                        
                        if (data && data.productInventoryBatch) {
                              let e: GridRow[] = [];
                              let curStyle = "";
                              let d = [];

                              curStyle = "";
                              for (let entry of data.productInventoryBatch.batchEntries.shelfInventory) {
                                    if ( entry.profileId !== 7 && entry.profileId !== 8){
                                          if (curStyle !== entry.frameStyleId) {

                                                curStyle = entry.frameStyleId;
                                                let row = {} as GridRow;
                                                row.id = entry.frameStyleId; // required by the grid to lookup rows
                                                row.frameStyle =  `Premiere ${entry.style}`;
                                                let key: string = `s${entry.size}_${entry.year}`;
                                                row[key] = Number(entry.quantity ?? 0);
                                                e.push(row);

                                          } else {

                                                let key: string = `s${entry.size}_${entry.year}`;
                                                e[e.length - 1][key] = Number(entry.quantity ?? 0);
                                          }
                                    }
                              }
                              setRowDataPremiere(e)
                        }
                        if (data && data.productInventoryBatch) {
                              let e: GridRow[] = [];
                              let curStyle = "";
                              let d = [];

                              curStyle = "";
                              for (let entry of data.productInventoryBatch.batchEntries.shelfInventory) {
                                    if ( entry.profileId === 7 || entry.profileId === 8){
                                          if (curStyle !== entry.frameStyleId) {

                                                curStyle = entry.frameStyleId;
                                                let row = {} as GridRow;
                                                row.id = entry.frameStyleId; // required by the grid to lookup rows
                                                row.frameStyle = `${entry.profile} ${entry.style}`;
                                                let key: string = `s${entry.size}_${entry.year}`;
                                                row[key] = Number(entry.quantity ?? 0);
                                                e.push(row);

                                          } else {

                                                let key: string = `s${entry.size}_${entry.year}`;
                                                e[e.length - 1][key] = Number(entry.quantity ?? 0);
                                          }
                                    }
                              }
                              setRowDataSlim(e)
                        }
                  }

            }
            getData()

      }, [params, frameType, data])

      const onGridReady = useCallback((params: any, gridType: string) => {
            if ( gridType==='premierePremiere'){
                  gridRefPremiere.current?.api.sizeColumnsToFit({
                        defaultMinWidth: 100,
                        columnLimits: [{ key: 'frameStyle', minWidth: 400 }],
                  });
            } else if ( gridType==='premiereSlim'){
                  gridRefSlim.current?.api.sizeColumnsToFit({
                        defaultMinWidth: 100,
                        columnLimits: [{ key: 'frameStyle', minWidth: 400 }],
                  });
            } else {
                  gridRefAlloy.current?.api.sizeColumnsToFit({
                        defaultMinWidth: 100,
                        columnLimits: [{ key: 'frameStyle', minWidth: 400 }],
                  });
            }

      }, []);

      const onBtCopyRows = useCallback(( gridRef: RefObject<AgGridReact<any>> ) => {

            gridRef.current?.api.forEachNode(node => node.setSelected(true));
            gridRef.current?.api.copySelectedRowsToClipboard();
            gridRef.current?.api.forEachNode(node => node.setSelected(false));
            /*
            if ( frameType==='premiere'){
                  gridRefPremiere.current?.api.forEachNode(node => node.setSelected(true));
                  gridRefPremiere.current?.api.copySelectedRowsToClipboard();
                  gridRefPremiere.current?.api.forEachNode(node => node.setSelected(false));
            } else {
                  gridRefAlloy.current?.api.forEachNode(node => node.setSelected(true));
                  gridRefAlloy.current?.api.copySelectedRowsToClipboard();
                  gridRefAlloy.current?.api.forEachNode(node => node.setSelected(false));
            }
                  */
      }, []);

      // not used but an example of copying by range
      const onBtCopyRange = useCallback(() => {
            if ( frameType==='premiere'){
                  gridRefPremiere.current?.api.copySelectedRangeToClipboard();
            } else {
                  gridRefAlloy.current?.api.copySelectedRangeToClipboard();
            }
      }, []);


      const exportToExcel = ( gridRef: RefObject<AgGridReact<any>> ) => {
   
            // @ts-ignore 
            gridRef.current?.api.exportDataAsExcel({
                  processRowGroupCallback: (params: any) => {
                        const { node } = params;
                        return `${node.key} (${node.allChildrenCount})`;
                  }
            });
   
      };

      /*
      const exportToExcel = ( frameType: string) => {
            if ( frameType==='premierePremiere'){
                  // @ts-ignore 
                  gridRefPremiere.current?.api.exportDataAsExcel({
                        processRowGroupCallback: (params: any) => {
                              const { node } = params;
                              return `${node.key} (${node.allChildrenCount})`;
                        }
                  });
            } else if ( frameType==='premiereSlim') {
                  // @ts-ignore 
                  gridRefSlim.current?.api.exportDataAsExcel({
                        processRowGroupCallback: (params: any) => {
                              const { node } = params;
                              return `${node.key} (${node.allChildrenCount})`;
                        }
                  });

            } else {
                  // @ts-ignore 
                  gridRefAlloy.current?.api.exportDataAsExcel({
                        processRowGroupCallback: (params: any) => {
                              const { node } = params;
                              return `${node.key} (${node.allChildrenCount})`;
                        }
                  });
            }
      };
      */
      return (
        
                  <Card>
                        <div className="report-title-wrapper">

                              { params.frameType && 
                                    <div className="report-title"> {params.frameType?.charAt(0).toUpperCase() + params.frameType?.slice(1)} Inventory</div> 
                              }

                              { data && data.productInventoryBatch && data.productInventoryBatch.locationName  && 
                                    <div className="report-date-title"><span className="font-semibold" >{ data.productInventoryBatch.locationName == null ? "" : data.productInventoryBatch.locationName}</span></div>
                              }

                              { data && data.productInventoryBatch && data.productInventoryBatch.createdDateLocal  && 
                                    <div className="report-date-title"><span className="font-semibold" >{ data.productInventoryBatch.createdDateLocal == null ? "" : data.productInventoryBatch.createdDateLocal}</span></div>
                              }

                              { data && data.productInventoryBatch && data.productInventoryBatch.batchState  && 
                                    <div className="report-date-title"><span className="font-semibold" >STATE:</span> { data.productInventoryBatch.batchState == null ? "" : data.productInventoryBatch.batchState.toLowerCase()}</div>
                              }

                              { data && data.productInventoryBatch && data.productInventoryBatch.batchType  && 
                                    <div className="report-date-title"><span className="font-semibold">TYPE:</span> { data.productInventoryBatch.batchType == null ? "" : data.productInventoryBatch.batchType.toLowerCase()}</div>
                              }

                              { data && data.productInventoryBatch && data.productInventoryBatch.notes  && 
                                    <div className="report-date-title"><span className="font-semibold">NOTE:</span> NOTE{ data.productInventoryBatch.notes == null ? "" : data.productInventoryBatch.notes}</div>
                              }



                              { data && data.productInventoryBatch && data.productInventoryBatch.deletedBy  && 
                                    <>
                                          <div className="report-date-title">{ data.productInventoryBatch.deletedBy == null ? "" : data.productInventoryBatch.deletedBy}</div>
                                          <div className="report-date-title">{ data.productInventoryBatch.deletedDate == null ? "" : data.productInventoryBatch.deletedDate}</div>
                                    </>
                              }
                        </div>

                        
                        { params.frameType?.toLowerCase() === 'premiere' ?
                              <>
                     
                              <div className="report-header2"> Premiere</div> 
                 

                              <div className="ag-theme-alpine" > { /*, width: "95%" */}
                                    <AgGridReact<GridRow>
                                          ref={gridRefPremiere}
                                          rowData={rowDataPremiere}
                                          columnDefs={columnDefs}
                                          columnTypes={columnTypes}
                                          defaultColDef={defaultColDef}
                                          pagination={false}
                                          rowMultiSelectWithClick={true}
                                          enableRangeSelection={true}
                                          rowSelection={'multiple'}
                                          onGridReady={(params) => onGridReady( params, "premierePremiere")}
                                          groupIncludeFooter={true}
                                          groupIncludeTotalFooter={true}
                                          domLayout={'autoHeight'}
                                    ></AgGridReact>
                                    <Card>
                                          <div className="card flex flex-wrap justify-content-start gap-3">
                                                <Button label="Copy All to Clipboard" icon="pi pi-copy" onClick={ () => onBtCopyRows( gridRefPremiere )} />
                                                <Button label="Export to Excel" icon="pi pi-file-export" onClick={() => exportToExcel( gridRefPremiere)} />
                                          </div>
                                    </Card>
                              </div>

                              <div className="report-header2">Slim</div> 
                              <div className="ag-theme-alpine" > { /*, width: "95%" */}
                                    <AgGridReact<GridRow>
                                          ref={gridRefSlim}
                                          rowData={rowDataSlim}
                                          columnDefs={columnDefs}
                                          columnTypes={columnTypes}
                                          defaultColDef={defaultColDef}
                                          pagination={false}
                                          rowMultiSelectWithClick={true}
                                          enableRangeSelection={true}
                                          rowSelection={'multiple'}
                                          onGridReady={(params) => onGridReady( params, "premiereSlim")}
                                          groupIncludeFooter={true}
                                          groupIncludeTotalFooter={true}
                                          domLayout={'autoHeight'}
                                    ></AgGridReact>
                                    <Card>
                                          <div className="card flex flex-wrap justify-content-start gap-3">
                                                <Button label="Copy All to Clipboard" icon="pi pi-copy" onClick={ () => onBtCopyRows( gridRefSlim )} />
                                                <Button label="Export to Excel" icon="pi pi-file-export" onClick={() => exportToExcel( gridRefSlim)} />
                                          </div>
                                    </Card>
                              </div>
                              </>
                              : 
                              <div className="ag-theme-alpine">
                                    <div className="alloy-header"> Alloy</div>
                                          <AgGridReact<GridRowAlloy>
                                                ref={gridRefAlloy}
                                                rowData={rowDataAlloy}
                                                columnDefs={columnAlloyDefs}
                                                columnTypes={columnTypes}
                                                defaultColDef={defaultColDef}
                                                pagination={false}
                                                rowMultiSelectWithClick={true}
                                                enableRangeSelection={true}
                                                rowSelection={'multiple'}
                                                onGridReady={(params) => onGridReady( params, "alloy")}
                                                groupIncludeFooter={true}
                                                groupIncludeTotalFooter={true}
                                                domLayout={'autoHeight'}
                                          ></AgGridReact>
                             </div>                         

                        } 


                        {error && <p>An error occurred loading the report.</p>}

                        {loading && <p> <ProgressSpinner /> Loading...</p>}

                
                  </Card>

      )


}

export default FrameInventoryBatchReport;