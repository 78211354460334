import { IStationTask, StationTask } from "../../../models/daily-activity.models";
import { Duration } from "../../../models/duration";
import { DurationUtils } from "../../../utils/duration-utils";

interface IStationTaskCompleted extends IStationTask {

}

const StationTaskTile= (props: IStationTaskCompleted) => {
      return (
            
            <div className="flex align-content-start flex-column">
                  <div>{props.taskName}</div>
                  {props.isDeleted && <div className="text-red-500">deleted</div> }
                  {props.isDeleted && <div>{props.deletedTime}</div>}
                  <div>{props.userName}</div>
                 
            </div>
      );
}

export default StationTaskTile;