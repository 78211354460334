import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import useFetch from "../../hooks/use-fetch";
import Config from "../../config";
//import NextPreviousDayButtons from "../../components/next-previous-day-buttons";
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { PalletCountInventoryLookupResult } from "./dtos/pallet-count-inventory-iookup-result.dtos";
import "@glideapps/glide-data-grid/dist/index.css";
import {
      DataEditor,
      GridCell,
      GridCellKind,
      GridColumn,
      GroupHeaderClickedEventArgs,
      HeaderClickedEventArgs,
      Item
    } from "@glideapps/glide-data-grid";
import { Dropdown } from "primereact/dropdown";



interface IPalletInventorySummaryReport {
      inventoryId: string
      date: string;
}

interface TableRow {
      mouldingName: string;
      total: string;
}
interface PIGridColumn {
      palletCode: string;
      sku: string;
      mouldingName: string;
      poNumber: string;
      quantity: number;

}
interface IDropDownData {
      name: string;
      code: string;
}
const PalletInventoryListReport = () => {

      /// this should likely just list both alloy and premiere for a complete view
      const [clipboard, setClipBoard] = useState<string>("");

      const [dataFormatted, setDataFormatted] = useState<TableRow[]>([]);
      const [batchNotEnded, setBatchNotEnded] = useState<boolean>( false );

      const [frameType, setFrameType] = useState<'premiere'|'alloy'>('premiere');
      const params = useParams();

      const url =  params && params.batchid && `${Config.baseUrl}/api/temp-pallet-count/inventory/by-pallets/${params.batchid}`;

      const { data, loading, error } = useFetch<PalletCountInventoryLookupResult>(url ?? "", [], {});

      const [gridDataOriginal, setGridDataOriginal] = useState<PIGridColumn[]>( [] );

      const [gridData, setGridData] = useState<PIGridColumn[]>( [] );
      const [frameStyles, setFrameStyles] = useState<IDropDownData[]>([]);

      const [totalInitial, setTotalInitial] = useState<number>(0);
      const [totalConsumed, setTotalConsumed] = useState<number>(0);
      const [totalRemaining, setTotalRemaining] = useState<number>(0);
      const [sortOrderPerColumn, setSortOrderPerColumn] = useState<{title: string, sortOrder: string}[]>([]);
      const [selectedFrameStyle, setSelectedFrameStyle] = useState<IDropDownData | null>(null);

      const columns: GridColumn[] = [
            { title: "Pallet Code", width: 100 },
            { title: "Sku", width: 150 },
            { title: "Name", width: 200 },
            { title: "PO", width: 100 },
            { title: "Age", width: 100 },
            { title: "Qty@Count", width: 100 },
            { title: "Consumed", width: 100 },
            { title: "Consumed Date", width: 150 },
            { title: "Qty Consumed", width: 150 },
            { title: "Qty Remaining", width: 100 }
      ];
     
   
      useEffect(() => {
            let initialSort = [];
            for ( let c of columns ) {
                  initialSort.push( { title: c.title, sortOrder: "asc" } )
            }
            setSortOrderPerColumn( initialSort );
            function getData() {

                  let y = data; 
                  let columns: PIGridColumn [] = [];
                  let uniqueFrameStyles: {name: string, code: string}[] = [];

                  let initialQuantity = 0; 
                  let consumedQuantity = 0;
                  let remainingQuantity = 0; 

                  if ( data && data.palletsAtLastInventory ) {
                        
                        // eslint-disable-next-line array-callback-return
                        for ( let entry of data.palletsAtLastInventory ) {
      
                              let palletCode = entry.inventoryHistoryPallet.palletCode;
      
                              // eslint-disable-next-line array-callback-return
                              for ( let item of entry.inventoryHistoryPallet.inventoryHistoryPalletItems ) {
                                    const consumedPallet = data.palletsConsumedSinceLastInventory.find( e=> e.palletCode === palletCode );
                                    let wasConsumed = consumedPallet === undefined ? false : true;
                                    const consumedDateTime = consumedPallet?.deletedDateTimeLocal;
                                    let consumedDate = "";
                                    if ( consumedDateTime ) {
                                          consumedDate = consumedDateTime.substring(0, consumedDateTime.indexOf("T"))
                                    }
                                    
                                    const po =  item.purchaseOrderLineReceived.purchaseOrderLine.po.poNumber; //item.purchaseOrderLineReceived.
                                    const receivedDate = item.purchaseOrderLineReceived.purchaseOrderLine.po.receivedDate
                                    if ( ! uniqueFrameStyles.find( x => x.code === item.productMoulding.frameStyle.frameStyle )) {
                                          uniqueFrameStyles.push( { name: item.productMoulding.frameStyle.frameStyle, code: item.productMoulding.frameStyle.frameStyle } as IDropDownData );
                                    }

                                    initialQuantity += item.quantity;
                                    consumedQuantity += wasConsumed === true ? item.quantity : 0;
                                    remainingQuantity += wasConsumed === true ? 0 : item.quantity;

                                    columns.push ( {
                                          palletCode: palletCode,
                                          sku: item.productMoulding.sku,
                                          mouldingName: item.productMoulding.frameStyle.frameStyle,
                                          poNumber: po,
                                          age: receivedDate,
                                          quantity: item.quantity,
                                          consumed: wasConsumed,
                                          consumedDate:  consumedDate,
                                          consumedQuantity: wasConsumed === true ? item.quantity : "0",
                                          quantityRemaining:  consumedPallet ? "0" : item.quantity,
                                    } as PIGridColumn )
                                    
                              };
      
                              
                        };

                        setFrameStyles( uniqueFrameStyles );
                        setGridDataOriginal( columns ); // a copy to restore after filtering/searching
                        setGridData( columns );

                        setTotalInitial( initialQuantity );
                        setTotalConsumed( consumedQuantity );
                        setTotalRemaining( remainingQuantity );
                  }
                  // {pallet.inventoryHistoryPallet.palletCode}   {item.productMoulding.sku}   {item.productMoulding.frameStyle.frameStyle}  QTY: {item.quantity}
            }

            getData()
      }, [params, data]);

      function getColumnName( col: number) {
            let columnName = col === 0 ? "palletCode" : col === 1 ? "sku" : col === 2 ? "mouldingName" : col === 3 ? "poNumber" : col === 4 ? "age" : col === 5 ? "quantity" : col === 6 ? "consumed" : col === 7 ? "consumedDate" :  col === 8 ? "consumedQuantity" :  col === 9 ? "quantityRemaining" : "not set properly";
            return columnName;
      }

      function getCellData([col, row]: Item): GridCell {

            const rowdata = gridData[row];
            let columnName = getColumnName( col );

            let data =  (rowdata as any)[ columnName ];
            if ( typeof data == "boolean") {
                  data = data === true ? "yes" : ""
            } else {
                  data = data ? data.toString() : "";
            }

            return {
                  kind: GridCellKind.Text,
                  data: data,
                  allowOverlay: false,
                  displayData: data,
            };

      }

      function onHeaderClicked( col:number, event: HeaderClickedEventArgs ) {
            // sort the data by the column head
            let columnName = getColumnName( col );

            function sortFrames ( a:any, b:any) {
                  if ( !a || !b) {
                        return 0;
                  }
                  if ( a[columnName] === b[columnName] ) {
                        if ( a[columnName] === "" && b[columnName] === "") {
                              return 1;
                        } else if ( a[columnName] === b[columnName] ) {
                              return -1
                        } else {
                              if ( a[columnName] > b[columnName] ) {
                                    return 1;
                              } else  {
                                    return -1;
                              }                        
                        }
                  } else {
                        if (a[columnName] > b[columnName] ) {
                              return 1;
                        } else  {
                              return -1;
                        }              
                  }
                  
            };
            var isNumber = function isNumber(value:any) 
            {
                  return typeof value === 'number' && isFinite(value);
            }
            function sortByDataString(a:any, b:any) {
                  const a1 = a[columnName] || undefined;
                  const b1 = a[columnName] || undefined;

                  return a.localeCompare(b);
            }
            const sortorder = sortOrderPerColumn.find( c=> c.title === columnName)!.sortOrder
 

            const sortedDate = gridData.toSorted(sortByDataString );

            
            setGridData( sortedDate )
      }

      function filterByFrameStyle( style: IDropDownData ) {

            if ( !style ) {
                  setSelectedFrameStyle(null);

                  // show all
                  setGridData( gridDataOriginal );
                //  setTotalInitial( initialQuantity );
                //  setTotalConsumed( consumedQuantity );
               //   setTotalRemaining( remainingQuantity );

            } else {

                  setSelectedFrameStyle(style);

                  let fitleredData = gridDataOriginal.filter( row => row.mouldingName === style.code );
                  setGridData( fitleredData );

                  const initialQuantity = fitleredData.map( e=> e.quantity).reduce( (previous, current ) => previous + current, 0 );
               //   const palletsStart = fitleredData.map( e => e.palletCode );
                  const palletsConsumed = data.palletsConsumedSinceLastInventory.map( e=> e.palletCode );
                  const consumedQuantity = fitleredData.filter( e=>  palletsConsumed.includes(e.palletCode)).map( x=> x.quantity).reduce( (previous, current ) => previous + current, 0 );
                  const remainingQuantity = (initialQuantity ?? 0) - (consumedQuantity ?? 0)

                  setTotalInitial( initialQuantity );
                  setTotalConsumed( consumedQuantity );
                  setTotalRemaining( remainingQuantity );
            }
      }

      return (
            <div className='report'>
                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title"> Pallet Inventory Batch Summary</div> <div className="report-date-title"> 
                             
                              </div>                              
                        </div>

                        <div style={{ textAlign: "left", backgroundColor: "#5587da", color: "white:", padding: "7px" }}>
                              Total Boxes@Count:  <span style={{ color: "white", backgroundColor: "#505050", borderRadius: "5px", padding: "2px 7px", fontWeight: "bold"}}>{totalInitial}</span>  |
                              Consumed Boxes:  <span style={{ color: "white", backgroundColor: "#505050", borderRadius: "5px", padding: "2px 7px", fontWeight: "bold"}}>{totalConsumed}</span>  |
                              Remaining Boxes:  <span style={{ color: "white", backgroundColor: "#505050", borderRadius: "5px", padding: "2px 7px", fontWeight: "bold"}}>{totalRemaining}</span>
                        </div>

                        <div className="card flex justify-content-center">
                              <Dropdown 
                                    value={selectedFrameStyle} 
                                    onChange={(e) => { 
                                                       filterByFrameStyle( e.value );
                                                     

                                                }}
                                    options={frameStyles} 
                                    optionLabel="name" 
                                    placeholder="Filter by style"
//                                    className="w-full md:w-14rem" 
                                    checkmark={true}
                                    highlightOnSelect={false} 
                                    editable
                                    showClear
                               />
                        </div>

                        <DataEditor getCellContent={getCellData} columns={columns} rows={gridData.length} getCellsForSelection={true}  onHeaderClicked={onHeaderClicked} />

                       
                      {/*
                              { data && data.palletsAtLastInventory  && data.palletsAtLastInventory.map( pallet => {

                              <DataEditor getCellContent={getData} columns={columns} rows={gridData.length} />


                              return (
                                    <div className="flex justify-content-start align-items-center">
                                          
                                          <div className="flex p-1 align-content-start flex-column">
                                                {pallet.inventoryHistoryPallet.inventoryHistoryPalletItems && pallet.inventoryHistoryPallet.inventoryHistoryPalletItems.map( item => {

                                                      return (
                                                            <div>
                                                            {pallet.inventoryHistoryPallet.palletCode}   {item.productMoulding.sku}   {item.productMoulding.frameStyle.frameStyle}  QTY: {item.quantity}
                                                            </div>
                                                      )

                                                })}
                                          </div>
                                    {pallet.inventoryHistoryPallet.note}
                                    </div>
                              )
                              })}
                        */}
                        { error && <p>An error occurred loading the report <span>{error}</span></p> }
                        { batchNotEnded && <p style={{ color: 'navy' }} > There is no data to display because this batch was {batchNotEnded}</p> }
                        { loading && <p> <ProgressSpinner /> Loading... </p> }

                  </Card>
            </div>
      )
}

export default PalletInventoryListReport;