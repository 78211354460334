import IProduct from "./i-product";

export class ProductBase implements IProduct {
    
      constructor( product: any ) {
          Object.assign( this, product)
          //delete this['infojson']
      }
      
      productId!: string;
      createdTimestamp?: Date;
      createdBy?: string;
      lastUpdated?: Date;
      lastUpdatedBy?: number;
      deleted?: boolean;
      deletedBy?: string;
      sku!: string;
      modelName!: string;
      modelYear!: string; //TODO fix sql definition to be a number
      modelSize!: string;
      manufacturer?: string;
      description?: string;
      productCategory?: string;
      frameStyleId?: string; 
  }