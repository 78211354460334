import React from 'react';

interface IFrameImage {
     imageName: string;
}

const FrameImage = (props: IFrameImage) => {
  return (
    <div style={{minWidth: "350px"}}>
      <img style={{minWidth: "350px"}} src={process.env.PUBLIC_URL + `/img/frames/${props.imageName}.jpg`} alt={props.imageName} />
    </div>
  );
};

export default FrameImage;