import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import useFetch from "../../hooks/use-fetch";
import Config from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { InputSwitch } from 'primereact/inputswitch';
import { SelectButton } from 'primereact/selectbutton';
interface IFrameInventoryCountEntry {
      inventoryId: string;
      productId: string;
      year: string;
      quantity: string;
}

interface IFrameInventoryBatchSummary {

      productInventoryBatchId:   string;
      productInventoryLocationId: string;
      locationName: string;
      createdDateLocal: string;
      username: string;
      deletedBy: string; // since this is 'finished', it is not deleted by definition
      deletedDate: string; // since this is 'finished', it is not deleted by definition
      batchState: string; // since this is 'finished', it is not deleted by definition
      batchType: string; // BatchType.COUNT,
      productCategoryId: string;
      frameCount: number;
      frameType: string;
      notes: string;

}
        
interface IFrameInventoryReport {
      isMenuVisible: boolean,
      showSideBarMenu: (show: boolean) => void;
}


const FrameInventoryReport = ( props: IFrameInventoryReport) => {

      const params = useParams();
      const [date, setDate] = useState<Date | null>( null);
      const [selectedBatch, setSelectedBatch] = useState<IFrameInventoryBatchSummary>();
      const [metaKey, setMetaKey] = useState(true);
      const [showCanceled, setShowCanceled] = useState(false);
      const url = `${Config.baseUrl}/api/inventory/frames/premiere/inventory-report/batch-summaries/`;
      const { data, loading, error } = useFetch<IFrameInventoryBatchSummary[]>(url ?? "", [], {});

      const [frameFilter, setFrameFilter] = useState("all");

      const frameFilterOptions = [
          { name: 'All', value: "all" },
          { name: 'Premiere', value: "premiere" },
          { name: 'Alloy', value: "alloy" }
      ];

      const [canceledFilter, setCanceledFilter ] = useState(null);
      const cancelFilterOptions = [
          { name: 'All', value: "all" },
          { name: 'Include Canceled', value: "canceled" }
      ];

      const [ batches, setBatches] = useState<IFrameInventoryBatchSummary[]>();

      let curFrameStyle = "";
      
      const navigate = useNavigate();

      useEffect(() => {
            if ( selectedBatch ) {
               navigate( `/frames-inventory-batch/${selectedBatch.productInventoryBatchId}/${selectedBatch.frameType}`);
            }

      }, [selectedBatch] );

      useEffect(() => {
         if ( loading === false ) {
            let dataCopy = data;

            if ( showCanceled === false ){
                  dataCopy = dataCopy.filter( i => i.batchState.toUpperCase() === "ENDED" );
            } 

            if ( frameFilter === "all") {
                  //do nothing
            } else if (frameFilter === "premiere") {
                  dataCopy = dataCopy.filter( i => i.frameType.toLocaleLowerCase() === "premiere")
            } else if ( frameFilter === "alloy") {
                  dataCopy = dataCopy.filter( i => i.frameType.toLocaleLowerCase() === "alloy")
            }
            setBatches( dataCopy )
         }
      }, [data, frameFilter, showCanceled] );

      return (

            <div className='report' >
                  <Card>
                        <div className="report-title-wrapper" style={{ alignItems: "center"}}>
                              <div className="report-title">Frame Inventory Batches</div>      
                              <div className="card flex justify-content-center">
                                    <SelectButton style={{ paddingLeft: "20px", display:"flex", alignItems: "center"}} value={frameFilter} onChange={(e) => setFrameFilter(e.value)} optionLabel="name" options={frameFilterOptions} />
                                    <div  style={{ paddingLeft: "20px", display:"flex", alignItems: "center"}}>Show canceled <InputSwitch checked={showCanceled} onChange={(e) => setShowCanceled(e.value)} /></div>
                              </div>
                             
                        </div>

                        <div className="flex flex-column align-items-start">
                             
                              {error && <div>An error occurred loading the report.</div>}

                              {loading && <div> <ProgressSpinner /> Loading...</div>}
                          
                              {data && data.length === 0 &&
                                       <div>No frames were completed on this date.</div>
                              }
                        
                              <DataTable stripedRows value={batches} selectionMode="single" selection={selectedBatch}
                                    onSelectionChange={(e) => setSelectedBatch(e.value as any)} dataKey="palletInventoryBatchId" metaKeySelection={metaKey} tableStyle={{ minWidth: '50rem' }}>
                                          <Column field="locationName" header="Location"></Column>
                                          <Column field="createdDateLocal" header="Created"></Column>
                                          <Column field="frameType" header="Frame Type"></Column>
                                          <Column field="username" header="by"></Column>
                                          <Column field="batchType" header="Type"></Column>                         
                                          <Column field="batchState" header="State"></Column>
                                         
                                          <Column field="notes" header="Notes"></Column>
                              </DataTable>
                                   
                        </div>

                     
                  </Card>
                
            </div>
      )
}

export default FrameInventoryReport;

/*
      { selectedBatch &&

                                    <FrameInventoryGrid inventoryBatchId={selectedBatch?.productInventoryBatchId}></FrameInventoryGrid>

                              }
                                    */