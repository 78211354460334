import { useEffect, useState } from "react";
import Config from "../../config";
import { FrameCompletion, IFrameEntry, IFrameOtherProperties } from "../../models/daily-activity.models";
import { FrameProduct } from "../../models/frame-product";
import IProduct from "../../models/i-product";

interface IFrameData {

      frames: IProduct[];
}
interface CellMap {
      size: string;
      year: string;
      quantity?: string;
      productId?: string;
}

const GridRow = (props: IFrameData) => {
      //32 is all years but mapped to one year, same as 85"
      
      const [frames, setFrames] = useState<CellMap[]>([]); 
      const frameSizes: CellMap[] = [    { size: "32", year:"2021"},

                                          { size: "43", year:"2020"},
                                          { size: "50", year:"2020"},
                                          { size: "55", year:"2020"},
                                          { size: "65", year:"2020"},
                                          { size: "75", year:"2020"},

                                          { size: "43", year:"2021"},
                                          { size: "50", year:"2021"},
                                          { size: "55", year:"2021"},
                                          { size: "65", year:"2021"},
                                          { size: "75", year:"2021"},

                                          { size: "85", year:"2021"},
                                          { size: "Total", year:"2021"} ];

      useEffect(() => { 
            const newFrames:CellMap[] = [];
            let total = 0;
            for ( let i of frameSizes ) {
                  const entry = { size: i.size, year: i.year, quantity: ""};
                  let found = props.frames.find( e => { return i.size === e.modelSize && i.year === e.modelYear })
                  if ( found  ) {
                        entry.quantity = (found  as any).framesCompleted
                        total += parseInt( (found  as any).framesCompleted );
                  }
                  newFrames.push (entry);
            }

            newFrames[newFrames.length - 1].quantity = total.toString();

            setFrames( newFrames )
      },[props.frames]);

      return (

            <>
                   { frames.map((cell: CellMap) => {
                       
                        return      (
                             
                              <div key={cell.productId} className={`frame-grid-cell ${cell.size === "Total" ? 'total' : cell.quantity === "" ?  'empty' : ''}`}>
                                    {cell.quantity}
                              </div>
                       
                        )        
                     
                  })}
                   </>          

      );
}

export default GridRow;