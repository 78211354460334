import {  EntryTypeEnum, FrameCompletion, IStationAction, StationEntry } from "../../../models/daily-activity.models";
import { Panel } from 'primereact/panel';
import StationTimeLineHeader from "./station-time-line-header";
import TimeStartStopDuration from "./time-start-stop-duration";
import FrameCompletedTile from "../frame-event-tiles/frame-completed-tile";
import { Timeline } from 'primereact/timeline';
import { DateUtils } from "../../../utils/date-utils";
import FrameRejectedTile from "../frame-event-tiles/frame-rejected-tile";
import StationTaskTile from "../frame-event-tiles/station-task-tile";

interface IStationTimerStartStopPanelProps {
      stationEntry: StationEntry;
      showEventIds: boolean;
      actions: any[] ;
}


const StationTimerStartStopPanel = (props: IStationTimerStartStopPanelProps) => {

      function getTaskName( actions: any){

      }
      return (
            <Panel toggleable collapsed header={ <StationTimeLineHeader 
                                                                eventHistoryId={props.stationEntry.eventHistoryId}
                                                                taskName={props.stationEntry.taskName} 
                                                                stationId={props.stationEntry.stationId} 
                                                                showEventIds={props.showEventIds} 
                                                                startEventId={props.stationEntry.startEventId} 
                                                                endEventId={props.stationEntry.endEventId} 
                                                                userName={props.stationEntry.userName} 
                                                                startTime={props.stationEntry.startTime}  
                                                                endTime={props.stationEntry.endTime} 
                                                                duration={props.stationEntry.duration} 
                                                                actions={props.stationEntry.actions} />} >
                  <div className='m-0'>
                        <>
              
                        <div className="pb-3">Station Start {DateUtils.format24To12( props.stationEntry.startTime ) }</div>
                        <Timeline value={props.stationEntry.actions} opposite={(item) =>  
                                    <TimeStartStopDuration entryType={ (item as IStationAction).entryType} 
                                                          eventHistoryId={item.eventHistoryId}
                                                           showEventIds={props.showEventIds} 
                                                           startEventId={item.startEventId}  
                                                           endEventId={item.endEventId}  userName={item.userName} startTime={ ( item as IStationAction).entryType === EntryTypeEnum.STATION_TASK ? item.time : item.startTime}  endTime={item.endTime} duration={item.duration} />} 
                                          content={(item) =>  {

                                                switch(( item as IStationAction).entryType) {
                                                      case EntryTypeEnum.STATION_TASK:
                                                            return <StationTaskTile
                                                            eventHistoryId={item.eventHistoryId}
                                                            userName={item.userName} 
                                                            taskName={item.taskName} 
                                                            isDeleted={item.isDeleted} 
                                                            deletedTime={item.deletedItem} 
                                                            time={item.time} 
                                                            duration={item.duration}  />  

                                                      case EntryTypeEnum.FRAME_COMPLETED: 
                                                            return <FrameCompletedTile eventHistoryId={item.eventHistoryId} frame={item.frame} frameSerialId={item.frameSerialId}  />

                                                      case EntryTypeEnum.FRAME_VOIDED:
                                                            return <FrameCompletedTile eventHistoryId={item.eventHistoryId}  frame={item.frame} frameSerialId={item.frameSerialId}  />

                                                      case EntryTypeEnum.FRAME_DISCARDED:
                                                            return <FrameCompletedTile eventHistoryId={item.eventHistoryId}  frame={item.frame} frameSerialId={item.frameSerialId}  />

                                                      case  EntryTypeEnum.FRAME_REJECTED:
                                                            return <FrameRejectedTile eventHistoryId={item.eventHistoryId}  frame={item.frame} frameSerialId={item.frameSerialId} 
                                                            rejectedToStationId={item.rejectedToStationId} 
                                                            rejectionReason={item.rejectedReason} 
                                                            comment={item.comment} />
                                                }

                                              

                                          } }
                        />
                        <div className="pt-3">Station End {  DateUtils.format24To12( props.stationEntry.endTime )}</div>
                        

                        </>

                  </div>
            </Panel>
      )

}

/*

                                                            <StationTaskTile entryType={EntryTypeEnum.STATION_TASK} 
                                                            userName={item.userName} taskName={item.taskName} 
                                                            isDeleted={item.isDeleted} 
                                                            deletedTime={item.deletedItem} 
                                                            time={item.time} 
                                                            duration={item.duration}  /> 

                              {props.stationEntry.actions.map((action: IStationAction) => {
                                        return <> 
                                           {  action.entryType === 'frameCompletion' &&

                                                 <div> 
                                                      <FrameCompletionPanel key={(action as unknown as ITimedEntry).eventId} frameCompletionEvent={(action as FrameCompletion)} />
                                                </div>
                                          }
                                          </>
                                    })
                              }
                              */

export default StationTimerStartStopPanel;


